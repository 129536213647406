<template>
    <div v-cloak>
      <HeaderCom />
  <!-- Start of breadcurmb section
      ============================================= -->
      <section class="saasio-breadcurmb-section-detailblog">
      <div class="container">
          <div class="breadcurmb-title-detailblog">
              <!-- <h2>{{ $t('ContactUs.title') }}</h2>
                <p>Lorem ipsum dolor sit amet consectetur. Ac nunc in urna sed id sit sed adipiscing. Mauris egestas parturient arcu ullamcorper egestas mauris. Mi eget congue feugiat sit. Sit tortor id sapien convallis. Ac.</p> -->
          </div>
      </div>
  </section>

  <div class="filter-blog-section" v-for="(post, index) in postsArtikel" :key="index">
      <div class="filter-blog-label">
          <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
      </div>
      <div class="event-links" v-if="post.keyword">
          <span v-for="(keyword, i) in post.keyword.split(',')" :key="i">
              <a href="#" class="event-link">{{ keyword }}</a>
          </span>
      </div>
  </div>

  <!-- End of breadcurmb section
      ============================================= -->

  <!-- Start of blog page section
      ============================================= -->
  <section id="news-feed" class="news-feed-section">
      <div class="container">
          <div class="blog-feed-content">
              <div class="row">
                  <div class="col-md-12" v-for="(post, index) in  postsArtikel" :key="index">
                      <div class="saasio-blog-details-content">
                          <div class="gambarnews">
                              <img :src="getFileUrl(post.gambar3)" alt="Description of Image">
                          </div>
                          <div class="blog-details-text dia-headline">
                              <article class="justify-text" v-html="currentLanguage === 'en' ? post.keterangan_en : post.keterangan_id"></article>
                          </div>
                          <div class="blog-details-text dia-headline">
                              <article class="justify-text" v-html="currentLanguage === 'en' ? post.keterangan_en2 : post.keterangan_id2"></article>
                          </div>
                        
                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!-- End of blog section
      ============================================= -->
      <section class="blog-tambahan">
    <div class="interesting">
      <h2>{{ $t('detailblog.title') }}</h2>
    </div>
    <div class="container-blog">
      <div 
        class="card-blog" 
        v-for="post in filteredBannerNews" 
        :key="post.id_artikel"
      >
        <div class="blog-img">
          <img :src="post.gambar" alt="Blog Image" class="card-image-blog">
          <div class="card-blog-content">
            <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
            <router-link :to="{ name: 'DetailBlog', params: { id_artikel: post.id_artikel } }">
              <button class="read-more-button-blog" @click="scrollToTop">Read More</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment'; 
import { useI18n } from 'vue-i18n';
import HeaderCom from '@/components/HeaderCom.vue';

const BASE_URL = 'https://puracigarettesolutions.com:3000/api/artikel'; // API base URL

export default {
  name: 'DetailBlog',
  components: {
    HeaderCom,
  },
  setup() {
    const postsArtikel = ref([]);
    const bannernews = ref([]);
    const route = useRoute();
    const { locale } = useI18n();

    const changeLanguage = (lang) => {
      console.log(`Changing language to: ${lang}`);
      locale.value = lang;
    };

    const formatDate = (dateString) => {
      return moment(dateString).format('YYYY-MM-DD');
    };

    const getFileUrli = (filename) => {
      const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
      const encodedFilename = encodeURIComponent(filename);
      return `${baseUrl}/${encodedFilename}`;
    };

    const getFileUrl = (filename) => {
      const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
      const encodedFilename = encodeURIComponent(filename);
      return `${baseUrl}/${encodedFilename}`;
    };

    const getData = async () => {
      const id_artikel = route.params.id_artikel;
      if (id_artikel) {
        try {
          const response = await axios.get(`${BASE_URL}/TampilanArtikel/${id_artikel}`);
          if (response.data && response.data.data) {
            postsArtikel.value = [response.data.data].map(post => ({
              ...post,
              tanggal: formatDate(post.tanggal)
            }));
          } else {
            console.error('Data received is not in expected format');
          }
        } catch (error) {
          console.error('Error fetching article data:', error);
        }
      }
    };

    const getbannernews = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/TampilanUtama`);
        if (response.data && response.data.data) {
          bannernews.value = response.data.data.map(item => ({
            ...item,
            gambar: getFileUrli(item.gambar1)
          }));
          console.log("Banner news data:", bannernews.value);
        } else {
          console.error('Banner news data is not in expected format');
        }
      } catch (err) {
        console.error('Failed to fetch banner news:', err);
      }
    };

    onMounted(() => {
      getData();
      getbannernews();
    });

    watch(() => route.params.id_artikel, (newId, oldId) => {
      if (newId !== oldId) {
        getData();
        getbannernews();
      }
    });

    const handleRouteChange = () => {
      getData();
      getbannernews();
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // Ensures the scrolling is smooth
      });
    };

    const filteredBannerNews = computed(() => {
      const currentArticleId = postsArtikel.value[0]?.id_artikel;
      return bannernews.value.filter(post => post.id_artikel !== currentArticleId);
    });

    return {
      postsArtikel,
      bannernews,
      filteredBannerNews,  // Add this line
      getFileUrli,
      getFileUrl,
      handleRouteChange,
      changeLanguage,
      currentLanguage: computed(() => locale.value),
      scrollToTop
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700|Roboto:100,500,300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


[v-cloak] {
display: none;
}

.justify-text {
  text-align: justify;
}

.justify-text a{
  color: #DFA419;
}


.filter-blog-section {
  margin: -70px 200px -50px; /* Adjust the margins as needed */
  background: linear-gradient(to right, #fff, #fff); /* The gradient background */
  padding: 20px 0; /* Increased padding top for moving text down */
  position: relative; /* For pseudo-element positioning */
}

.filter-blog-label {
  font-size: 36px;
  font-weight: bold; /* Bold text */
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  text-align: center; /* Center aligns all text within the blog details */
max-width: 3000px; /* Sets the maximum width of the text block */
margin: auto; /* Centers the block horizontally in its container */
}

.filter-blog-label h2 {
  color: #000000;
  margin-top: 25px;
font-size: 65px;
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: 2000px;
}

.filter-blog-label h3 {
  color: #000000;
font-size: 48px;
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: 2000px;
padding-bottom: 25px;
}



.container-blog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px; /* Corrected typo "margin-button" to "margin-bottom" */
}

.card-blog {
  margin: 10px;
  width: 300px; /* Set a specific width */
  height: 500px; /* Set a specific height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative; /* Needed for absolute positioning of content */
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.blog-img {
  width: 100%; /* Ensures the blog image container fills the card */
  height: 110%; /* Ensures the blog image container fills the card */
  position: relative;
  overflow: hidden; /* Ensures no spill outside the boundary */
}

.card-image-blog {
  width: 100%;
  height: 100%; /* Adjusted to ensure the image covers the whole area */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  position: absolute; /* Ensures the image is positioned absolutely within .blog-img */
}

.card-blog-content {
  position: absolute;
  bottom: 50px; /* Positions the content at the bottom */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  color: white;
  padding: 20px; /* Padding inside the content area */
  text-align: center; /* Centers the text */
  font-size: 14px; /* Adjusted default font size for smaller text */
}

.card-blog-content h2 {
  font-size: 18px; /* Smaller font size for headlines */
  margin-bottom: 10px; /* Space below the headline */
}

.card-blog-content h3 {
  font-size: 18px; /* Smaller font size for headlines */
  margin-bottom: 10px; /* Space below the headline */
}

.card-blog-content p {
  font-size: 12px; /* Smaller font size for paragraph text */
}

button {
  background-color: #FFCA2C; /* Gold color */
  color: #0D0D0D; /* Dark grey text */
  border: none;
  padding: 1px 5px; /* Adjusted padding to match the example */
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px; /* Adjusted font size */
  outline: none; /* Removes the outline */
  position: relative; /* Needed for pseudo-elements */
  overflow: hidden; /* Ensures no pseudo-elements spill out */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

button::before {
  content: '>'; /* The arrow symbol */
  font-size: 20px; /* Larger font size for the arrow */
  color: #0D0D0D; /* Dark grey arrow */
  position: absolute;
  right: -30px; /* Starts outside the button */
  top: 50%; /* Centers vertically */
  transform: translateY(-50%); /* Aligns arrow to the middle of the button */
  transition: right 0.3s; /* Smooth transition for hover effects */
}

button:hover {
  background-color: #E5A00D; /* Slightly darker shade for hover state */
}

.interesting h2 {
  margin-left: 300px;
  color:#000000;
  font-size: 18px; /* Sets the font size for the section */
  font-weight: bold; /* Makes the font bold */
}

.gambarnews {
  width: 900px;
  margin-top: 50px;
  text-align: center;
  align-items: center;
  margin-left: 150px;
}

.event-links {
  text-align: center;
  align-items: center;
  color: #DFA419;

}

.event-link {
  color: #DFA419; /* White text for links */
  text-decoration: none; /* No underline */
  padding: 3px 10px; /* Padding around links */
  text-align: center; /* Center the text */
  align-items: center;
  font-size: 12px;
  margin: 0 10px; /* Space between links */
  border: 2px solid #DFA419;
  background-color: #ffffff; /* Darker background for links */
  border-radius: 20px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.event-link.active, .event-link:hover {
 color: #FFCA2C;
}

.ref-details-text {
 margin-left: 200px;
} 

.ref-details-text h5{
  font-size: 30px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #FFCA2E;;
} 


.read-more-button-blog {
      margin-bottom: 0px;
      padding: 5px 30px;
      font-size: 9px;
}

.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

.blog-details-text h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
}
  

@media (max-width: 1075px) {
  .filter-blog-section {
      padding: 20px 0;
      margin: -70px 17px -50px; /* Adjust the margins as needed */
  }
  
  .filter-blog-label h2 {
      font-size: 25px;
  }
  
  .container-blog {
      display: flex;
      justify-content: space-around; /* Better spacing on tablets */
  }
  
  .card-blog {
      width: 270px; /* Fixed width on tablets and above */
      height: 400px; /* Fixed height */
  }

  .blog-details-text article {
  padding: 20px 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  
.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  
  .interesting h2 {
      text-align: left;
      margin-left: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color: #000000;
      font-size: 16px;
  }
  
  .gambarnews {
      width: 750px;
      margin-left: 50px;
  }


.card-blog-content h2 {
  font-size: 12px;
  margin-bottom: 16px;
}

.card-blog-content h3 {
  font-size: 12px;
  margin-bottom: 16px;
}

.card-blog-content {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 0px;
  text-align: center;
  font-size: 14px;
}

.read-more-button-blog {
      margin-bottom: 0px;
      padding: 5px 30px;
      font-size: 9px;

}

.ref-details-text {
 margin-left: 0px;
} 

.ref-details-text h5{
  font-size: 25px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  color: #FFCA2E;;
} 



}



@media (max-width: 765px) {

  .saasio-breadcurmb-section-detailblog {
  padding: 100px 0px -1px;
}

  .filter-blog-section {
      padding: 20px 0;
      margin: -70px 17px -50px; /* Adjust the margins as needed */
  }
  
  .filter-blog-label h2 {
      text-align: center;
      font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
      font-weight: bold;
      color: #000000;
      font-size: 20px;
  }
  
  .filter-blog-label h3 {
      text-align: center;
      font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
      font-weight: bold;
      color: #000000;
      font-size: 35px;
  }
  
  .container-blog {
      display: flex;
      justify-content: space-around;
  }

  .card-blog {
      width: 150px; /* Fixed width on tablets and above */
      height: 220px;
  }

  .blog-details-text article {
  padding: 20px 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  

  .interesting h2 {
     margin-left: 14px;
      font-size: 14px;
  }
  
  .gambarnews {
    width: 700px;
    margin-left: -20px
  }


.card-blog-content h2 {
  font-size: 10px;
  margin-bottom: 5px;
}

.card-blog-content h3 {
  font-size: 9px;
  margin-bottom: 10px;
}

.card-blog-content {
  position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      padding: 0px;
      text-align: center;
      font-size: 10px;
}

.read-more-button-blog {
  margin-bottom: 10px;
      padding: 0px 15px;
      font-size: 10px;

}

.ref-details-text {
 margin-left: 0px;
} 

.ref-details-text h5{
  font-size: 20px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  color: #FFCA2E;;
} 

.event-links {
  display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      padding: 10px 0;
      align-content: center;
      justify-content: center;
}

  .event-link {
      padding: 4px 2px;
      font-size: 8px;
      margin: 5px;
      border-radius: 25px;
  }

  .blog-tambahan {
      margin-bottom: 150px;
  }
}


</style>
