<template>
    <HeaderCom />
    <!-- Start of breadcurmb section
        ============================================= -->
        <section id="saasio-breadcrumbin" class="saasio-breadcurmb-sectionix">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="carousel-background">
            <img src="assets/img/business-4/slider/produkk.png" alt="First slide">
          </div>
          <div class="carousel-content">
            <h2>{{ $t('BlogPages.title') }}</h2>
            <p>{{ $t('BlogPages.desc') }}</p>
            <div class="button-container-news">
              <a href="/SimulasiCtp" class="btn btn-primary-news">
                {{ $t('BlogPages.buttommore') }}<span class="arrow-icon">></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

    
<div class="filter-section">
    <div class="filter-label">{{ $t('BlogPages.filter') }}</div>
  <div class="horizontal-group">
            <div class="filter-blog" @click="toggleActive(0)" :class="{ active: activeItem === 0 }">{{ $t('BlogPages.all') }}</div>
            <div class="filter-blog" @click="toggleActive(8)" :class="{ active: activeItem === 8 }">{{ $t('BlogPages.event') }}</div>
            <div class="filter-blog" @click="toggleActive(1)" :class="{ active: activeItem === 1 }">{{ $t('BlogPages.cp') }}</div>
            <div class="filter-blog" @click="toggleActive(2)" :class="{ active: activeItem === 2 }">{{ $t('BlogPages.tp') }}</div>
            <div class="filter-blog" @click="toggleActive(3)" :class="{ active: activeItem === 3 }">{{ $t('BlogPages.etiket') }}</div>
            <div class="filter-blog" @click="toggleActive(9)" :class="{ active: activeItem === 9 }">{{ $t('BlogPages.inovasi') }}</div>
    </div>
</div>
    <!-- End of breadcurmb section
        ============================================= -->


    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
        <div class="container">
            <div class="blog-feed-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="blog-feed-post" v-for="(post, index) in paginatedPosts" :key="index">
                            <div class="blog-feed-img-txt">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="saasio-blog-img">
                                            <img :src="getFileUrl(post.gambar2)" alt="Description of Image">
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="saasio-blog-text">
                                            <router-link :to="{ name: 'DetailBlog', params: { id_artikel: post.id_artikel } }" target="_blank" class="newscontent">
                                                <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
                                            </router-link>
                                            <article class="center-text" v-html="currentLanguage === 'en' ? post.deskripsi_en : post.deskripsi_id"></article>
                                            <br>
                                            <!-- Example of using Vue Router for navigation -->
                                            <router-link :to="{ name: 'DetailBlog', params: { id_artikel: post.id_artikel } }"  target="_blank" class="newscontent"
                                                    style="color: #dcb83d;">{{ $t('BlogPages.buttom') }} ></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="saasio-pagination text-center ul-li">
                            <ul>
                                <li v-for="page in totalPages" :key="page">
                                    <a href="#" @click="setPage(page)" :class="{ active: currentPage === page }">{{ page
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of blog section
        ============================================= -->
    <FooterCom />
</template>


<script>
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/FooterCom.vue';
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

export default {
    name: 'BlogComponent',
    components: {
        HeaderCom,
        FooterCom,
    },
    setup() {
        const currentPage = ref(1);
        const totalPages = ref(0);
        const postsPerPage = 3;
        const categories = ref([]);
        const postsArtikel = ref([]);
        const activeItem = ref(null); // Use ref for reactive reference
        const { locale } = useI18n();

        const changeLanguage = (lang) => {
        console.log(`Changing language to: ${lang}`);
        locale.value = lang;
        };

        // ... existing script code ...

        const toggleActive = async (index) => {
            activeItem.value = index;
            await getData(index); // Fetch data based on category
        };


        const paginatedPosts = computed(() => {
            const start = (currentPage.value - 1) * postsPerPage;
            const end = start + postsPerPage;
            return postsArtikel.value.slice(start, end);
        });

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
            return `${baseUrl}/${filename}`;
        };

        const error = ref(null);

        const getData = async (id_kategori = 0) => {
            try {
                const url = `https://puracigarettesolutions.com:3000/api/artikel/TampilanKategori?category=${id_kategori}`;
                const response = await axios.get(url);
                const formattedPosts = response.data.data.map(post => ({
                    ...post,
                    tanggal: moment(post.tanggal).format('YYYY-MM-DD')
                }));
                postsArtikel.value = formattedPosts;
                totalPages.value = Math.ceil(formattedPosts.length / postsPerPage);
                error.value = null;
            } catch (err) {
                console.error('Error fetching data:', err);
                error.value = 'Failed to fetch data';
                // Additional error handling can be added here
            }
        };

        const setPage = (page) => {
            currentPage.value = page;
        };

        
    const currentLanguage = computed(() => locale.value);

        const changeLanguageHandler = (lang) => {
        changeLanguage(lang);
        };

        watch(locale, () => {
        getData(); // Reload data when language changes
        });

        onMounted(() => {
            getData();
        });

        // Returning all necessary data and methods
        return {
            paginatedPosts,
            currentPage,
            totalPages,
            setPage,
            toggleActive,
            categories, // Pastikan mengembalikan ini
            activeItem,
            getFileUrl,
            postsArtikel,
            error,
            changeLanguageHandler,
            currentLanguage, // returning error for use in template if needed
        };
    },
};
</script>



<style scoped>

.button-container-news {
  display: flex;
  justify-content: center;
}

.btn-primary-news {
  background-color: #FFCA2E;
  color: black;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.btn-primary-news:hover {
  background-color: black;
  color: white;
}



</style>