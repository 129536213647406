<template>
  <HeaderCom />
  <!-- Start of carousel section -->
  <section class="saasio-breadcurmb-section-produk">
      <div class="container">
          <div class="breadcurmb-title-produk">
            <h2>{{ $t('ProdukPages.title') }}</h2>
            <p>{{ $t('ProdukPages.subtitle') }}</p>
          </div>
      </div>
  </section>

  <!-- End of carousel section -->
  <div class="container-produk">
    <div class="filter-section-produk">
      <h1>{{ $t('ProdukPages.title2') }}</h1>
      <hr class="gold-line">
      <p>{{ $t('ProdukPages.subtitle2') }}</p>
      <div class="horizontal-group-produk">
        <div class="group-item" @click="toggleActive(0)" :class="{ active: activeItem === 0 }">All</div>
        <div class="group-item" @click="toggleActive(1)" :class="{ active: activeItem === 1 }">{{ $t('ProdukPages.cp') }}</div>
        <div class="group-item" @click="toggleActive(2)" :class="{ active: activeItem === 2 }">{{ $t('ProdukPages.tp') }}</div>
        <div class="group-item" @click="toggleActive(3)" :class="{ active: activeItem === 3 }">{{ $t('ProdukPages.etiket') }}</div>
        <div class="group-item" @click="toggleActive(4)" :class="{ active: activeItem === 4 }">{{ $t('ProdukPages.icp') }}</div>
        <div class="group-item" @click="toggleActive(5)" :class="{ active: activeItem === 5 }">{{ $t('ProdukPages.fp') }}</div>
        <div class="group-item" @click="toggleActive(6)" :class="{ active: activeItem === 6 }">{{ $t('ProdukPages.prc') }}</div>
        <div class="group-item" @click="toggleActive(7)" :class="{ active: activeItem === 7 }">{{ $t('ProdukPages.rm') }}</div>
      </div>
    </div>
    <div class="products-grid">
      <div class="product" v-for="(post, index) in paginatedPosts" :key="index">
        <router-link :to="{ name: 'DetailProduk', params: { id_produk: post.id_produk } }" target="_blank">
          <div class="product-image-wrapper">
            <img :src="getFileUrl(post.gambar1)" :alt="'Image of ' + post.judul_id">
          </div>
          <div class="product-details">
            <div class="product-title">
              <h2>{{ post.nama_kategori }}</h2>
              <h3>{{ currentLanguage === 'en' ? post.judul_en : post.judul_id }}</h3>
            </div>
            <button class="detail-button">{{ $t('ProdukPages.buttom') }}</button>
          </div>
        </router-link>
      </div>
    </div>
    <div class="pagination-controls" v-if="totalPages > 1">
    <button @click="prevPage" :disabled="currentPage === 1">&lt;</button>
    <span v-for="page in paginationNumbers" :key="page" @click="setPage(typeof page === 'number' ? page : currentPage)" :class="{ active: currentPage === page }">
      {{ page }}
    </span>
    <button @click="nextPage" :disabled="currentPage === totalPages">&gt;</button>
  </div>
  </div>

  <!-- <div class="our-patents-container">
  <div class="our-patents-content">
    <h2>OUR PATENT</h2>
    <hr class="gold-line">
    <swiper
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
       :pagination="{ clickable: true }"
      :loop="false"
      :centered-slides="false"
      class="patents-swiper"
    >
      <swiper-slide v-for="(patent, index) in patents" :key="index">
        <div class="patent-card">
          <div class="patent-header">
            {{ patent.title }}
          </div>
          <div class="patent-body">
            <p><span class="patent-label">Patent ID:</span> {{ patent.id }}</p>
            <p><span class="patent-label">Product:</span> {{ patent.product }}</p>
            <p><span class="patent-label">Grant Date:</span> {{ patent.grantDate }}</p>
            <p><span class="patent-label">Status:</span> <span :class="['patent-status', getStatusClass(patent.status)]">{{ patent.status }}</span></p>
            <p class="patent-label">Abstract:</p>
            <p class="patent-abstract">{{ truncateAbstract(patent.abstract, isMobile ? 15 : 20) }}</p>
          </div>
          <div class="patent-button-container">
            <button class="patent-btn" @click="learnMore(patent)">Learn More</button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</div> -->


  <div class="innovations-section" style="background: url('assets/img/business-4/back/produk1.png')">
  <h1>{{ $t('DerecButtom.title') }}</h1>
  <swiper :navigation="true" :modules="modules" :slides-per-view="3" :space-between="0">
    <swiper-slide v-for="(post, index) in innovations" :key="index">
      <div class="innovation-item" :style="{ backgroundImage: 'url(' + getFileUrli(post.gambar1) + ')' }">
        <div class="innovation-content">
          <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
          <router-link  :to="{ name: 'DetailBlog', params: { id_artikel: post.id_artikel } }" target="_blank">
            <button class="read-more-button">{{ $t('DerecButtom.buttom') }}</button>
          </router-link>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</div>

  <FooterCom />
</template>

<script>
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/FooterCom.vue';
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

export default {
  name: 'BlogComponent',
  components: {
    HeaderCom,
    FooterCom,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentPage = ref(1);
    const postsPerPage = 6;
    const categories = ref([]);
    const postsProduk = ref([]);
    const innovations = ref([]);
    
    const activeCategory = ref('all');
    const activeItem = ref(0);
    const { locale } = useI18n();

    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    const patents = ref([
      {
        id: '',
        title: 'PENGHAMBAT NYALA API PADA KERTAS ROKOK HINGGA PADAM SENDIRI',
        product: 'LIP (Low Ignition Propensity)',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },
      {
        id: '',
        title: 'LAPIS GANDA KERTAS TIPING DENGAN ROKOK UNTUK SIGARET KRETEK TANGAN (SKT)',
        grantDate: '',
        product: 'Double Wrapper',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'METODE PEMBUATAN BUTIRAN TEMBAKAU UNTUK ROKOK ELEKTRONIK',
        product: 'Slurry Tembakau',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },
      {
        id: '',
        title: 'FILTER PENDINGIN ROKOK ELETRONIK DENGAN LAPISAN MICRO COOL',
        product: 'Filter Rokok dengan Micro Cool',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PERFORASI LASER CO2 UNTUK KEMASAN YANG RAPAT DENGAN ATAU TANPA CETAKAN',
        product: 'OPP',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PENGHALANG TEMBAKAU EKSTERNAL PADA BAGIAN UJUNG HISAP PRODUK ROKOK',
        product: 'Filter',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PEMBUNGKUS ROKOK DARI BAHAN ALAMI UNTUK ROKOK SIGARET KRETEK TANGAN (SKT)',
        product: 'Selongsong',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PENGHALANG TEMBAKAU DARI BAHAN ALAMI DENGAN PEMBUNGKUS KERTAS PADA UJUNG HISAP ROKOK',
        product: 'Filter',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PENGOLAHAN TALAS DENGAN SISTEM OZONASI SEBAGAI BAHAN DASAR ROKOK',
        product: 'Slurry Talas dengan Ozonasi',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },
      
      {
        id: '',
        title: 'KEMASAN ROKOK SATUAN BERBENTUK KUBUS',
        product: 'Cigarette pack/Stick',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'KANTONG IRISAN TEMBAKAU SIAP LINTING PADA ROKOK SIGARET KRETEK TANGAN (SKT) DENGAN BAHAN METALIZE',
        product: 'Kantong Tembakau SKT',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'KERTAS ROKOK DAN KERTAS TEMBAKAU DENGAN AROMA DAN MOTIF',
        product: 'HTL/CP with flavor',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PENGHALANG TEMBAKAU PADA UJUNG HISAP ROKOK SKT DENGAN PENAMBAHAN MICROENCAPTULASI',
        product: 'Filter Internal w/Microcool',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'PENGHALANG TEMBAKAU EKSTERNAL PADA PRODUK ROKOK SKT DENGAN PENAMBAHAN MICROENCAPTULASI',
        product: 'Filter External w/Microcool',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },

      {
        id: '',
        title: 'KERTAS TIPPING ROKOK DENGAN RASA, AROMA DAN MOTIF',
        product: 'CTP with sweetness and flavor',
        grantDate: '',
        status: 'Pending',
        abstract: '',
      },
      // Add more patent objects as needed
    ]);

    const isMobile = ref(window.innerWidth < 991);

const slidesPerView = computed(() => {
  return isMobile.value ? 2 : 3;
});

const spaceBetween = computed(() => {
      return isMobile.value ? 10 : 20;
    });


const updateViewport = () => {
  isMobile.value = window.innerWidth < 768;
};

    
    const learnMore = (patent) => {
      // Implement the action for the "Learn More" button
      console.log(`Learning more about patent: ${patent.id}`);
      // You can add more functionality here, such as opening a modal or navigating to a detailed view
    };

    const truncateAbstract = (text, wordLimit = 20) => {
      const words = text.split(' ');
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
      }
      return text;
    };


    const getStatusClass = (status) => {
      return status.toLowerCase() === 'pending' ? 'status-pending' : 'status-success';
    };


    const filteredPosts = computed(() => {
      if (activeCategory.value === 'all') {
        return postsProduk.value;
      } else {
        return postsProduk.value.filter(post => post.id_kategori === parseInt(activeCategory.value));
      }
    });

    const totalPages = computed(() => Math.ceil(filteredPosts.value.length / postsPerPage));

    const paginatedPosts = computed(() => {
      const start = (currentPage.value - 1) * postsPerPage;
      const end = start + postsPerPage;
      return filteredPosts.value.slice(start, end);
    });

    const getFileUrl = (filename) => {
      const baseUrl = 'https://puracigarettesolutions.com:3000/api/produk/file';
      return `${baseUrl}/${filename}`;
    };

    const getFileUrli = (filename) => {
      const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
      const encodedFilename = encodeURIComponent(filename);
      return `${baseUrl}/${encodedFilename}`;
    };

    const error = ref(null);

    const getData = async () => {
      try {
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/produk/TampilanProduk');
        postsProduk.value = response.data.data;
      } catch (err) {
        error.value = 'Error fetching product data.';
      }
    };

    const setPage = (page) => {
      if (typeof page === 'number') {
        currentPage.value = page;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const toggleActive = (index) => {
      activeItem.value = index;
      activeCategory.value = index === 0 ? 'all' : index.toString();
      currentPage.value = 1; // Reset to first page when changing filter
    };

    const currentLanguage = computed(() => locale.value);

    const paginationNumbers = computed(() => {
      const totalPagesValue = totalPages.value;
      const currentPageValue = currentPage.value;
      const delta = 1;
      let range = [];

      for (let i = Math.max(2, currentPageValue - delta); i <= Math.min(totalPagesValue - 1, currentPageValue + delta); i++) {
        range.push(i);
      }

      if (currentPageValue - delta > 2) {
        range.unshift("...");
      }
      if (currentPageValue + delta < totalPagesValue - 1) {
        range.push("...");
      }

      range.unshift(1);
      if (totalPagesValue !== 1) {
        range.push(totalPagesValue);
      }

      return range;
    });

    const getInnovations = async () => {
      try {
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel');
        innovations.value = response.data.data;
        console.log("Innovations data:", innovations.value);
      } catch (err) {
        console.error('Failed to fetch innovations:', err);
      }
    };

    
document.addEventListener('DOMContentLoaded', function() {
  var section = document.querySelector('.saasio-breadcurmb-section-produk');
  var img = new Image();
  img.src = 'assets/img/business-4/slider/PRODUCT.png';
  img.onload = function() {
    section.classList.add('loaded');
  };
});

    watch([locale, activeCategory], () => {
      getData();
      currentPage.value = 1;
    });

    onMounted(() => {
      getData();
      getInnovations();
      window.addEventListener('resize', updateViewport);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateViewport);
    });


    return {
      paginatedPosts,
      currentPage,
      totalPages,
      setPage,
      prevPage,
      nextPage,
      paginationNumbers,
      categories,
      activeItem,
      getFileUrl,
      getFileUrli,
      toggleActive,
      postsProduk,
      error,
      changeLanguage,
      currentLanguage,
      innovations,
      patents,
      modules: [Pagination],
      slidesPerView,
      learnMore,
      truncateAbstract,
      isMobile,
      spaceBetween,
      getStatusClass
    };
  },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');


.group-item {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 10px 25px;
  margin: 5px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 20px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.group-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.group-item.active {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.filter-section-produk {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10; /* Higher z-index to ensure it's on top */
  position: relative;
  align-items: center;
}

.filter-section-produk h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  font-size: 30px;
}

.filter-section-produk p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  text-align: center;
  margin: 0 100px;
  margin-bottom: 50px;
}

#saasio-produk {
  background-color: black;
  padding: 20px 0;
}

.carousel-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 10px;
  min-height: 500px;
}

.carousel-image-container {
  flex: 0 0 auto;
  max-width: 500px;
  margin-left: 300px;
  max-height: 300px;
  overflow: hidden;
}

.carousel-image-custom {
  width: 200px;
  height: 300px;
}

.carousel-caption-produk {
  flex: 1;
  padding-left: 20px;
  color: white;
  text-align: left;
}

.text-warning {
  color: #FFC107;
}

.text-white {
  color: #FFFFFF;
}

.container-produk {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}



.products-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for desktop */
  gap: 20px; /* Add spacing between the items */
}

.product {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 450px; /* Ensure all products have the same height */
  margin-bottom: 20px;
}

.product-image-wrapper {
  width: 100%;
  height: 270px; /* Set a fixed height for all images */
  overflow: hidden;
}

.product img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.product:hover img {
  transform: scale(1.05);
}

.product-details {
  padding: 10px;
  background-color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.product-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.product-details h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  margin: 5px 0;
  flex-grow: 1; /* Allow title to grow and push button down */
}

.product-details h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  color: #C3C3C3;
  font-size: 16px;
  margin: 10px 0;
}

.detail-button {
  background-color: #ffc107;
  border: none;
  padding: 8px 15px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s;
  margin-top: 30px; /* Add some space above the button */
  align-self: center;
  width: fit-content; /* Adjust button width to content */
}
.detail-button:hover {
  background-color: #ffffff;
  color: #000;
}

.gold-line {
  width: 100px;
  height: 4px;
  background-color: gold;
  margin: 10px auto;
  border-radius: 2px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.pagination-controls button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-controls span.active {
  color: #ffc107;
}

.pagination-controls span:hover {
  color: #ffc107;
}

.innovations-section {
  text-align: center;
  padding: 20px 0;
  background-color: #000;
  color: #fff;
}

.innovations-section h1 {
  text-align: left;
  margin-left: 80px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}


.innovations-section h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.innovations-carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}

.swiper-container {
  width: 100%;
}


.innovation-item {
  background-color: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  height: 450px;
  display: flex;
  padding: 0 10px;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center center; /* Mengatur posisi gambar tepat di tengah secara vertikal dan horizontal */
  background-repeat: no-repeat;
  transition: transform 0.3s, box-shadow 0.3s;
}



.innovation-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-radius: 8px;
}

.innovation-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.innovation-content {
  padding: 20px;
  position: relative;
  z-index: 2; /* Ensure it is above the gradient overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%; /* Full height to align items inside vertically */
}

.innovation-content h3 {
  margin-top: auto; /* Pushes the title to the bottom */
  font-size: 18px;
  color: #fff;
}

.read-more-button {
 margin-bottom: 25px;
  padding: 5px 30px;
  background-color: #FFCA2E;
  color: #000;
  font-weight: bold;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}


.read-more-button:hover {
  background-color: #fff;
  color: #000;
}

.swiper-button-next, .swiper-button-prev {
  background-color: #000; /* Solid color for visibility */
  opacity: 0.8; /* Slightly transparent */
  border-radius: 50%;
  width: 30px; /* Smaller circle for subtlety */
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 16px; /* Appropriate icon size */
}

.swiper-button-next {
  right: 10px; /* Proper alignment */
}

.swiper-button-prev {
  left: 10px;
}

@media (max-width: 991px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

}


@media (max-width: 768px) { /* Tablet */
  .innovations-section h2 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovation-item {
    width: 85%; /* Adjust width to use more space */
    margin-left: 20px; /* Decrease margin */
    height: 350px; /* Adjust height */
  }

  .innovation-content {
    padding: 0px;
  }

  .innovation-content h3 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 25px;
  padding: 5px 30px;
  background-color: #FFCA2E;
  color: #000;
  font-weight: bold;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  }
}

@media (max-width: 765px) and (min-width: 700px) { /* Mobile */
  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 25px;
        margin-bottom: 20px;
}


  .innovations-section h2 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovations-carousel {
    flex-direction: column;
    gap: 10px;
  }

  .innovation-item {
    width: 90%; /* Adjust width to use more space */
    margin-left: auto;
    margin-right: auto; /* Centering item */
    height: 300px;
  }

  .innovation-content h3 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 15px;
  padding: 0px 10px;
  font-size: 6px;
  }
}

@media (max-width: 695px) and (min-width: 481px) { /* Mobile */
  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 25px;
        margin-bottom: 20px;
}


  .innovations-section h2 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovations-carousel {
    flex-direction: column;
    gap: 10px;
  }

  .innovation-item {
    width: 90%; /* Adjust width to use more space */
    margin-left: auto;
    margin-right: auto; /* Centering item */
    height: 270px; /* Adjust height */
  }

  .innovation-content h3 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 15px;
  padding: 0px 10px;
  font-size: 6px;
  }
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  .group-item {
    /* Ensure pointer events are allowed */
    pointer-events: auto;
  }

}

@media (max-width: 480px) {
  .products-grid {
    width: 100%;
    height: 700px;
  }

  .container-produk {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
}

.product-details {
    padding: 0px;
    background-color: #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.product {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 225px;
    margin-bottom: 20px;
}

.product-image-wrapper {
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
}

.product img {
        width: 180px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
        transition: transform .3s;
    }
.product-details h2 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: #C3C3C3;
    font-size: 12px;
    margin: 10px 0;
}

.product-details h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 11px;
    margin: 10px 0;
}

.detail-button {
    background-color: #ffc107;
    border: none;
    padding: 5px 10px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 4px;
    margin-bottom: 0px;
    transition: background-color 0.3s;
}

.innovation-item {
    width: 85%; /* Adjust width to use more space */
    margin-left: 20px;
    height: 200px;
  }

  .innovations-section h2 {
        margin-left: 0;
        font-size: 7px;
    }

    .innovation-content h3 {
        font-size: 7px;
    }

    .read-more-button {
        margin-bottom: 25px;
        padding: 1px 10px;
        background-color: #ffca2e;
        color: #000;
        font-weight: 700;
        border-radius: 20px;
        border: none;
        cursor: pointer;
    }

}



/* Mobile Phones */
@media (max-width: 765px) {
 
  .carousel-caption-produk, .filter-section-produk h1, .product-details h3 {
    text-align: center; /* Center text for smaller devices */
  }

  .carousel-image-container {
    margin-left: 0; /* Remove extra margin */
  }
}

.horizontal-group-produk {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 25px;
}

.group-item {
  white-space: nowrap;
  padding: 8px 15px;
  margin: 0 2px;
  font-size: 12px;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 0px;
  }
.horizontal-group-produk {
        display: flex;
        margin-bottom: 24px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }

  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 5px;
        margin-bottom: 20px;
}


}

@media (max-width: 991px) {
  .group-item {
    padding: 5px 8px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }


}

@media (max-width: 780px) and (min-width: 700px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }
  .horizontal-group-produk {
    justify-content: flex-start;
  }

  .horizontal-group-produk {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
    flex-direction: row;
}


}

@media (max-width: 695px) and (min-width: 481px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }
  .horizontal-group-produk {
    justify-content: space-between;
  }

  .horizontal-group-produk {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}


}


.our-patents-container {
  padding: 40px 0;
  overflow: hidden;
}

.our-patents-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}


.our-patents-container h2 {
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  color: #000000;
}

.patents-swiper {
  padding: 20px 15px 50px; /* Add horizontal padding */
  margin: 0 5%;
}

.patent-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 350px;
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Center the cards */
  max-width: 350px; /* Limit the width for consistency */
}

.patent-header {
  background-color: #000000;
  color: white;
  font-family: 'Oswald', sans-serif;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
}

.patent-header .arrow {
  float: right;
}

.patent-body {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.patent-body p {
  margin: 5px 0;
  font-size: 12px;
  line-height: 1.4;
  font-family: 'Poppins', sans-serif;
}

.patent-label {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.patent-abstract {
  margin-top: 10px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.patent-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.patent-btn {
  background-color: #ffca2e;
  color: rgb(0, 0, 0);
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 4px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.patent-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #ffca2e;
}

@media (max-width: 768px) {
  .our-patents-container h2 {
    font-size: 24px;
  }

  .patent-card {
    max-width: 250px;
  }
  .patent-body p {
    font-size: 12px;
  }

  .patent-btn {
    font-size: 12px;
  }

}


.patent-status {
  font-weight: bold;
  padding: 3px 12px;
  border-radius: 12px;
}

.status-pending {
  background-color: #ffca2e;
  color: #000000;
}

.status-success {
  background-color: #4CAF50;
  color: #FFFFFF;
}

@media only screen and (max-width: 360px) {
  .patent-header {
    background-color: #000000;
    color: white;
    font-family: 'Oswald', sans-serif;
    padding: 15px;
    text-align: center;
    font-size: 7px;
    font-weight: bold;
    line-height: 1.3;
}

.patent-body p {
        font-size: 9px;
    }
    .patent-status {
    font-weight: bold;
    padding: 3px 7px;
    border-radius: 11px;
}

.patent-btn {
        font-size: 7px;
    }

    .our-patents-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0px;
}

.patents-swiper {
    padding: 20px 0px 50px;
    margin: 0 5%;
}
.product img {
        width: 150px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
        transition: transform .3s;
    }
}

@media only screen and (max-width: 480px) and (min-width: 360px) {
  .patent-header {
    background-color: #000000;
    color: white;
    font-family: 'Oswald', sans-serif;
    padding: 7px;
    text-align: center;
    font-size: 7px;
    font-weight: bold;
    line-height: 1.3;
}

.our-patents-content {
    max-width: 1200px;
    margin-top:  60px;
    padding: 0 0px;
}

.patents-swiper {
    padding: 20px 10px 50px;
    margin: 0 0%;
}

.patent-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 275px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 350px;
}

.our-patents-container {
    padding: 0px 12px;
    overflow: hidden;
}

.patent-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.patent-btn {
        font-size: 9px;
    }

 .patent-body p {
        font-size: 10px;
    }
}

@media only screen and (max-width: 760px) and (min-width: 481px) {
  .patents-swiper {
    padding: 20px 10px 50px;
    margin: 0 5%;
}

.patent-header {
    background-color: #000000;
    color: white;
    font-family: 'Oswald', sans-serif;
    padding: 15px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.3;
}

.product-image-wrapper {
    width: 100%;
    height: 170px;
    overflow: hidden;
}

.product img {
    width: 100%;
    height: 160px;
    -o-object-fit: cover;
    object-fit: cover;
}

.product {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 370px;
    margin-bottom: 20px;
}

}

@media only screen and (max-width: 991px) and (min-width: 765px) {
  .patent-header {
    background-color: #000000;
    color: white;
    font-family: 'Oswald', sans-serif;
    padding: 15px;
    text-align: center;
    font-size: 7px;
    font-weight: bold;
    line-height: 1.3;
}

.patents-swiper {
    padding: 20px 0px 50px;
    margin: 0 5%;
}

.patent-btn {
        font-size: 10px;
    }

    .patent-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 290px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 350px;
}

.patent-header {
    background-color: #000000;
    color: white;
    font-family: 'Oswald', sans-serif;
    padding: 15px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.3;
}



.product img {
        width: 250px;
        height: 170px;
        -o-object-fit: cover;
        object-fit: cover;
        transition: transform .3s;
    }


    .product {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        background-color: #000;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        height: 335px;
        margin-bottom: 20px;
    }

    .product-details h2 {
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        color: #C3C3C3;
        font-size: 12px;
        margin: 10px 0;
    }

    .product-details h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: #fff;
        font-size: 11px;
        margin: 10px 0;
    }

    .detail-button {
        background-color: #ffc107;
        border: none;
        padding: 5px 10px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        border-radius: 15px;
        margin-top: 4px;
        margin-bottom: 0px;
        transition: background-color 0.3s;
    }

    .product-image-wrapper {
        width: 100%;
        height: 180px;
        overflow: hidden;
    }

}


@media only screen and (max-width: 1024px) and (min-width: 991px) {

  .product-image-wrapper {
    width: 100%;
    height: 220px;
    overflow: hidden;
}

.product {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 400px;
    margin-bottom: 20px;
}

.product img {
    width: 100%;
    height: 210px;
    -o-object-fit: cover;
    object-fit: cover;
}

.horizontal-group-produk {
        display: flex;
        margin-bottom: 24px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }

    .group-item {
        padding: 5px 8px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
    }

}

    
</style>
