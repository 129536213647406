<template>
  <HeaderCom />
  <section class="saasio-breadcurmb-section-contact">
      <div class="container">
          <div class="breadcurmb-title-contact">
              <h2>{{ $t('ContactUs.title') }}</h2>
              <p>{{ $t('ContactUs.subtitle') }}</p>
          </div>
      </div>
  </section>
  <div class="container-contact" style="background: url('assets/img/business-4/back/contactt.png')">
      <div class="contact-form">
          <h2>{{ $t('ContactUs.title2') }}</h2>
          <p>{{ $t('ContactUs.subtitle2') }}</p>
          <form @submit.prevent="submitForm">
              <!-- Salutations and name inputs -->
              <div class="form-row">
                  <select v-model="formData.salutation">
                      <option disabled value="">Salutations</option>
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Other">Other</option>
                  </select>
                  <input v-if="formData.salutation === 'Other'" type="text" v-model="formData.CustomSalutations" placeholder="Other Salutations">
                  <input type="text" v-model="formData.firstname" placeholder="First name*">
                  <input type="text" v-model="formData.lastname" placeholder="Last name*">
              </div>
              <!-- Contact details -->
              <div class="form-row">
                  <input type="email" id="email" v-model="formData.email" placeholder="Enter your email*">
              </div>
              <div class="form-row">
             <vue-tel-input
                v-model="formData.no_hp"
                :placeholder="$t('ContactUs.title')"
                :defaultCountry="defaultCountry"
                :enabledCountryCode="true"
                :inputOptions="{ showDialCode: true }">
              </vue-tel-input>
            </div>
            
            <div class="form-row flex">
                <div class="form-row flex">
                
                  <input type="text" id="search" v-model="searchTerm" :placeholder="formData.country || 'Country*'" />
                <ul v-if="searchCountries.length">
                  <li v-for="country in searchCountries" :key="country.name" @click="selectCountry(country.name)">
                    {{ country.name }}
                  </li>
                </ul>
                </div>
              </div>

              <!-- Company and job title -->
              <div class="form-row">
                  <input type="text" v-model="formData.company" placeholder="Company*">
                  <input type="text" v-model="formData.job_title" placeholder="Job title*">
              </div>
              <!-- Request type with conditional input for 'Other' -->
              <div class="form-row">
                  <select v-model="formData.request">
                      <option disabled selected value="">Request*</option>
                      <option value="General Information">General Information</option>
                      <option value="Product / Sample Request">Product / Sample Request</option>
                      <option value="Suppliers / Vendors">Suppliers / Vendors</option>
                      <option value="Collaborations / Sponsorships">Collaborations / Sponsorships</option>
                      <option value="PR / Press Media">PR / Press Media</option>
                      <option value="Other">Other</option>
                  </select>
                  <input v-if="formData.request === 'Other'" type="text" v-model="formData.Customrequest" placeholder="Other Request">
              </div>
              <!-- Additional comments and agreement to privacy policy -->
              <textarea v-model="formData.comments" placeholder="Any comments*"></textarea>
              <div class="checkbox-group">
                <div class="checkbox-wrapper">
                  <input type="checkbox" id="privacy" v-model="formData.agree">
                  <label for="privacy">
                    <span class="checkbox-text">{{ $t('ContactUs.privacy') }} <span class="highlight">{{ $t('ContactUs.policy') }}</span></span>
                  </label>
                </div>
              </div>
              <div ref="recaptcha"></div>
              <button type="submit" class="simpancontact">{{ $t('ContactUs.buttom') }}</button>
          </form>
      </div>
      <div class="brand-info">
          <h2>{{ $t('ContactUs.title3') }} <strong>Pura MICS?</strong></h2>
          <p>{{ $t('ContactUs.subtitle3') }} <span style="background-color: #FFC107; color: #000000;"> <strong>{{ $t('ContactUs.subtitle33') }}</strong> </span> {{ $t('ContactUs.subtitle333') }}</p>
          <img src="assets/img/business-4/back/rokok.png" alt="Brand Info Image" class="brand-info-image">
        </div>
  </div>
  <div class="contact-maps">
    <h1>{{ $t('ContactUs.title4') }}</h1>
    <hr class="gold-line">
    <h5>{{ $t('ContactUs.subtitle4') }} </h5>
    <h5>{{ $t('ContactUs.subtitle5') }}
      </h5>
    <div class="maps-container">
        <div class="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.1794570909474!2d110.8227133700839!3d-6.8345992150953405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e70c577af3c49cf%3A0x935e5ec3595fa214!2sPura%20Unit%20Rotogravure%203%20(%20MICS%20)!5e0!3m2!1sid!2sid!4v1718958288837!5m2!1sid!2sid" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="info">
            <h2>{{ $t('ContactUs.title6') }}</h2>
            <ul class="contact-list">
            <li class="contact-item">
              <a href="https://maps.app.goo.gl/p6oBtjAaQERGgU4o8" target="_blank">
                <div class="contact-header">
                <img src="https://img.icons8.com/fluency-systems-filled/35/858585/marker.png" alt="marker" class="contact-icon">
                <h2 class="contact-title">PURA GROUP Marketing Jakarta</h2>
              </div>
              </a>
              <p class="contact-address">Graha Pura, Jl. Panconron Indah No. 5A, Jakarta Selatan</p>
            </li>
            <li class="contact-item">
              <a href="https://maps.app.goo.gl/AbQxffCFGAaWk19r5" target="_blank">
               <div class="contact-header">
                <img src="https://img.icons8.com/fluency-systems-filled/40/858585/marker.png" alt="marker" class="contact-icon">
                <h2 class="contact-title">PURA GROUP Marketing Jawa Barat</h2>
              </div>
              <p class="contact-address">Jl. Kopo No. 6B, Komplek Kopo Mas Regency Blok N 11 D, Bandung, Jawa Barat</p>
				</a>
            </li>
            <a href="https://maps.app.goo.gl/8iWzbC5dXpffNXF79" target="_blank"><li class="contact-item">
              <div class="contact-header">
                <img src="https://img.icons8.com/fluency-systems-filled/40/858585/marker.png" alt="marker" class="contact-icon">
                <h2 class="contact-title">PURA GROUP Marketing Jawa Tengah</h2>
              </div>
              <p class="contact-address">Jl. Dr. Ramaelan No. 20, Magersari, Pemalang, Kec. Kota Kudus, Kabupaten Kudus, Jawa Tengah</p>
            </li>
            <br>
          </a>  
            <li class="contact-item">
              <a href="https://maps.app.goo.gl/w52sttk62LnEKPgh6" target="_blank">
                <div class="contact-header">
                <img src="https://img.icons8.com/fluency-systems-filled/40/858585/marker.png" alt="marker" class="contact-icon">
                <h2 class="contact-title">PURA GROUP Marketing Jawa Timur</h2>
              </div>
              </a>
              <p class="contact-address">Jalan Raya Panjang Jiwo Permai No.19-20 Blok 1B, Panjang Jiwo, Kec. Tenggilis Mejoyo, Surabaya, Jawa Timur</p>
            </li>
          </ul>
        </div>
    </div>
  </div>
  <FooterCom />
</template>

<script>
import axios from 'axios';
import FooterCom from '@/components/FooterCom.vue';
import HeaderCom from '@/components/HeaderCom.vue';
import { VueTelInput } from 'vue-tel-input';
import countries from '@/data/countries.json';

export default {
  name: 'ContactUs',
  components: {
    FooterCom,
    HeaderCom,
    VueTelInput
  },

  data() {
    return {
      formData: {
        salutation: '',
        CustomSalutations: '',
        firstname: '',
        lastname: '',
        email: '',
        no_hp: '',
        country: '',
        company: '',
        job_title: '',
        request: '',
        Customrequest: '',
        comments: '',
        agree: false,
        recaptchaToken: '',
        tanggal: new Date().toISOString().substr(0, 19)
      },
      defaultCountry: 'ID',
      searchTerm: '',
      countries: countries  // Here you use the imported countries data
    };
  },
  computed: {
    searchCountries() {
      if (this.searchTerm === '') return [];
      return this.countries.filter(country =>
        country.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    selectCountry(countryName) {
      this.formData.country = countryName;
      this.searchTerm = '';
    },
    loadRecaptcha() {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.onload = () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render(this.$refs.recaptcha, {
            sitekey: '6LfyiR8qAAAAAE1pz6YwVbWzRDw1byHQru7v8EAN',
            callback: this.onRecaptchaVerified
          });
        });
      };
    },
    onRecaptchaVerified(token) {
      this.formData.recaptchaToken = token;
    },
    async submitForm() {
      if (!this.formData.agree) {
        alert('Please agree to the Privacy Policy');
        return;
      }
      if (this.formData.recaptchaToken === '') {
        alert('Please complete the reCAPTCHA');
        return;
      }
      
      // Update formData if Salutations or request is "Other"
      if (this.formData.salutation === 'Other') {
        this.formData.salutation = this.formData.CustomSalutations;
      }
      if (this.formData.request === 'Other') {
        this.formData.request = this.formData.Customrequest;
      }

      try {
        await axios.post('https://puracigarettesolutions.com:3000/api/email/send-email', this.formData);
        alert('Quuestion sent successfully!');
        this.resetForm();
      } catch (error) {
        console.error('Error in form submission', error);
        alert('An error occurred while sending the save. Please try again.');
      }
    },
    resetForm() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = key === 'agree' ? false : '';
      });
    }
  }
}
</script>



<style>

.form-row input, .contact-form select, .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
}

.contact-form .form-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.contact-form select {
    flex: 1;
}

.contact-form input[type="text"],
.contact-form input[type="email"] {
    flex: 1;
}

.contact-form textarea {
    width: 100%;
    height: 100px;
    resize: none;
}


.contact-form button {
    width: 100%;
    padding: 10px;
    background-color: #FFC107;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-row.flex {
  display: flex;
  color: #000000;
  flex-direction: column;
  width: 100%;
}

.form-row.flex ul li.selected {
  color: #000;
}

.form-row.flex input {
  width: 100%;
  box-sizing: border-box;
  color: #000;
}

.form-row.flex ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #000;
  border-top: none;
  box-sizing: border-box;
}

.form-row.flex ul li {
  padding: 8px;
  color: #000;
  cursor: pointer;
}

.form-row.flex ul li:hover {
  background-color: #f1f1f1;
  color: #000;
}



@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');


.checkbox-group {
  margin: 15px 15px;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: 30px;
}

.checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 2px;
  width: 15px;
  height: 15px;
}

.checkbox-wrapper label {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
}

.checkbox-text {
  display: inline-block;
}

.checkbox-wrapper .highlight {
  font-weight: bold;
  color: #FFC107;
}

@media (max-width: 480px) {
  .checkbox-wrapper {
    padding-left: 25px;
  }
  
  .checkbox-wrapper input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
  
  .checkbox-wrapper label {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .checkbox-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
  }

  .checkbox-wrapper input[type="checkbox"] {
    position: relative;
    margin-bottom: -15px;
  }

  .checkbox-wrapper label {
    padding-left: 25px;
    position: relative;
  }
}


.vue-tel-input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.vue-tel-input .vti__input {
  background: #FFF;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

  .container-contact {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    min-height: 100vh; /* Full screen height */
    background-size: cover;
    background-position: center;
}

.contact-form {
  margin-top: 100px;
  margin-left: 100px;
  margin-bottom: 100px;
    width: 80%; /* Adjust the width as per your layout */
    padding: 20px;
    background: #FFFFFF; /* White background */
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add shadow for depth */
}

.contact-form h2 {
    color: #000000; /* Darker font for better readability */
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 35px;
  
}

.contact-form  p {
  font-family: 'Poppins', sans-serif;
  color: #000000; /* Darker font for better readability */
  font-size: 12px;

}

.form-rowinput, .contact-form select, .contact-form textarea {
    width: 90%; /* Responsive width */
    padding: 15px;
    margin-top: 10px; /* Space between inputs */
    background: #FFFFFF; /* White background */
    border: none; /* No border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 
        inset -5px -5px 5px rgba(255, 255, 255, 0.8), 
        inset 5px 5px 5px rgba(0, 0, 0, 0.1),
        0 10px 15px rgba(0, 0, 0, 0.1); /* Simulating depth with external shadow */
    box-sizing: border-box; /* Border box model */
}

.contact-form textarea {
  height: 150px;
}


.contact-form input:focus, .contact-form textarea:focus {
    outline: none;
    box-shadow: 
        inset -3px -3px 3px rgba(255, 255, 255, 0.9), 
        inset 3px 3px 3px rgba(0, 0, 0, 0.15),
        0 8px 10px rgba(0, 0, 0, 0.15); /* Adjusted focus style for depth */
}



.contact-form button {
    width: auto;
    padding: 10px 20px;
    margin-top: 20px; /* Space above the button */
    background-color: #FFC107; /* Primary color for the button */
    color: #000000;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.contact-form button:hover {
    background-color: #000000; /* Darker shade on hover */
    color: #FFFFFF;
}

@media only screen and (min-width: 1921px) {
  .brand-info-image {
    top: 580px;
    position: absolute; 
    right: 0;   
    bottom: 0;    
    max-width: 50%;   
    height: 850px;   
    z-index: 0;
  }
  .brand-info h2 {
    margin-top: 90px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Oswald', sans-serif;
    color: #ffffff;
    font-size: 35px; 
}

.brand-info p {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 150px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 20px;
}

}

@media only screen and (max-width: 1920px) and (min-width: 1441px) {
  .brand-info-image {
    top: 580px;
    position: absolute; 
    right: 0;   
    bottom: 0;    
    max-width: 50%;   
    height: 850px;   
    z-index: 0; }

    .brand-info h2 {
    margin-top: 90px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Oswald', sans-serif;
    color: #ffffff;
    font-size: 35px; 
}

.brand-info p {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 150px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 20px;
}

}

@media only screen and (max-width: 1440px) and (min-width: 1281px) {
  .brand-info-image {  
     position: absolute; 
    right: 0;   
    bottom: 0;   
    top: 580px;   
    max-width: 50%;   
    height: 845px;   
    z-index: 0; }

    .brand-info h2 {
    margin-top: 90px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Oswald', sans-serif;
    color: #ffffff;
    font-size: 35px; 
}

.brand-info p {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 150px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 20px;
}

}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .brand-info-image {
    top: 570px;
    position: absolute; 
    right: 0;   
    bottom: 0;    
    max-width: 50%;   
    height: 850px;   
    z-index: 0;
  }
  .brand-info h2 {
  font-size: 35px;
        margin-top: -50px;
        margin-left: 20px;
        margin-right: 50px;
        color: #ffffff;
  }

  .brand-info h2 {
    margin-top: 90px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Oswald', sans-serif;
    color: #ffffff;
    font-size: 35px; 
}

.brand-info p {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 150px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 20px;
}

}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .brand-info-image {
    top: 1333px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 30%;
        height: 300px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 162px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 162px;
    margin-top: 50px;
    margin-left: -35px;
    margin-right: 150px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 20px;
}

}

@media only screen and (max-width: 991px) and (min-width: 901px) {
  .brand-info-image {
    top: 1331px;
        position: absolute;
        right: 20px;
        bottom: 0;
        max-width: 30%;
        height: 300px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 164px;
        margin-top: -70px;
        margin-left: -40px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 125px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 20px;
    }
}


@media only screen and (max-width: 900px) and (min-width: 781px) {
  .brand-info-image {
    top: 1300px;
        position: absolute;
        right: 20px;
        bottom: 0;
        max-width: 30%;
        height: 300px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 164px;
        margin-top: -70px;
        margin-left: -40px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 125px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 20px;
    }
}


@media only screen and (max-width: 780px) and (min-width: 761px) {
  .brand-info-image {
    top: 1243px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 30%;
        height: 300px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 55px;
        margin-top: -100px;
        margin-left: -40px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 75px;
        margin-top: 20px;
        margin-left: -60px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 20px;
}

}

@media only screen and (max-width: 760px) and (min-width: 700px) {
  .brand-info-image {
    top: 1260px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 35%;
        height: 350px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 70px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 70px;
        margin-top: 15px;
        margin-left: -35px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 20px;
}
}

@media only screen and (max-width: 699px) and (min-width: 501px) {
  .brand-info-image {
    top: 1192px;
        position: absolute;
        right: 15px;
        bottom: 0;
        max-width: 35%;
        height: 270px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px 60px;
        margin-top: -90px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 30px;
        margin-top: 15px;
        margin-left: -10px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}

@media only screen and (max-width: 500px) and (min-width: 481px) {
  .brand-info-image {
    top: 1300px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 35%;
        height: 350px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 35px;
        padding: 0px 70px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 70px;
        margin-top: 15px;
        margin-left: -35px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 20px;
}
}

@media only screen and (max-width: 480px) and (min-width: 401px) {
  .brand-info-image {
    top: 1314px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 35%;
        height: 250px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px 30px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 20px;
        margin-top: 15px;
        margin-left: -30px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}

@media only screen and (max-width: 400px) and (min-width: 381px) {
  .brand-info-image {
        top: 1338px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 35%;
        height: 250px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px 30px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 20px;
        margin-top: 15px;
        margin-left: -30px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}


@media only screen and (max-width: 380px) and (min-width: 361px) {
  .brand-info-image {
    top: 1337px;
        position: absolute;
        right: 30px;
        bottom: 0;
        max-width: 35%;
        height: 250px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px 20px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 3px;
        margin-top: 15px;
        margin-left: -15px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}

@media only screen and (max-width: 360px) and (min-width: 341px) {
  .brand-info-image {
    top: 1328px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 50%;
        height: 250px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px 20px;
        margin-top: -50px;
        margin-left: -35px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 3px;
        margin-top: 15px;
        margin-left: -15px;
        margin-right: 150px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}


@media only screen and (max-width: 340px) {
  .brand-info-image {
    top: 1357px;
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 50%;
        height: 250px;
        z-index: 0;
  }

  .brand-info h2 {
    font-size: 25px;
        padding: 0px -15px;
        margin-top: -50px;
        margin-left: 0px;
        margin-right: 50px;
        color: #ffffff;
  }


.brand-info p {
  padding: 0px 0px;
        margin-top: 15px;
        margin-left: -5px;
        margin-right: 120px;
        font-family: 'Poppins', sans-serif;
        color: #ffffff;
        font-size: 14px;
}
}




form p{
    color: #000000;
}


.contact-maps {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.contact-maps h1 {
  margin-top: 25px;
  color: #000000; /* Darker font for better readability */
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

.contact-maps h5 {
    color: #000000;
    font-size: 16px;
    margin: 10px 200px;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
}

.maps-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.map {
  margin-top: 30px;
  width: 800px;
  height: 500px;
  border-radius: 25px;
  overflow: hidden;
}

.map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}


.info {
    flex: 1;
    padding: 20px;
}

.info h2 {
  color: #DFA419; /* Darker font for better readability */
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.contact-item {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: #000000;
}

.contact-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-header h2 {
  font-size: 24px;
  font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: #000000;
}

.contact-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-shrink: 0;
}

.contact-title {
  font-size: 18px;
  margin: 0;
  line-height: 1.2;
}

.contact-address {
  margin: 0;
  padding-left: 34px;
  font-size: 14px;
  line-height: 1.4;
}

.gold-line {
  width: 150px;
  height: 4px;
  background-color: gold;
  margin: 10px auto;
  border-radius: 2px;
}


.info li:last-child {
    margin-bottom: 0;
}


@media (max-width: 1025px) {
  .container-contact {
    flex-direction: column;
    align-items: center;
  }

  .contact-form h2 {
    font-size: 24px;
  }


  .contact-form p{
    font-size: 14px;
    
  }

  .contact-form button {
    font-size: 16px;
  }

  .contact-maps h5 {
    margin: 10px 35px

  }

    
.maps-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.map {
  margin-top: 30px;
  width: 350px;
  height: 200px;
  border-radius: 25px;
  overflow: hidden;
}

.map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.map, .info{
  margin-top: 50px;;

}

.contact-item {
    padding: 12px;
  }

  .contact-icon {
        width: 35px;
        height: 35px;
    }

  .contact-header {
      font-size: 20px;
    }

  .contact-title {
    font-size: 16px;
  }

  .contact-address {
    font-size: 13px;
    padding-left: 30px;
  }


}


/* Style adjustments for tablets */
@media (max-width: 768px) {
  .container-contact {
    flex-direction: column;
    align-items: center;
  }

  .contact-form, .brand-info {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;

  }

  .contact-form h2 {
    font-size: 24px;
  }



  .contact-form p{
    font-size: 14px;
    
  }

  .contact-form button {
    font-size: 16px;
  }

  .contact-maps h5 {
    margin: 10px 35px

  }

    
.maps-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.map {
  margin-top: 30px;
  width: 600px;
  height: 400px;
  border-radius: 25px;
  overflow: hidden;
}

.map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.map, .info{
  margin-top: 50px;;

}

.contact-item {
    padding: 12px;
    margin-bottom: 10px;
  }

  .contact-icon {
    width: 20px;
    height: 20px;
  }

  .contact-header {
      font-size: 20px;
    }

  .contact-title {
    font-size: 16px;
  }

  .contact-address {
    font-size: 13px;
    padding-left: 30px;
  }


}

/* Style adjustments for mobile phones */
@media (max-width: 480px) {
  .contact-form, .brand-info {
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 2.5%;
  }

  .contact-form h2 {
    font-size: 24px;
  }

  .contact-form p{
    font-size: 14px;
    
  }


  .contact-form button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .contact-form input, .contact-form textarea {
    width: 100%; /* Mengisi lebar yang tersedia */
  }

  .contact-maps h1 {
    font-size: 28px;
  }

  .contact-maps h5 {
    font-size: 11px;
    margin: 0px 50px;
    text-align: center;
    margin-top: 15px;
  }

  
.maps-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.map {
  margin-top: 30px;
  width: 350px;
  height: 200px;
  border-radius: 25px;
  overflow: hidden;
}

.map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.map, .info{
  margin-top: 50px;;

}

.contact-item {
    padding: 10px;
  }

  .contact-header h2 {
    font-size: 16px;
  }

  .contact-icon {
    width: 18px;
    height: 18px;
  }

  .contact-title {
    font-size: 15px;
  }

  .contact-address {
    font-size: 12px;
    padding-left: 28px;
  }

}

@media only screen and (max-width: 991px) {
  .maps-container {
    flex-direction: column;
  }

  .map {
    order: -1; /* Memindahkan peta ke atas */
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }

  .info {
    width: 100%;
  }

  .contact-item {
    margin-bottom: 15px;
  }

  .contact-header h2 {
    font-size: 20px;
  }

  .contact-address {
    font-size: 14px;
  }
}
</style>
