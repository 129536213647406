<template>
    <div id="menu-header">
      <header :class="['apbc-header-section', {'apbc-header-sticky': isSticky}]">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-2 col-md-6 col-7">
          <div class="apbc-logo-wrapper">
            <a href="#"><img :src="logoPath" alt="Logo"></a>
          </div>
        </div>
        <div class="col-lg-8 apbc-desktop-menu">
          <div class="apbc-nav-menu">
            <ul>
              <li :class="{ 'active': activeSection === 'home' }"><a href="/">{{ $t('header.home') }}</a></li>
              <li :class="{ 'active': activeSection === 'about' }"><a href="/AboutPage">{{ $t('header.about') }}</a></li>
              <li :class="{ 'active': activeSection === 'product' }"><a href="/ProdukPage">{{ $t('header.product') }}</a></li>
              <li :class="{ 'active': activeSection === 'blog' }"><a href="/BlogPura">{{ $t('header.blog') }}</a></li>
              <li :class="{ 'active': activeSection === 'contactus' }"><a href="/ContactUs">{{ $t('header.contact') }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-5 d-flex justify-content-end align-items-center">
          <div class="apbc-lang-menu d-none d-lg-flex">
            <ul class="d-flex align-items-center">
              <li class="language-switcher">
                <button :class="{ 'selected': locale.value === 'en' }" @click="changeLanguage('en')">EN</button>
              </li>
              <li class="separator">|</li>
              <li class="language-switcher">
                <button :class="{ 'selected': locale.value === 'id' }" @click="changeLanguage('id')">ID</button>
              </li>
            </ul>
          </div>
          <div class="apbc-mobile-menu-open d-lg-none">
            <a href="#" @click="toggleMobileMenu" aria-label="Mobile"><i class="fas fa-bars"></i></a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Mobile Navigation -->
  <div class="apbc-mobile-navigation" :class="{ 'open': isMobileMenuOpen }">
    <div class="apbc-mobile-menu-wrapper">
      <a href="#" class="apbc-mobile-menu-close" @click="toggleMobileMenu" aria-label="Close mobile menu"><i class="fas fa-times"></i></a>
      <nav>
        <ul>
          <li :class="{ 'active': activeSection === 'home' }"><a href="/">{{ $t('header.home') }}</a></li>
          <li :class="{ 'active': activeSection === 'about' }"><a href="/AboutPage">{{ $t('header.about') }}</a></li>
          <li :class="{ 'active': activeSection === 'product' }"><a href="/ProdukPage">{{ $t('header.product') }}</a></li>
          <li :class="{ 'active': activeSection === 'blog' }"><a href="/BlogPura">{{ $t('header.blog') }}</a></li>
          <li :class="{ 'active': activeSection === 'contactus' }"><a href="/ContactUs">{{ $t('header.contact') }}</a></li>
        </ul>
        <div class="translite">
          <ul class="d-flex align-items-center">
            <li class="language-switcher">
              <button :class="{ 'selected': locale.value === 'en' }" @click="changeLanguage('en')">EN</button>
            </li>
            <li class="separator">|</li>
            <li class="language-switcher">
              <button :class="{ 'selected': locale.value === 'id' }" @click="changeLanguage('id')">ID</button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  </div>
  
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default {
  name: 'HeaderCom',
  setup() {
    const activeSection = ref('home');
    const { locale } = useI18n();
    const router = useRouter();
    const isSticky = ref(false);
    const isMobileMenuOpen = ref(false);

    const changeLanguage = (lang) => {
      console.log(`Changing language to: ${lang}`);
      locale.value = lang;
    };

    const navigateTo = (path) => {
      router.push(path).then(() => {
        if (router.currentRoute.value.name === 'DetailBlog') {
          // If we're navigating to a DetailBlog page, call the update method
          router.currentRoute.value.matched[0].instances.default.handleRouteChange();
        }
      });
    };

    const updateActiveSection = () => {
      const sections = ['home', 'about', 'product', 'blog', 'contactus'];
      const currentRouteName = router.currentRoute.value.name;

      if (sections.includes(currentRouteName)) {
        activeSection.value = currentRouteName;
      } else {
        activeSection.value = '';
      }
    };

    const handleScroll = () => {
      const scrollThreshold = 100;
      isSticky.value = window.scrollY > scrollThreshold;
    };

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };

    onMounted(() => {
      updateActiveSection();
      window.addEventListener('scroll', handleScroll);
    });


    const logoPath = computed(() => {
      return isSticky.value ? '/assets/img/business-4/LOGO_Black.png' : '/assets/img/business-4/LOGO.png';
    });

    return { activeSection, changeLanguage, locale,   navigateTo, isSticky, logoPath, isMobileMenuOpen, toggleMobileMenu };
  },
};
</script>

<style scoped>

/* Header section styles */
.apbc-header-section {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 10;
   background-color: rgb(0, 0, 0); /* 50% transparency */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.apbc-header-sticky {
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 20;
}


.apbc-logo-wrapper a {
  color: white; /* Inherits text color based on header state */
}

.apbc-nav-menu ul li a {
  color: white; /* Inherits text color based on header state */
}

.apbc-nav-menu ul li.active a {
  color: gold; /* Active link color */
}

.apbc-nav-menu ul li a:hover {
  color: gold; /* Hover color */
}

/* Specific styles for the mobile menu */
.apbc-mobile-navigation {
  position: fixed;
  right: -320px;
  top: 0;
  width: 300px;
  height: 100vh;
  overflow: scroll;
  background-color: #000000;
  padding-top: 20px;
  z-index: 300;
  transition: right 0.3s ease-in;
}

.apbc-mobile-navigation.open {
  right: 0;
}

.apbc-mobile-menu-open a {
  background-color: gold;
  color: black;
}

.apbc-header-sticky .apbc-nav-menu ul li a {
  color: black;
  font-weight: bold;
}

.apbc-header-sticky .apbc-nav-menu ul li.active a {
  color: gold; /* Active link color */
}

.apbc-header-sticky .apbc-logo-wrapper a,
.apbc-header-sticky .language-switcher button {
  color: black;
}

.apbc-lang-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.translite {
  margin-left: 20px;
}

.language-switcher {
  display: flex;
  align-items: center;
}

.language-switcher button {
  background: none;
  border: none;
  color: white;
  margin-top: 13px;
  font-family: 'Poppins', sans-serif; 
  font-size: 12px;
  cursor: pointer;
  padding: 10px 15px; /* Adjusted padding for better width */
  margin-right: 2px;
}

.language-switcher button.selected {
  color: gold;
}

.language-switcher button:hover {
  color: gold;
}

.language-switcher button:active {
  color: gold; /* Ensuring the color stays consistent when clicked */
}

.separator {
  color: white;
  margin-top: 13px;
  padding: 0 5px;
}

.apbc-header-sticky .separator {
  color: black;
}

.d-none {
  display: none;
}

.d-lg-flex {
  display: flex !important;
}

.d-lg-none {
  display: none !important;
}

@media (max-width: 991.98px) {
  .d-lg-flex {
    display: none !important;
  }

  .d-lg-none {
    display: block !important;
  }
}
</style>
