<template>
    <div class="wrapper">
      <NavbarDs />
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="index3.html" class="brand-link">
          <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
          <span class="brand-text font-weight-light">PURA MISC</span>
        </a>
        <SidebarDs />
      </aside>
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <div class="col-6">
                  <h5>Tulisan Baru</h5>
                </div>
                <div class="text-right">
                  <router-link to="/TampilanArtikel" class="btn btn-primary" style="color: #ffffff;">
                    <i class="fas fa-angle-left"></i> Kembali
                  </router-link>
                </div>
              </div>
              <form @submit.prevent="update" class="card-body">
                <div class="form-group">
                  <input type="hidden" id="id_artikel" v-model="post.id_artikel" class="form-control" placeholder="Enter Post Title" required>
                  <label for="postTitle">Title EN:</label>
                  <ckeditor :editor="editor" v-model="post.judul_en" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="postTitle">Title ID:</label>
                  <ckeditor :editor="editor" v-model="post.judul_id" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="category">Category:</label>
                  <select v-model="post.id_kategori" class="form-control">
                    <option v-for="kategoriItem in kategori" :key="kategoriItem.id_kategori" :value="kategoriItem.id_kategori">
                      {{ kategoriItem.nama_kategori }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="author">Tanggal:</label>
                  <input type="date" id="tanggal" v-model="post.tanggal" class="form-control" required>
                </div>
                <div class="form-group">
                  <label for="articleContent">Deskripsi EN:</label>
                  <ckeditor :editor="editor" v-model="post.deskripsi_en" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="articleContent">Deskripsi ID:</label>
                  <ckeditor :editor="editor" v-model="post.deskripsi_id" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="articleContent">Content EN:</label>
                  <ckeditor :editor="editor" v-model="post.keterangan_en" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="articleContent">Content ID:</label>
                  <ckeditor :editor="editor" v-model="post.keterangan_id" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="articleContent">Content 2 EN:</label>
                  <ckeditor :editor="editor" v-model="post.keterangan_en2" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="articleContent">Content 2 ID:</label>
                  <ckeditor :editor="editor" v-model="post.keterangan_id2" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="author">Pembuat:</label>
                  <input type="text" id="pembuat" v-model="post.pembuat" class="form-control" required>
                </div>
                <div class="form-group">
                <label for="customTags">Custom Tags:</label>
                <select id="keyword" ref="keyword" multiple class="form-control">
                  <option v-for="tag in post.keyword" :value="tag" :key="tag">{{ tag }}</option>
                </select>
              </div>
                <div class="form-group">
                  <label for="author">Referensi 1 :</label>
                  <input type="text" id="link_ref1" v-model="post.link_ref1" class="form-control">
                </div>
                <div class="form-group">
                  <label for="author">Referensi 2 :</label>
                  <input type="text" id="link_ref2" v-model="post.link_ref2" class="form-control">
                </div>
                <div class="form-group">
                  <label for="author">Referensi 3 :</label>
                  <input type="text" id="link_ref3" v-model="post.link_ref3" class="form-control">
                </div>
                <div class="form-group">
                  <label for="author">Referensi 4 :</label>
                  <input type="text" id="link_ref4" v-model="post.link_ref4" class="form-control">
                </div>
                <div class="form-group">
                  <label for="imageUpload">Gambar untuk Home</label>
                  <input type="file" @change="handleFileUpload(1)" accept="image/*" />
                  <img v-if="post.gambar1" :src="getFileUrl(post.gambar1)" height="50" width="50" alt="Image Preview" />
                </div>
                <div class="form-group">
                  <label for="imageUpload">Gambar untuk List Artikel</label>
                  <input type="file" @change="handleFileUpload(2)" accept="image/*" />
                  <img v-if="post.gambar2" :src="getFileUrl(post.gambar2)" height="50" width="50" alt="Image Preview" />
                </div>
                <div class="form-group">
                <label for="imageUpload">Gambar Untuk Detail Artikel</label>
                <input type="file" @change="handleFileUpload(3)" accept="image/*" />
                <img v-if="post.gambar3" :src="getFileUrl(post.gambar3)" height="50" width="50" alt="Image Preview" />
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <FooterDs />
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Content here -->
      </aside>
    </div>
  </template>
  
  <script>
  import jQuery from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
  import FooterDs from "../components/FooterDs.vue";
  import NavbarDs from "../components/NavbarDs.vue";
  import SidebarDs from "../components/SidebarDs.vue";
  import CKEditor from '@ckeditor/ckeditor5-vue';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import { reactive, ref, onMounted, nextTick } from 'vue';
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  
  export default {
    components: {
      FooterDs,
      NavbarDs,
      SidebarDs,
      ckeditor: CKEditor.component
    },
    setup() {
      const post = reactive({
        id_artikel: '',
        judul_en: '',
        judul_id: '',
        tanggal: '',
        keterangan_en: '',
        keterangan_id: '',
        keterangan_en2: '',
        keterangan_id2: '',
        deskripsi_en: '',
        deskripsi_id: '',
        pembuat: '',
        keyword: [],
        link_ref1: '',
        link_ref2: '',
        link_ref3: '',
        link_ref4: '',
        gambar1: null,
        gambar2: null,
        gambar3: null
      });
      const kategori = reactive([]);
      const imageFiles = ref({ 1: null, 2: null, 3: null });
      const router = useRouter();
  
      const editorConfig = {
        toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'fontColor', // Add fontColor to the toolbar
    'link',
    'bulletedList',
    'numberedList',
    'imageUpload',
    'mediaEmbed',
    '|',
    'undo',
    'redo'
  ]
      };


      onMounted(async () => {
            if (router.currentRoute.value.params.id_artikel) {
                await fetchArticle(router.currentRoute.value.params.id_artikel);
            }
            initializeSelect2();
            await fetchKategori();
        });
  

        const initializeSelect2 = () => {
    jQuery('#keyword').select2({
        tags: true,
        tokenSeparators: [',', '\n'],
        theme: 'classic',
        data: post.keyword.map(tag => ({ id: tag, text: tag }))  // Safely map over the array
    }).on('change', function () {
        const selected = jQuery(this).select2('data');
        post.keyword = selected.map(item => item.id);
    });
};


      const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
            return `${baseUrl}/${filename}`;
        };
  
      const fetchKategori = async () => {
        try {
          const response = await axios.get('https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori');
          kategori.splice(0, kategori.length, ...response.data.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

       const fetchArticle = async (id_artikel) => {
          try {
              const response = await axios.get(`https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel/${id_artikel}`);
              if (response.data.success && response.data.data) {
                  const articleData = response.data.data;
                  Object.keys(post).forEach(key => {
                      // Handle the keyword specifically
                      if (key === 'keyword' && Array.isArray(articleData[key])) {
                          post[key] = articleData[key];
                      } else if (key === 'keyword') {
                          post[key] = [];  // Default to an empty array if not an array
                      } else {
                          post[key] = articleData[key] || post[key];
                      }
                  });
                  nextTick(() => {
                      initializeSelect2();
                  });
              } else {
                  console.error('Invalid or missing data in server response.');
              }
          } catch (error) {
              console.error('Error fetching article data:', error);
          }
      };

         const handleFileUpload = (imageNumber) => (event) => {
            const file = event.target.files[0];
            if (file) {
                imageFiles.value[imageNumber] = file;
                const imageUrl = URL.createObjectURL(file);
                post['gambar' + imageNumber] = imageUrl;
                console.log(`Image ${imageNumber} uploaded: `, imageUrl);
            } else {
                console.log(`No file selected for image ${imageNumber}`);
            }
        };


        const update = async () => {
            const formData = new FormData();
            formData.append('id_artikel', post.id_artikel);
            formData.append('judul_en', post.judul_en);
            formData.append('judul_id', post.judul_id);
            formData.append('id_kategori', post.id_kategori);
            formData.append('keterangan_en', post.keterangan_en);
            formData.append('keterangan_id', post.keterangan_id);
            formData.append('keterangan_en2', post.keterangan_en2);
            formData.append('keterangan_id2', post.keterangan_id2);
            formData.append('deskripsi_en', post.deskripsi_en);
            formData.append('deskripsi_id', post.deskripsi_id);
            formData.append('keyword', post.keyword.join(','));
            formData.append('link_ref1', post.link_ref1);
            formData.append('link_ref2', post.link_ref2);
            formData.append('link_ref3', post.link_ref3);
            formData.append('link_ref4', post.link_ref4);

            // Append images if they have been re-uploaded
            Object.entries(imageFiles.value).forEach(([key, file]) => {
                if (file) {
                    formData.append('gambar' + key, file);
                }
            });

            try {
              const response = await axios.put(`https://puracigarettesolutions.com:3000/api/artikel/EditArtikel/${post.id_artikel}`, formData, {
                      headers: { 'Content-Type': 'multipart/form-data' }
                    });
                if (response.data.success) {
                    alert('Artikel berhasil diperbarui');
                    router.push('/TampilanArtikel');
                } else {
                    alert('Gagal memperbarui Artikel');
                }
            } catch (error) {
                console.error('Error updating product:', error);
                alert('Error updating product: ' + error.message);
            }
        };
  
      return {
        post,
        kategori,
        getFileUrl,
        imageFiles,
        editor: ClassicEditor,
        editorConfig,
        handleFileUpload,
        update
      };
    }
  };
  </script>
  

  
<style>
/* CSS */
.image-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>