<template>
  <div class="wrapper">
    <NavbarDs />
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <a href="index3.html" class="brand-link">
        <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">Pura MICS</span>
      </a>
      <SidebarDs />
    </aside>

    <div class="content-wrapper">
      <section class="content">
        <div class="container-fluid">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <div class="col-6">
                <h5>Blog Baru</h5>
              </div>
              <div class="text-right">
                <router-link to="/TampilanArtikel" class="btn btn-primary" style="color: #ffffff;">
                  <i class="fas fa-angle-left" style="color: #ffffff;"></i> Kembali
                </router-link>
              </div>
            </div>
            <form @submit.prevent="store" class="card-body">
              <div class="form-group">
                  <input type="hidden" id="id_artikel" v-model="post.id_artikel" class="form-control" placeholder="Enter Post Title" required>
                  <label for="postTitle">Title EN:</label>
                  <ckeditor :editor="editor" v-model="post.judul_en" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="postTitle">Title ID:</label>
                  <ckeditor :editor="editor" v-model="post.judul_id" :config="editorConfig"></ckeditor>
                </div>
              <div class="form-group">
                <label for="id_kategori">Category:</label>
                <select id="id_kategori" v-model="post.id_kategori" class="form-control">
                  <option v-for="kategori in kategori" :key="kategori.id_kategori" :value="kategori.id_kategori">
                      {{ kategori.nama_kategori }}
                  </option>
              </select>
              </div>

              <div class="form-group">
                <label for="tanggal">Tanggal:</label>
                <input type="date" id="tanggal" v-model="post.tanggal" class="form-control" required>
              </div>
              <div class="form-group">
                <label for="articleContent">Deskripsi EN:</label>
                <ckeditor label="Deskripsi EN:" id="deskripsi_en" :editor="editor" v-model="post.deskripsi_en"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Deskripsi ID:</label>
                <ckeditor label="Deskripsi ID:" id="deskripsi_id" :editor="editor" v-model="post.deskripsi_id"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content EN:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_en" ></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content ID:</label>
                <ckeditor label="Content ID:" id="keterangan_id" :editor="editor" v-model="post.keterangan_id"></ckeditor>
              </div>

              <div class="form-group">
                <label for="articleContent">Content 2 EN:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_en2" ></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content 2 ID:</label>
                <ckeditor label="Content ID:" id="keterangan_id" :editor="editor" v-model="post.keterangan_id2"></ckeditor>
              </div>

              <div class="form-group">
                <label for="pembuat">Pembuat:</label>
                <input type="text" id="pembuat" v-model="post.pembuat" class="form-control" required>
              </div>

              <div class="form-group">
                <label for="customTags">Keyword Tags:</label>
                <select id="keyword" ref="keyword" multiple class="form-control">
                  <option v-for="tag in post.keyword" :value="tag" :key="tag">{{ tag }}</option>
                </select>
              </div>
                            <!-- Handle multiple reference links dynamically -->
              <div class="form-group" v-for="n in 4" :key="n">
                <label :for="'link_ref' + n">Referensi {{ n }}:</label>
                <input :id="'link_ref' + n" v-model="post['link_ref' + n]" class="form-control" :placeholder="'Enter Reference ' + n">
              </div>

              <!-- Handle multiple images -->
              <div class="form-group" v-for="n in 3" :key="n">
                <label :for="'gambar' + n">Gambar untuk {{ ['Home', 'List Produk', 'Detail Produk'][n-1] }}:</label>
                <input type="file" :id="'gambar' + n" class="form-control-file" @change="handleImageUpload($event, n)">
                <img v-if="imagePreview[n]" :src="imagePreview[n]" alt="Preview" style="max-width: 200px; max-height: 200px; margin-top: 10px;">
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
    <FooterDs />
  </div>
</template>

<script>
import jQuery from 'jquery';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

export default {
  name: "BlogEntryForm",
  components: { FooterDs, NavbarDs, SidebarDs, ckeditor: CKEditor.component },
  data() {
    return {
      kategori: [],
      post: {
        judul_en: '',
        judul_id: '',
        tanggal: null,
        keterangan_en: '',
        keterangan_id: '',
        keterangan_en2: '',
        keterangan_id2: '',
        deskripsi_en: '',
        deskripsi_id: '',
        pembuat: '',
        link_ref1: '',
        link_ref2: '',
        link_ref3: '',
        link_ref4: '',
        keyword: [],
        id_kategori: null,
        gambar1: null,
        gambar2: null,
        gambar3: null
      },
      imagePreview: ['', '', ''],
      editor: ClassicEditor,
      editorConfig: {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'fontColor', // Add fontColor to the toolbar
    'link',
    'bulletedList',
    'numberedList',
    'imageUpload',
    'mediaEmbed',
    '|',
    'undo',
    'redo'
  ],
  fontColor: {
    colors: [
      { color: 'hsl(0, 75%, 60%)', label: 'Red' },
      { color: 'hsl(30, 75%, 60%)', label: 'Orange' },
      { color: 'hsl(60, 75%, 60%)', label: 'Yellow' },
      { color: 'hsl(90, 75%, 60%)', label: 'Light Green' },
      { color: 'hsl(120, 75%, 60%)', label: 'Green' },
      { color: 'hsl(150, 75%, 60%)', label: 'Aquamarine' },
      { color: 'hsl(180, 75%, 60%)', label: 'Turquoise' },
      { color: 'hsl(210, 75%, 60%)', label: 'Light Blue' },
      { color: 'hsl(240, 75%, 60%)', label: 'Blue' },
      { color: 'hsl(270, 75%, 60%)', label: 'Purple' }
    ]
  }
},
      isSubmitting: false
    };
  },
  mounted() {
    this.fetchKategori();
    this.initializeSelect2();
  },
  methods: {
    initializeSelect2() {
    const vm = this;
    jQuery(this.$refs.keyword).select2({
      tags: true,
      tokenSeparators: ['\n', '\r'],
      theme: 'classic'
    }).on('change', function () {
      const data = jQuery(this).select2('data');
      vm.post.keyword = data.map(item => item.text);
    });
  },
 async fetchKategori() {
  try {
      const response = await axios.get('https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori');
      console.log('Kategori Data:', response.data); // Log to see the data structure
      this.kategori = response.data.data;
  } catch (error) {
      console.error('Error fetching categories:', error);
  }
},

store() {
          if (!this.validateForm()) {
            alert('Please fill in all required fields.');
            return;
          }
          this.isSubmitting = true;
          const formData = new FormData();
          Object.keys(this.post).forEach(key => {
            if (key === 'keyword') {
              formData.append(key, this.post.keyword.join(','));
            } else {
              formData.append(key, this.post[key]);
            }
          });

          axios.post('https://puracigarettesolutions.com:3000/api/artikel/CreateArtikel', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(response => {
            console.log('Article submitted:', response.data);
            this.$router.push('/TampilanArtikel');
          })
          .catch(error => {
            if (error.response) {
              console.log('Error data:', error.response.data);
              console.log('Error status:', error.response.status);
              console.log('Error headers:', error.response.headers);
            } else if (error.request) {
              console.log('No response received:', error.request);
            } else {
              console.log('Error setting up request:', error.message);
            }
            this.isSubmitting = false;
          });
        },

validateForm() {
// Define which fields are required
const requiredFields = [
  'judul_en', 'judul_id', 'tanggal', 'pembuat'  // Add other required fields here
];

// Check only the required fields for non-empty values
return requiredFields.every(field => this.post[field]);
},

    handleImageUpload(event, index) {
      const file = event.target.files[0];
      if (file) {
        this.post['gambar' + index] = file;
        this.imagePreview[index - 1] = URL.createObjectURL(file);
      }
    }
  }
};
</script>


<style scoped>
  .select2-container--classic .select2-selection--multiple,
.select2-container--classic .select2-selection--single {
  color: black; /* Set text color to black */
}
.a {
  color: gold;
}
</style>
