<template>
  <HeaderCom />
  <section class="saasio-breadcurmb-section">
      <div class="container">
          <div class="breadcurmb-title">
            <h3><strong>{{ $t('aboutPage.title') }}</strong></h3>
            <p><span>{{ $t('aboutPage.content') }}</span><a href="https://www.puragroup.com/en/home/"> Pura Grup </a>
               <span> {{ $t('aboutPage.content1') }}</span></p>
              <h3>{{ $t('aboutPage.subtitle') }}</h3>
              <p>{{ $t('aboutPage.content2') }}</p>
              <p>{{ $t('aboutPage.content3') }}</p>
              <p>{{ $t('aboutPage.content4') }}</p>
              <p>{{ $t('aboutPage.more') }}</p>
              <br>
              <br>
          </div>
      </div>
  </section>
  <!-- End of breadcurmb section
      ============================================= -->

      <div class="containerkk">                      
    <div class="row text-left justify-content-left mb-4">
      <div class="col-xl-6 col-lg-8">
        <div class="gold-bar-vertikal"></div> 
        <h2 class="font-weight-bold">{{ $t('StoryPages.title') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <swiper :slides-per-view="3" class="swiper-container" ref="swiperContainer">
          <swiper-slide v-for="step in steps" :key="step.dateLabel" class="timeline-slide" @click="selectStep(step)">
            <div class="timestamp">
              <span class="timestamptitle">{{ step.dateLabel }}</span></div>
            <div class="status">
              <span class="circle"></span>
              <span class="date" v-if="activeStep && step.dateLabel === activeStep.dateLabel">{{ step.titleLabel }}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>

  <div class="power-container">
    <div v-if="activeStep" class="power-gambar">
      <img :src="activeStep.image" :alt="'Image for ' + activeStep.dateLabel" class="logo">
      <div class="text-overlay">
        <p>{{ activeStep.description }}</p>
      </div>
    </div>
  </div>


  <section class="core-values">
    <br>
    <br>
  <h2>{{ $t('valuePage.title') }}
      <div class="gold-bar"></div>
  </h2>
  <div class="values-container">
      <div :class="['value', { expanded: showDetail.visi }]" @click="toggleDetail('visi')">
          <div class="image-text-container">
              <img src="assets/img/business-4/slider/visi.png" data-highres="assets/img/business-4/back/visi_low.png" loading="lazy" alt="Vision">
              <div class="overlay-text-title">
                  <h3>{{ $t('valuePage.subtitle') }}</h3>
              </div>
              <transition name="fade">
                  <div class="overlay-text" v-if="showDetail.visi">
                      <p>{{ $t('valuePage.visi') }}</p>
                  </div>
              </transition>
          </div>
      </div>
      <div :class="['value', { expanded: showDetail.misi }]" @click="toggleDetail('misi')">
          <div class="image-text-container">
              <img src="assets/img/business-4/slider/misi.png" data-highres="assets/img/business-4/slider/misi_low.png" loading="lazy" alt="MISSION">
              <div class="overlay-text-title">
                  <h3>{{ $t('valuePage.subtitle2') }}</h3>
              </div>
              <transition name="fade">
                  <div class="overlay-text" v-if="showDetail.misi">
                      <p>{{ $t('valuePage.misi') }}</p>
                  </div>
              </transition>
          </div>
      </div>
      <div :class="['value', { expanded: showDetail.value }]" @click="toggleDetail('value')">
          <div class="image-text-container">
              <img src="assets/img/business-4/slider/value.png" data-highres="assets/img/business-4/back/value_low.png" loading="lazy" alt="VALUE">
              <div class="overlay-text-title-value">
                  <h3>{{ $t('valuePage.subtitle3') }}</h3>
              </div>
              <transition name="fade">
                  <div class="overlay-text-value" v-if="showDetail.value">
                      <p><strong>{{ $t('valuePage.detail1') }}</strong></p>
                      <p>{{ $t('valuePage.value1') }}</p>
                      <p><strong>{{ $t('valuePage.detail2') }}</strong></p>
                      <p>{{ $t('valuePage.value2') }}</p>
                      <p><strong>{{ $t('valuePage.detail3') }}</strong></p>
                      <p>{{ $t('valuePage.value3') }}</p>
                  </div>
              </transition>
          </div>
      </div>
      <!-- Repeat for other values -->
  </div>
</section>




<div id="quotes"  style="background-image: url('assets/img/business-4/back/quotes.png');">
  <h3><strong>{{ $t('QuotesPage.title') }}</strong></h3>
    <p>{{ $t('QuotesPage.subtitle') }}</p>
</div>

<section class="statistics-section" aria-label="statistics" style="background-image: url('assets/img/business-4/back/skor.png');">
<div class="containerik">
<div class="stat">
<div class="number">{{ year }} &nbsp; |</div>
<div class="label">{{ $t('StatistikPage.title4') }}</div>
</div>
  <div class="stat">
<div class="number">+{{ brand }} &nbsp;|</div>
  <div class="label">{{ $t('StatistikPage.title') }}</div>

</div>
<div class="stat">
<div class="number">+{{ countries }} &nbsp;|</div>
<div class="label">{{ $t('StatistikPage.title2') }}</div>

</div>

<div class="stat">
<div class="number">+{{ clients }} &nbsp;</div>
<div class="label">{{ $t('StatistikPage.title3') }}</div>
</div>
<!-- <div class="stat">
<div class="number">{{ employees }} &nbsp; |</div>
<div class="label">CUSTOMERS</div>
</div>
<div class="stat">
<div class="number">{{ employees }}</div>
<div class="label">CUSTOMERS</div>
</div> -->
</div>
</section>
<div class="banner-section-about">
  <div class="banner-content-about">
    <div class="banner-text-content-about">
      <h1>{{ $t('derecAbout.title1') }} <span class="top-notch-highlight"> {{ $t('derecAbout.title2') }} </span> {{ $t('derecAbout.title3') }} </h1>
      <p>{{ $t('derecAbout.desc') }}</p>
      <button class="primary-button-about"><a href="/SamplePage">{{ $t('derecAbout.buttom') }}<span class="arrow-icon">></span></a></button>
    </div>
    <div class="banner-image-wrapper-about">
      <img src="assets/img/business-4/slider/banner_aboutt.png" alt="Banner Image" class="banner-image-about"/>
    </div>
  </div>
</div>

<div class="about-kreatif-section">
  <div class="title-facility">
    <h2>{{ $t('Facility.title') }}</h2>
    <p>{{ $t('Facility.subtitle') }}</p>
  </div>
  <swiper :navigation="true" :modules="modules" :slides-per-view="3" :space-between="30">
    <swiper-slide v-for="(kreatif, index) in items" :key="index">
      <div class="about-kreatif-item">
        <img :src="kreatif.image" alt="Product Image" class="about-product-image" @click="openFacilityModal(kreatif)"  style="cursor: pointer;">
        <div class="about-kreatif-content">
          <h4 @click="openFacilityModal(kreatif)" style="cursor: pointer;">{{ kreatif.name }}</h4>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</div>


<transition name="fade">
  <div v-if="showFacilityModal" class="modal-overlay" @click.self="showFacilityModal = false">
    <div class="modal-content-fasilitas" @click.stop>
      <span class="close-button" @click="showFacilityModal = false">&times;</span>
      <img :src="activeFacilityImage" alt="Facility Image" class="modal-fasilitas-image">
      <div class="titlefasilitas">
        <h3 >{{ activeFacilityTitle }}</h3>
      </div>
    <div class="fasiliasdesc">
      <p>{{ activeFacilityDescription }}</p>
      <p>{{ activeFacilityDescription2 }}</p>
    </div>
    </div>
  </div>
</transition>

  <section id="sertifikat" class="apbc-team-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="apbc-title-area text-center wow fadeInUp">
                        <div class="apbc-headline">
                            <h3>{{ $t('awardPage.title') }}</h3>
                            <div class="gold-bar-certif"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="custom-apbc-team-single">
  <div class="custom-apbc-img-wrapper text-center" 
       v-for="(image, index) in images" 
       :key="index" 
       @click="openModalWithImage(image)" 
       style="cursor: pointer;">
    <img :src="image.src" :alt="'Image ' + image.id" class="gambarser">
    <p class="image-description">{{ image.description }}</p>
    <p class="nomer">{{ image.nomer }}</p>
  </div>
</div>

<transition name="fade">
  <div v-if="showModal" class="modal-overlay" @click.self="showModal = false" style="z-index: 1050;">
    <div class="modal-content" @click.stop>
      <span class="close-button" @click="showModal = false">&times;</span>
      <img :src="activeImage" alt="Active Image" style="max-width: 100%;">
    </div>
  </div>
</transition>


  <FooterCom />
</template>

<script>
import { computed, watch, onMounted, ref } from 'vue'; 
import FooterCom from '@/components/FooterCom.vue';
import HeaderCom from '@/components/HeaderCom.vue';
import { useI18n } from 'vue-i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

export default {
  name: 'SimulasiCTP',
  components: {
      FooterCom,
      HeaderCom,
      Swiper,
    SwiperSlide,
  },
  setup() {
      const { t, locale } = useI18n();
      const showModal = ref(false);
  const activeImage = ref('');

  const showDetail = ref({
      visi: false,
      misi: false,
      value: false,
    });

    const toggleDetail = (key) => {
      showDetail.value[key] = !showDetail.value[key];
    };
  
  const images = ref([
  { id: 'ECOVADIS', src: 'assets/img/business-4/team/ECOVADIS.svg', certificateSrc: 'assets/img/business-4/team/ECOVADIS POP UP.jpg', description: computed(() => t('awardPage.ecovadis'))},
  { id: 'HALAL', src: 'assets/img/business-4/team/HALAL.svg', certificateSrc: 'assets/img/business-4/team/Sertifikat_Halal.webp', description: computed(() => t('awardPage.halal')), nomer: 'No. ID00410000156580921'},
  { id: 'ISO', src: 'assets/img/business-4/team/ISO.svg', certificateSrc: 'assets/img/business-4/team/Sertifikat_ISO.webp', description: computed(() => t('awardPage.iso')), nomer: 'No. QSC 00119' },
  // More images and descriptions as needed
]);

const swiperContainer = ref(null);


    const steps = ref([
    {
        dateLabel: '1989',
        titleLabel: computed(() => t('StoryPages.title1989')),
        image: 'assets/img/business-4/back/story.png',
        description: computed(() => t('StoryPages.desc1989')),
      },
      {
        dateLabel: '1995',
        titleLabel: computed(() => t('StoryPages.title1995')),
        image: 'assets/img/business-4/back/story2.png',
        description: computed(() => t('StoryPages.desc1995')),
      },
      {
        dateLabel: '2023',
        titleLabel: computed(() => t('StoryPages.title2023')),
        image: 'assets/img/business-4/back/story2023.png',
        description: computed(() => t('StoryPages.desc2023')),
      },
      // Add more steps as needed
    ]);

    const activeStep = ref(steps.value[0]); 

    const selectStep = (step) => {
      activeStep.value = step; // Update the active step with the clicked step data
    };

    onMounted(() => {
      selectStep(steps.value[0]); // Ensure 1989 is selected on mounted
    });

  const productSwiper = ref(null);
  const items = ref([
            { 
              name: computed(() => t('FasilitasPage.titleprof')),
              description: computed(() => t('FasilitasPage.descprof')),
              image: 'assets/img/business-4/service/ppos.png' },
            { name: computed(() => t('FasilitasPage.titletinta')),
             description: computed(() => t('FasilitasPage.desctinta')),
             description2: computed(() => t('FasilitasPage.desctinta2')),
             image: 'assets/img/business-4/service/tinta.png' },
            { name: computed(() => t('FasilitasPage.titlecylinder')), 
            description: computed(() => t('FasilitasPage.desccylinder')),
            description2: computed(() => t('FasilitasPage.desccylinder2')), 
             image: 'assets/img/business-4/service/silindermaker.png' },
            { name: computed(() => t('FasilitasPage.titlemeeting')),
             description: computed(() => t('FasilitasPage.descmeeting')),
              image: 'assets/img/business-4/service/meeting.png' },
              { name: computed(() => t('FasilitasPage.titleshowroom')),
               description: computed(() => t('FasilitasPage.descshowroom')), 
              image: 'assets/img/business-4/service/showroom.png' },
            { name: computed(() => t('FasilitasPage.titlebar')),
             description: computed(() => t('FasilitasPage.descbar')),
             image: 'assets/img/business-4/service/bar.png' },
           
            // Tambahkan lebih banyak produk sesuai kebutuhan
        ]);


const showFacilityModal = ref(false);
const activeFacilityDescription = ref('');
const activeFacilityDescription2 = ref('');
const activeFacilityImage = ref('');
const activeFacilityTitle = ref('');


const openFacilityModal = (product) => {
    // Use the description from the product object directly
    activeFacilityDescription.value = product.description;
    activeFacilityDescription2.value = product.description2;
    activeFacilityImage.value = product.image;
    activeFacilityTitle.value = product.name;
    showFacilityModal.value = true;
};



     const openModalWithImage = (image) => {
  activeImage.value = image.certificateSrc; // Gunakan certificateSrc untuk modal
  showModal.value = true;
};


      // Function to change language
      const changeLanguage = (lang) => {
          console.log(`Changing language to: ${lang}`);
          locale.value = lang;
      };


      // Watch language changes and reload data if the language changes
      const currentLanguage = computed(() => locale.value);

      const getData = () => {
          // Your data fetching logic here
      };

      watch(locale, () => {
          getData(); // Reload data if the language changes
      });

        const formatNumber = (number) => {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      };

 const animateValue = (refValue, end, duration) => {
          let start = refValue.value;
          let range = end - start;
          let increment = end > start ? 1 : -1;
          let stepTime = duration / Math.abs(range); // Calculate step time based on duration

          let timer = setInterval(() => {
              start += increment;
              refValue.value = formatNumber(start); // Format the number before updating
              if (start === end) {
                  clearInterval(timer);
              }
          }, stepTime);
      };

// Example statistics values and animate function call
      const year = ref(0);
      const brand = ref(9000);
      const countries = ref(0);
      const clients = ref(0);

      // Total duration for animation in milliseconds
      let totalDuration = 0.5; // 0.5 seconds for animation

      // Animate statistics values
      animateValue(year, 40, totalDuration);
       animateValue(brand, 10000, totalDuration);
      animateValue(countries, 50, totalDuration);
      animateValue(clients, 1000, totalDuration);

      document.addEventListener("DOMContentLoaded", function() {
    var sliders = document.querySelectorAll('.image-text-container');

    sliders.forEach(function(slider) {
        var highResImage = slider.getAttribute('data-highres');
        var img = new Image();
        img.onload = function() {
            slider.style.backgroundImage = 'url(' + highResImage + ')';
        };
        img.src = highResImage;
    });
});

window.addEventListener('load', function() {
  document.querySelector('.saasio-breadcurmb-section').classList.add('loaded');
});
      return {
          changeLanguage,
          currentLanguage,
          showModal,
          openFacilityModal,
          showFacilityModal,
          activeFacilityDescription,
          activeFacilityDescription2,
          activeFacilityImage,
         activeFacilityTitle,
          activeImage,
          images,
          t,
          openModalWithImage,
         brand,
         year,
          countries,
          clients,
          swiperContainer,
          steps,
      activeStep,
      selectStep,
        productSwiper, 
        items,
        modules: [Navigation],
        showDetail,
        toggleDetail,
      };
  },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');
@import 'swiper/css';


.judul {
  font-weight: 700;
font-size: 36px;
font-family: 'Poppins';
padding-bottom: 20px;
margin: 0;
color: #000000;
}


.justify-text {
  text-align: justify;
}

.contact {
          display: flex;
          justify-content: space-around;
          padding: 10px;
          font-family: 'Montserrat', sans-serif;
      }
      .kolom {
          width: 40%;
          color: #000000;
          font-family: 'Poppins';
          padding: 10px;
          margin: 20px;
          margin-bottom: 50px;
        
      }
      .kolom h3 {
          color: #dcb83d;
          font-family: 'Poppins';
      }

      .center-image {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 300px;
          height: 330px;
          margin-bottom: 100px; /* Atau ukuran spesifik lainnya */
      }

      .custom-apbc-team-single {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ubah ke flex-start untuk memulai dari atas */
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 95%;
}

.custom-apbc-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 30px 15px 0;
  width: 200px;
  height: auto;
}

.custom-apbc-img-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -15px;
}

.custom-apbc-img-wrapper .image-description {
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  text-align: center;
  width: calc(100% + 15px);
  margin-left: 80px;
}

.custom-apbc-img-wrapper .nomer {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  text-align: center;
  width: calc(100% + 15px);
  margin-left: 80px;
}

@media (max-width: 480px) {
  .custom-apbc-img-wrapper {
    margin: 15px auto;
    width: 100%;
    max-width: 200px;
  }

  .custom-apbc-img-wrapper img {
    margin-left: 0;
  }

  .custom-apbc-img-wrapper .image-description {
    width: 100%;
    margin-left: 0;
  }

  .custom-apbc-img-wrapper .nomer {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .custom-apbc-img-wrapper .image-description {
    font-size: 12px;
  }

  .custom-apbc-img-wrapper .nomer {
    font-size: 12px;
  }
}


.custom-apbc-img-wrapper img {
  width: 100%; /* Memastikan gambar menyesuaikan dengan wrapper */
  height: auto; /* Menjaga aspek rasio gambar */
  margin-left: 80px;
  object-fit: cover; /* Memastikan gambar menutupi area tanpa kehilangan aspek rasio */
}

/* Media Query for Tablets */
@media (max-width: 991px) {
  .custom-apbc-team-single {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Mengubah ke center untuk menempatkan konten di tengah */
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 0;
    gap: 10px;
    -webkit-overflow-scrolling: touch;
    
    /* Menambahkan properti berikut untuk memastikan konten tetap di tengah saat di-scroll */
    scroll-snap-type: x mandatory;
    scroll-padding: 0 50%;
  }
  
  .custom-apbc-img-wrapper {
    flex: 0 0 auto;
    width: calc(25% - 10px);
    min-width: 100px;
    max-width: 150px;
    scroll-snap-align: center; /* Memastikan item snap ke tengah saat di-scroll */
  }
  
  .custom-apbc-img-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Scrollbar styling tetap sama */
  .custom-apbc-team-single::-webkit-scrollbar {
    height: 6px;
  }
  
  .custom-apbc-team-single::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  
  .custom-apbc-team-single::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

/* Media Query for Mobile Phones */
@media (max-width: 767px) {
  .custom-apbc-team-single {
  display: flex;
  justify-content: center; /* Pusatkan item secara horizontal */
  align-items: center; /* Pusatkan item secara vertikal */
  flex-wrap: wrap; /* Izinkan item untuk bungkus ke baris berikutnya jika tidak ada cukup ruang */
  gap: 10px; /* Tambahkan jarak antar item */
  margin-bottom: 40px;
  margin-top: 40px;
}

.custom-apbc-img-wrapper {
    height: 200px;

}

.custom-apbc-img-wrapper img {
  width: 100%; /* Memastikan gambar mengisi wrapper */
  height: auto; /* Menjaga rasio aspek */
  margin-left: 0px;
}

.custom-apbc-img-wrapper .image-description {
  margin-top: 5px; /* Jarak di atas teks */
  font-family: 'Poppins', sans-serif; /* Font family untuk konsistensi */
  font-size: 14px; /* Ukuran font */
  color: #4A4A4A; /* Warna teks */
  text-align: center; /* Pusat teks */
  margin-left: 10px;
}

.custom-apbc-img-wrapper .nomer {
  font-family: 'Poppins', sans-serif; /* Font family untuk konsistensi */
  font-size: 14px; /* Ukuran font */
  color: #4A4A4A; /* Warna teks */
  text-align: center; /* Pusat teks */
  margin-left: 10px;
}

}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Angka yang tinggi untuk memastikan muncul di atas semua elemen lain */
  display: flex;
  align-items: center; /* Menengahkan modal secara vertikal */
  justify-content: center; /* Menengahkan modal secara horizontal */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* Atau lebar spesifik */
  max-width: 250px; /* Sesuaikan dengan konten Anda */
  z-index: 1001;
}

.close-button {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
}

/* Gaya tambahan untuk animasi */
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter, .modal-leave-to /* .modal-leave-active in <2.1.8 */ {
  opacity: 0;
}


/* Media Query for Tablets */

/* Media Query for Mobile Phones */
@media (max-width: 767px) {


.modal-content {
  width: 310px;
}

.close-button {
  top: 0px;
  right: 5px;
}

}

  /* Ensure that the container also stretches fully if it has constraints */
  .containerkk {
    text-align: center; /* Center text inside children */
    background-color: #000000; /* Light gray background */
    padding-top: 20px; /* Top padding */
    padding-bottom: 5px; /* Bottom padding */
    border-radius: 0; /* No rounded corners for full width */
    width: 100%; /* Calculate full width including negated margins */
    min-height: 50px; /* Reduced height from default */
    padding: 0; /* Remove padding to ensure full width */
}


  .containerkk h2 {
margin-top: 30px; /* Add margin to the top of the "OUR STORY" title */
 font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 30px; /* Set the font size to 72px */
position: relative; /* Position relative to allow absolute positioning of child elements */
  display: inline-block; /* Ensures the container fits the content's width */
  text-align: center; /* Center the text */
  margin-left: 140px;
}

.gold-bar {
  width: 100%; /* Adjust width to your liking, e.g., 50% of the text width */
  height: 5px; /* Set the thickness of the bar */
  background-color: #FFCA2E; /* Use a gold color for the bar */
  position: absolute; /* Position absolutely within the h2 element */
  top: 100%; /* Position the bar just below the text */
}

.gold-bar-certif {
  width: 20%; /* Adjust width to your liking, e.g., 50% of the text width */
  height: 5px; /* Set the thickness of the bar */
  background-color: #FFCA2E; /* Use a gold color for the bar */
  position: absolute; /* Position absolutely within the h2 element */
  margin-left: 300px;
  top: 100%; /* Position the bar just below the text */
}

@media (max-width: 767px) and (min-width: 481px) {

  .gold-bar-certif {

  margin-left: 175px;
 
}

.gold-bar-certif {
    width: 30%;
    height: 5px;
    background-color: #FFCA2E;
    position: absolute;
    top: 100%;
}
.containerkk h2 {
    font-size: 20px;
    left: -10%;
}

}



@media (max-width: 480px) {

.gold-bar-certif {

margin-left: 125px;

}

.gold-bar-certif {
  width: 30%;
  height: 5px;
  background-color: #FFCA2E;
  position: absolute;
  top: 100%;
}

.containerkk h2 {
    font-size: 20px;
    left: -22%;
}

}

@media (max-width: 360px) {
  .containerkk h2 {
        font-size: 20px;
        left: -5%;
    }
}

.gold-bar-vertikal {
    width: 5px; /* Set the width of the bar, which is now the thickness */
    height: 40%; /* Set the height to extend vertically */
    background-color: #FFCA2E; /* Use a gold color for the bar */
    position: absolute; /* Position absolutely within the h2 element */
    top: 35px; /* Start from the top of the container */
    left: 13%;
    transform: translateX(-50%); /* Center the bar by its width */
}


.core-values {
  margin-top: 250px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}

.core-values h2 {
  font-size: 26px;
  margin-bottom: 50px;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.gold-bar {
  height: 5px;
  background-color: gold;
  width: 50%; /* Adjusted to 50% to match the text width */
  position: absolute;
  top: 110%; /* Adjusted to appear just below the text */
  left: 25%; /* Centers the bar relative to the text */
}

.values-container {
  display: flex;
  justify-content: space-around;
}

.value {
  position: relative;
  width: 30%;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  height: 100px; /* Default compact height */
  overflow: hidden;
  transition: height 0.3s ease;
}

.value.expanded {
  height: auto; /* Expand height to fit content */
}

.value .image-text-container {
  position: relative;
}

.value img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.value.expanded img {
  transform: scale(1.1); /* Slight zoom when expanded */
}

.value .overlay-text-title,
.value .overlay-text-title-value {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  
}

.overlay-text-title h3 {
  color: #FFCA2E;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 50px;
}

.overlay-text-title-value h3 {
  color: #FFCA2E;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 50px;
}

.value .overlay-text,
.value .overlay-text-value {
  display: none; /* Hide text by default */
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 20px;
  color: #fff;
}

.value.expanded .overlay-text,
.value.expanded .overlay-text-value {
  display: block; /* Show text when expanded */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}


/* Media queries for responsiveness */
@media (max-width: 991px) and (min-width: 768px) { /* For tablets */

.value {
    position: relative;
    width: 30%;
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: height 0.3s ease;
}
  .value .overlay-text {
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        padding: 10px;
    
  }

.overlay-text-title-value {
  position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
    width: 90%;
}

.value .overlay-text-value {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 10px;

}

.value h3 {
    margin-bottom: 10px;
    font-size: 26px;

}

.value p {
    
    font-size: 8px;
    text-align: center;
 
}
  
  .core-values h2 {
    font-size: 28px;
  }

  .gold-bar {
    top: 120%;
    width: 60%;
  }

  .core-values {
  margin-top: 50px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}


}

@media (max-width: 765px) and (min-width: 481px) { /* For mobile phones */

  .core-values {
        margin-top: 200px;
        text-align: center;
        padding: 50px;
        background-color: #f5f5f5;
    }

  .core-values h2 {
    font-size: 24px;
  }

  .gold-bar {
    top: 125%;
    width: 70%;
  }

  .values-container {
    flex-direction: column;
    align-items: center;
  }

  .value {
    width: 90%; /* More width for better readability on phones */
    margin-bottom: 15px; /* Space between value blocks */
    height: 100px;
  }

  .value h3 {
    font-size: 45px;
    margin-bottom: 70px;
  }

  .value p {
    font-size: 16px; /* Smaller font size for text on mobile */
  }

  .value .overlay-text, .value .overlay-text-value, .value .overlay-text-title {
    width: 95%; /* Greater width to use space efficiently */
  }
}

@media (max-width: 480px) { /* For mobile phones */
  .core-values {
    padding: 10px;
    margin-top: 10px;
    text-align: center;
  }

  .core-values h2 {
    font-size: 24px;
  }

  .gold-bar {
    top: 125%;
    width: 70%;
  }

  .values-container {
    flex-direction: column;
    align-items: center;
  }

  .value {
    width: 90%; /* More width for better readability on phones */
    margin-bottom: 15px; /* Space between value blocks */
    height: 70px;
  }

  .value h3 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .value p {
    font-size: 16px; /* Smaller font size for text on mobile */
  }

  .value .overlay-text, .value .overlay-text-value, .value .overlay-text-title {
    width: 95%; /* Greater width to use space efficiently */
  }
}


#quotes {
  display: flex; /* Menggunakan Flexbox */
  height: 200px;
  flex-direction: column; /* Anak-anak ditampilkan secara vertikal */
  align-items: center; /* Pusatkan anak secara horizontal */
  text-align: center; /* Pusatkan teks */
}

.inline-group {
  display: flex; /* Mengatur agar "One Product" dan "at a Time" dalam satu baris */
  justify-content: center; /* Pusatkan teks secara horizontal dalam grup */
}

#quotes p {
font-family: 'Poppins', sans-serif;
margin: 0 10px; /* Memberikan margin horizontal antar teks */
padding: 0 10px; /* Add horizontal padding */
font-size: 24px;
color: #FFFFFF;
}

#quotes h3 {
margin-top: 45px;
font-family: 'Poppins', sans-serif;
padding: 0 10px; /* Add horizontal padding */
font-size: 50px;
color: #FFFFFF;
}

@media  (max-width: 480px) and (min-width: 361px) {
  #quotes p {

font-size: 14px;
}


#quotes h3 {
margin-top: 35px;
font-size: 25px;
}

#quotes {
  height: 120px;
}

}


/* Media Query untuk Tablet */
@media (max-width: 768px) and (min-width: 481px) {
  #quotes p {

font-size: 16px;
}

#quotes h3 {
margin-top: 35px;
font-size: 30px;

}

#quotes {
  height: 120px;
}

}

.statistics-section {
background-color: #000000; /* Yellow background color from your image */
padding: 20px 0; /* Padding to match your design */
display: flex;
justify-content: center; /* Center the stats horizontally */
}

.containerik {
display: flex;
justify-content: space-around; /* Distribute the stats evenly */
align-items: center; /* Vertically center the content */
}

.stat {
  margin-left: 50px;
text-align: center; /* Center the text for each statistic */
}

.number {
font-size: 3em; /* Larger font size for the number */
font-weight: bold; /* Bold font for the number */
color: #ffffff; /* Dark text color for contrast */
}

.label {
font-size: 16px; /* Smaller font size for the label */
text-transform: uppercase; /* Uppercase for the label */
color: #ffffff; /* Dark text color for contrast */
margin-top: 0.5em; /* Space between the number and the label */
}



/* Media query untuk tablet */
@media (max-width: 768px) {
    .number {
        font-size: 2.5em; /* Slightly smaller number size */
    }

    .label {
        font-size: 0.9em; /* Slightly smaller label size */
    }

    .stat {
        margin-left: 30px; /* Adjust left margin */
    }
    .power-container {
        height: 5vh;
    }
}

/* Media query untuk mobile phones */
@media (max-width: 765px) {
    .number {
        font-size: 1em; /* Further reduce number size */
    }

    .label {
        font-size: 0.5em; /* Further reduce label size */
    }

    .stat {
        margin-left: 10px; /* Adjust left margin for smaller screens */
    }
}





.banner-section-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #868686; /* Adjust the background color to match the image */
}

.banner-content-about {
  display: flex;
  align-items: center;
  width: 100%
}

.banner-text-content-about {
  flex: 1;
  margin-left: 80px;
  padding-right: 350px; /* Add padding to separate text from the image */
}

.banner-text-content-about h1 {
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
color: #000000;

font-size: 60px; /* Set the font size to 72px */
}

.banner-text-content-about p {
  font-family: 'Poppins', sans-serif; /* Use the Oswald font family */
font-size: 16px; /* Set the font size to 72px */
font-weight: bold;
color: #000000;
}


.top-notch-highlight {
  background-color: #FFC107; /* Highlight color for 'TOP-NOTCH' */
  color: #000000;
}

.banner-image-wrapper-about {
  flex: 0 0 40%; /* Adjust the size of the image container */
}

.banner-image-about {
  width: 100%; /* Ensure the image uses all the available space */
  height: auto;
}

.primary-button-about {
  background-color: #FFC107; /* A bright yellow color */
color: #000000; /* Black color for the text */
border: none;
font-weight: bold;
border-radius: 20px; /* Rounded corners */
padding: 10px 20px;
font-weight: bold; /* Make the text bold */
cursor: pointer;
transition: box-shadow 0.3s;
text-decoration: none; /* Remove underline from text */
display: inline-block; /* To apply padding and border correctly */
box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Slight shadow for depth */
}


.primary-button-about:hover {
box-shadow: 0 4px 8px rgb(250, 250, 250); /* More pronounced shadow on hover */
background-color: #000000; /* A bright yellow color */
color: #ffffff; /* Black color for the text */
}

.arrow-icon {
  margin-left: 10px; /* Space before the arrow icon */
}



@media (max-width: 1280px) {

  .core-values {
  margin-top: 130px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}


  .banner-section-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #868686; /* Adjust the background color to match the image */
}

.banner-content-about {
  display: flex;
  align-items: center;
  width: 100%
}

.banner-text-content-about {
  flex: 1;
  margin-left: 70px;
  padding-right: 200px; /* Add padding to separate text from the image */
}


  .banner-text-content-about h1 {
    font-size: 70px;
    margin-bottom: 2px;
  }

  .banner-text-content-about p {
    font-size: 14px;
  }

  .primary-button-about {
    font-size: 14px;
    padding: 8px 16px;
  }

  .banner-image-wrapper-about {
    width: 100%;
  }

  .banner-image-wrapper-about {
  flex: 0 0 40%; /* Adjust the size of the image container */
}

.banner-image-about {
  width: 100%; /* Ensure the image uses all the available space */
  height: auto;
}
}

@media (max-width: 1920px) and (min-width: 1751px){
  .banner-text-content-about h1 {
    font-size: 80px;
  }

  .banner-text-content-about p {
    font-size: 20px;
  }
  .primary-button-about {
    font-size: 14px;
    padding: 12px 40px;
  }
}


@media (max-width: 1750px) and (min-width: 1544px){
  .banner-text-content-about h1 {
    font-size: 75px;
    margin-bottom: 2px;
  }
}

@media (max-width: 1330px) and (min-width: 1281px){
  .banner-text-content-about h1 {
    font-size: 47px;
    margin-bottom: 2px;
  }
}

@media (max-width: 1100px) and (min-width: 992px){
  .banner-text-content-about h1 {
    font-size: 47px;
    margin-bottom: 2px;
  }
}

@media (max-width: 850px) and (min-width: 761px){
  .banner-text-content-about h1 {
    font-size: 30px;
    margin-bottom: 2px;
  }

  .banner-text-content-about {
  flex: 1;
  margin-left: 70px;
  padding-right: 200px; /* Add padding to separate text from the image */
  margin-bottom: 10px;
}



}

@media (max-width: 991px) {
  .banner-section-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #868686; /* Adjust the background color to match the image */
}

.banner-content-about {
  display: flex;
  align-items: center;
  width: 100%
}

.banner-text-content-about {
  flex: 1;
  margin-left: 70px;
  padding-right: 200px; /* Add padding to separate text from the image */
}


  .banner-text-content-about h1 {
    font-size: 31px;
    margin-bottom: 2px;
  }

  .banner-text-content-about p {
    font-size: 14px;
  }

  .primary-button-about {
    font-size: 14px;
    padding: 8px 16px;
  }

  .banner-image-wrapper-about {
    width: 100%;
  }

  .banner-image-wrapper-about {
  flex: 0 0 40%; /* Adjust the size of the image container */
}

.banner-image-about {
  width: 100%; /* Ensure the image uses all the available space */
  height: auto;
}
}

@media (max-width: 767px) and (min-width: 700px) {
 
  .banner-section-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #868686; /* Adjust the background color to match the image */
}

.banner-content-about {
  display: flex;
  align-items: center;
  width: 100%
}

.banner-text-content-about {
  flex: 1;
  margin-left: 70px;
  padding-right: 10px; /* Add padding to separate text from the image */
}
  .banner-text-content-about h1 {
    font-size: 28px;
  }

  .banner-text-content-about p {
    font-size: 12px;
  }

  .primary-button-about {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 699px) {

  .custom-apbc-team-single {
    display: flex;
  justify-content: center;
  align-items: flex-start; /* Ubah ke flex-start untuk memulai dari atas */
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 95%;
    
}

/* Menambahkan scrollbar yang lebih halus */
.custom-apbc-team-single::-webkit-scrollbar {
    height: 8px;
}

.custom-apbc-team-single::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.custom-apbc-team-single::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

  .core-values {
        margin-top: 150px;
        text-align: center;
        padding: 50px;
        background-color: #f5f5f5;
    }
 
 .banner-section-about {
 display: flex;
 align-items: center;
 justify-content: space-between;
 background-color: #868686; /* Adjust the background color to match the image */
}

.banner-content-about {
 display: flex;
 align-items: center;
 width: 100%
}

.banner-text-content-about {
 flex: 1;
 margin-left: 70px;
 padding-right: 50px; /* Add padding to separate text from the image */
}
 .banner-text-content-about h1 {
   font-size: 20px;
 }

 .banner-text-content-about p {
   font-size: 12px;
 }

 .primary-button-about {
   font-size: 12px;
   padding: 6px 12px;
 }
}

@media (max-width: 480px) {

  .custom-apbc-team-single {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }
 
    .banner-image-wrapper-about {
        width: 100%; /* Sesuaikan lebar gambar untuk tampilan mobile */
        margin-bottom: 25px;
    }

    .banner-content-about {
      margin-top: 25px;
    }

    .banner-text-content-about {
      width: 100%;
        margin-right: -111px;
        margin-left: 35px;
        padding-right: 85px;
    }

    .primary-button-about {
      width: auto;
        padding: 3px 5px;
        font-size: 8px;
        margin-bottom: 25px;
    }
    

    .banner-image-about {
      width: 100%; /* Ensure the image uses all the available space */
  height: 145px;
    }
    

    .banner-text-content-about h1 {
    font-size: 16px; /* Ukuran font yang lebih kecil untuk tablet */
  }

  .banner-text-content-about p {
    font-size: 6px;
  }

  .banner-section-about {
    height: 145px;
  }
}


.timeline {
margin: 100px 0;
list-style-type: none;
display: flex;
padding: 0;
text-align: center;
}
.timeline li {
transition: all 200ms ease-in;
}
.timestamp {
width: 100%; 
margin-bottom: 20px;
padding: 0px 40px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-weight: 100; 
}
.status {
padding: 0px 40px;
display: flex;
justify-content: center;
border-top: 4px solid #4A4A4A;
position: relative;
transition: all 200ms ease-in ;
}

.font-weight-bold {
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 45px; /* Set the font size to 72px */
color: #ffffff;
margin-left: 100px;
}

.status span {
font-weight: 600;
padding-top: 20px;
}
.status span:before {
content: '';
width: 25px;
height: 25px;
background-color: #000000;
border-radius: 25px;
border: 4px solid #DFA419;
position: absolute;
top: -15px;
left: calc(50% - 12px); 
transition: all 200ms ease-in;
}

.swiper-control {
text-align: right;
}

.timeline-slide {
  width: 100%; /* Allows each slide to grow as needed based on content */
  flex-shrink: 0; /* Prevents the slide from shrinking if there's not enough space */
  justify-content: center;
  align-items: center;
}

.swiper-container {
width: 100%;
height: 150px;
margin: 15px 0;
padding: 0 20px 30px 20px;
}
.story-swiper-slide {
width: 200px;
text-align: center;
font-size: 18px;
}
.story-swiper-slide:nth-child(2n) {
width: 40%;
}
.story-swiper-slide:nth-child(3n) {
width: 20%;
}



.date {
  color: #ffffff;
  font-family: 'Poppins', sans-serif; 
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
  margin: 0 20%;
}


.timestamptitle {
  color: #FFCA2E;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 24px;
}


.power-container {
    position: relative;
    width: 100%;
    height: 30vh;
    background-color: black; /* Mengganti background dengan warna hitam */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



/* Responsive adjustments */
@media (min-width: 768px) and (max-width: 991px) { /* For tablets */

    .containerkk h2 {
        font-size: 20px;
        left: -2%;
    }

    .gold-bar-vertikal {
      top: 31px;
    left: 5%;
    
}

.font-weight-bold {
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 45px; /* Set the font size to 72px */
color: #ffffff;
margin-left: 70px;
}


    .swiper-container {
        --swiper-navigation-size: 16px; /* Smaller navigation buttons */
    }

    .date {
  color: #ffffff;
  font-family: 'Poppins', sans-serif; 
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
  margin: 0 20%;
}


.timestamptitle {
  color: #FFCA2E;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.stat {
  margin-left: 20px;
text-align: center; /* Center the text for each statistic */
}



}

@media (max-width: 767px) { /* For mobile phones */
    .gold-bar-vertikal {
      top: 30px;
        left: 6%;
    
}

    .power-container {
      height: 5vh;
    }

    .power-gambar {
        margin-top: 50px; /* Remove negative margin */
    }

    .logo {
        width: 90%; /* Increase width to use more space */
    }

    .date {
      font-size: 9px;
      margin: 0px 15%;
}

.status {
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    border-top: 4px solid #4A4A4A;
    position: relative;
    transition: all 200ms ease-in;
}


.timestamptitle {

  font-size: 20px;
}

}


.power-gambar {
    width: 100%;
    display: flex;
    justify-content: center; /* Memusatkan gambar dalam footer */
    margin-top: -50px; /* Dorong ke atas jika perlu */
}

.logo {
    width: 80%; /* Sesuaikan lebar gambar */
    height: auto;
    margin-top: 30px;
}

.text-overlay {
    position: absolute;
    top: 10px;
    left: 175px;
    background-color: rgb(0, 0, 0); /* Menambahkan sedikit transparansi */
    padding: 10px 20px;
    width: 35%; /* Menetapkan lebar maksimum sebesar 50% dari elemen induk */
    z-index: 10;
   
}

@media (max-width: 1750px) and (min-width: 1441px) {
   
.logo {
    width: 80%; /* Sesuaikan lebar gambar */
    height: auto;
    margin-top: 30px;
}

.core-values {
  margin-top: 220px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}

.containerkk h2 {
margin-top: 30px; /* Add margin to the top of the "OUR STORY" title */
 font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 30px; /* Set the font size to 72px */
position: relative; /* Position relative to allow absolute positioning of child elements */
  display: inline-block; /* Ensures the container fits the content's width */
  text-align: center; /* Center the text */
  margin-left: 140px;
}

.text-overlay {
    position: absolute;
    top: 10px;
    left: 170px;
    background-color: rgb(0, 0, 0); /* Menambahkan sedikit transparansi */
    padding: 10px 20px;
    width: 35%; /* Menetapkan lebar maksimum sebesar 50% dari elemen induk */
    z-index: 10;
   
}

}

@media (min-width: 1920px) {
  .core-values {
  margin-top: 300px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}

.containerkk h2 {
margin-top: 30px; /* Add margin to the top of the "OUR STORY" title */
 font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 30px; /* Set the font size to 72px */
position: relative; /* Position relative to allow absolute positioning of child elements */
  display: inline-block; /* Ensures the container fits the content's width */
  text-align: center; /* Center the text */
  margin-left: 140px;
}

.value .overlay-text-title,
.value .overlay-text-title-value {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  
}

}

@media (min-width: 1770){
  .value .overlay-text-title,
.value .overlay-text-title-value {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  
}
}

@media (max-width: 1440px) and (min-width: 1226px) {
    .text-overlay {
      position: absolute;
    top: 10px;
    left: 50px;
    background-color: rgb(0, 0, 0); /* Menambahkan sedikit transparansi */
    padding: 10px 20px;
    width: 35%; /* Menetapkan lebar maksimum sebesar 50% dari elemen induk */
    z-index: 10;
}

.core-values {
  margin-top: 250px;
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}


.logo {
    width: 90%;
    height: auto;
    margin-top: 50px;
}

}

@media (max-width: 1225px) and (min-width: 992px) {
    .text-overlay {
      position: absolute;
    top: 10px;
    left: 50px;
    background-color: rgb(0, 0, 0); /* Menambahkan sedikit transparansi */
    padding: 10px 20px;
    width: 35%; /* Menetapkan lebar maksimum sebesar 50% dari elemen induk */
    z-index: 10;
}

.logo {
    width: 90%;
    height: auto;
    margin-top: 50px;
}

}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .text-overlay {
        width: 80%;
        padding: 15px;
        left: 61px;
        top: 50px;
        background-color: rgb(0 0 0 / 82%);
    }
.logo {
    width: 100%; /* Sesuaikan lebar gambar */
    height: auto;
    margin-top: 30px;
}
.power-gambar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -20px;
}
}
/* Styles for mobile phones */
@media (max-width: 767px) {
    .text-overlay {
      width: 90%;
        padding: 5px;
        left: 20px;
    }

    .logo {
    width: 100%; /* Sesuaikan lebar gambar */
    height: auto;
}


    .text-overlay {
        background-color: rgb(0 0 0 / 82%);
   
}


}
.text-overlay p {
  text-align: justify; /* Menambahkan rata kanan-kiri untuk teks */
  font-family: 'Poppins', sans-serif; /* Use the Oswald font family */
  color: white;
  font-size: 12px;
}


/* Styles for tablets */
@media (min-width: 768px) and (max-width: 991px) {
 
    .text-overlay p {
      font-size: 9px;
}
}

/* Styles for mobile phones */
@media (max-width: 767px) {

    .text-overlay p {
      font-size: 9px;
}

}

@media (max-width: 360px) {

  .text-overlay {
    position: absolute;
    top: -15px;
    left: 8px;
    background-color: rgb(0 0 0 / 82%);
    padding: 10px 20px;
    width: 90%; /* Menetapkan lebar maksimum sebesar 50% dari elemen induk */
    z-index: 10;
}
#quotes p {

font-size: 14px;
}

#quotes h3 {
margin-top: 35px;
font-size: 20px;

}

.gold-bar-vertikal {
    top: 30px;
    left: 30%;
}
  
}


.title-facility {
  position: relative;
  text-align: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.title-facility h2 {
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 30px;
  color: #000000;
}

.title-facility p {
  text-align: center;
  margin: 0 200px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #000000;
}


.title-facility::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  top: 40px;
  width: 10%; /* adjust the width of the gold line */
  height: 5px; /* adjust the height of the gold line */
  background-color: gold;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Angka yang tinggi untuk memastikan muncul di atas semua elemen lain */
  display: flex;
  align-items: center; /* Menengahkan modal secara vertikal */
  justify-content: center; /* Menengahkan modal secara horizontal */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* Atau lebar spesifik */
  max-width: 300px; /* Sesuaikan dengan konten Anda */
  z-index: 1001;
}

.modal-content-fasilitas {
  background-color: #fefefe;
  margin: auto;
  width: 650px; /* Atau lebar spesifik */
  height: auto;
  border-radius: 8%;
  z-index: 1001;
}

.modal-fasilitas-image {
    width: 100%; /* Menyesuaikan lebar gambar dengan container */
    height: 180px;
    border-radius: 8%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
    margin-bottom: 15px;
}


.fasiliasdesc p {
  font-family: 'Poppins', sans-serif; /* Font family untuk konsistensi */
  font-size: 12px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
  padding: 0px 40px; /* Increased padding on the sides for a wider margin */
  margin-bottom: 20px;
}


.titlefasilitas h3 {
  font-weight: bold;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-size: 30px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
}

/* Media Query for Tablets */
@media (max-width: 991px) {
  .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* Atau lebar spesifik */
  max-width: 300px; /* Sesuaikan dengan konten Anda */
  z-index: 1001;
}

.modal-content-fasilitas {
  background-color: #fefefe;
  margin: auto;
  width: auto; /* Atau lebar spesifik */
  border-radius: 5%;
  max-width: 600px; /* Sesuaikan dengan konten Anda */
  z-index: 1001;
}

.modal-fasilitas-image {
    width: 100%; /* Menyesuaikan lebar gambar dengan container */
    height: 180px;
    border-radius: 5%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
    margin-bottom: 15px;
}


.fasiliasdesc p {
  font-family: 'Poppins', sans-serif; /* Font family untuk konsistensi */
  font-size: 12px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
  padding: 0px 40px; /* Increased padding on the sides for a wider margin */
  margin-bottom: 20px;
}


.titlefasilitas h3 {
  font-weight: bold;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-size: 30px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
}
}

/* Media Query for Mobile Phones */
@media (max-width: 767px) {


  .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 300px; /* Atau lebar spesifik */

  z-index: 1001;
}

.modal-content-fasilitas {
  background-color: #fefefe;
  margin: auto;
  width: auto; /* Atau lebar spesifik */
  border-radius: 10%;
  max-width: 300px; /* Sesuaikan dengan konten Anda */
  height: auto;
  z-index: 1001;
}

.modal-fasilitas-image {
    width: 100%; /* Menyesuaikan lebar gambar dengan container */
    height: 100px;
    border-radius: 5%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
    margin-bottom: 15px;
}


.fasiliasdesc p {
  font-family: 'Poppins', sans-serif; /* Font family untuk konsistensi */
  font-size: 8px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
  padding: 0px 40px; /* Increased padding on the sides for a wider margin */
  margin-bottom: 20px;
}


.titlefasilitas h3 {
  font-weight: bold;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-size: 20px; /* Ukuran font */
  text-align: center;
  color: #000000; /* Warna teks */
}

}



.about-kreatif-carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}


.about-kreatif-section {
    text-align: center;
    margin: 30px 0; /* Adjusted margin for better spacing */
    margin-bottom: 100px;
    position: relative;
    padding: 0 60px; /* Padding to prevent edge items from touching container edges */
}

.about-kreatif-item {
    width: 300px; /* Lebar tetap untuk konsistensi */
    height: 250px; /* Tinggi tetap untuk konsistensi */
    border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    display: flex;
    padding: 0 10px; 
    margin-bottom: 80px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    background-color: #f8f9fa; /* Warna lebih terang atau sesuai dengan gambar */
}

.about-product-image {
    width: 100%; /* Menyesuaikan lebar gambar dengan container */
    border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
}

.about-kreatif-content h3
{
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #333; /* Warna teks gelap untuk kontras yang lebih baik */
}

.about-kreatif-content h4 {
  margin-top: 20px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #000000; /* Warna teks gelap untuk kontras yang lebih baik */

}

.about-read-more-button {
    background-color: #FFCA2E; /* Warna tombol biru */
    color: rgb(0, 0, 0);
    border-radius: 20px; /* Tombol dengan sudut yang lebih bulat */
    padding: 8px 10px;
    width: 100%;
    font-size: 12px; /* Ukuran font lebih besar untuk keterbacaan */
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.about-read-more-button:hover {
    background-color: #000000; /* Warna lebih gelap saat hover */
    color: white;
}

/* Swiper Container styling */
.swiper-container {
    width: 100%; /* Memastikan swiper container mengambil full width */
    padding: 20px 0; /* Memberi padding atas bawah */
}

.swiper-button-next, .swiper-button-prev {
  background-color: #000; /* Solid color for visibility */
  opacity: 0.8; /* Slightly transparent */
  border-radius: 50%;
  width: 30px; /* Smaller circle for subtlety */
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 16px; /* Appropriate icon size */
}

.swiper-button-next {
  right: 10px; /* Proper alignment */
}

.swiper-button-prev {
  left: 10px;
}

@media (max-width: 991px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

}

@media (max-width: 992px) {
  .about-kreatif-carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}

.title-facility p {
    text-align: center;
    margin: 0 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #000000;
}

.about-kreatif-section {
    text-align: center;
    margin: 30px 0; /* Adjusted margin for better spacing */
    margin-bottom: 100px;
    position: relative;
    padding: 0 60px; /* Padding to prevent edge items from touching container edges */
}

.about-kreatif-item {
  width: 220px;
    height: 200px;
    border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    display: flex;
    padding: 0 10px; 
    margin-bottom: 80px;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    background-color: #f8f9fa; /* Warna lebih terang atau sesuai dengan gambar */
}

.about-product-image {
    width: 100%; /* Menyesuaikan lebar gambar dengan container */
    border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
    object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
}

.about-kreatif-content h3
{
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #333; /* Warna teks gelap untuk kontras yang lebih baik */
}

.about-kreatif-content h4 {
  margin-top: 20px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #000000; /* Warna teks gelap untuk kontras yang lebih baik */

}

.about-read-more-button {
    background-color: #FFCA2E; /* Warna tombol biru */
    color: rgb(0, 0, 0);
    border-radius: 20px; /* Tombol dengan sudut yang lebih bulat */
    padding: 8px 10px;
    width: 100%;
    font-size: 12px; /* Ukuran font lebih besar untuk keterbacaan */
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.about-read-more-button:hover {
    background-color: #000000; /* Warna lebih gelap saat hover */
    color: white;
}


}

/* Styles for tablets */
@media (max-width: 768px) and (min-width: 700px) {
  .about-kreatif-section {
    padding: 0 50px; /* Reduced padding for tablet devices */
  }

  .about-kreatif-item {
    width: 180px;
    height: 190px;
    margin: 15px 0;
  }

  .about-kreatif-carousel {
    gap: 15px; /* Reduced gap between items */
  }

  .about-read-more-button {
    padding: 6px 8px; /* Slightly smaller padding for buttons */
    font-size: 11px; /* Smaller font size for better fit */
  }

  .title-facility p {
  text-align: center;
  margin: 25px 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 9px;
  color: #000000;
}


.title-facility::after {

  left: 50%;
  top: 40px;
  width: 25%; /* adjust the width of the gold line */
  height: 5px; /* adjust the height of the gold line */
}

}

@media (max-width: 699px) and (min-width: 481px) {
  .about-kreatif-section {
    padding: 0 50px; /* Reduced padding for tablet devices */
  }

  .about-kreatif-item {
    width: 150px;
    height: 150px;
  }

  .about-kreatif-item {
    width: 154px;
    height: 154px;
        border-radius: 15px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
        display: flex;
        padding: 0 10px;
        margin-bottom: 80px;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        background-color: #f8f9fa;
    }
  .about-kreatif-content h4 {
        margin-top: 20px;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: #000000;
    }

  .about-kreatif-carousel {
    gap: 15px; /* Reduced gap between items */
  }

  .about-read-more-button {
    padding: 6px 8px; /* Slightly smaller padding for buttons */
    font-size: 11px; /* Smaller font size for better fit */
  }

  .title-facility p {
  text-align: center;
  margin: 25px 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 9px;
  color: #000000;
}


.title-facility::after {

  left: 50%;
  top: 40px;
  width: 25%; /* adjust the width of the gold line */
  height: 5px; /* adjust the height of the gold line */
}

}

/* Styles for mobile phones */
@media (max-width: 480px) {
  .about-kreatif-section h2 {
    text-align: center; /* Center align title for better visibility on small screens */
    margin-left: 0; /* Remove left margin */
    font-size: 24px; /* Smaller font size for small screens */
  }

  .about-kreatif-section {
    padding: 0 5px; /* Reduced padding for mobile screens */
    margin: 20px 0; /* Adjust margin for better spacing */
  }


  .title-facility p {
    text-align: center;
    margin: 0 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}

  .swiper {
    margin-left: -10px;
    margin-right: -18px;
  }

  .about-kreatif-item {
    width: 110px;
    height: 125px;
        padding: 0 5px;
        margin-bottom: 15px;
  }

  .about-product-image {
    height: 75px;
  }

  .about-kreatif-content h3, .about-kreatif-content h4 {
    font-size: 12px;/* Smaller font size for headings */
  }

  .about-read-more-button {
    padding: 5px 5px;
        font-size: 10px;
        width: auto;
  }


  .font-weight-bold {
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 45px; /* Set the font size to 72px */
color: #ffffff;
margin-left: 80px;
}
}

.breadcurmb-link a {
  position: relative;
  font-family: 'Poppins', sans-serif; /* Use the Oswald font family */
  font-size: 12px; /* Set the font size to 72px */
  color: #FFCA2E;
}

.custom-apbc-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto; /* Adjust height as needed */
}

.custom-apbc-img-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.custom-apbc-img-wrapper .image-description,
.custom-apbc-img-wrapper .nomer {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  width: 100%; /* Ensure full width for centering */
}

.custom-apbc-img-wrapper .image-description {
  margin-top: 5px; /* Add some space between image and description */
  margin-bottom: 2px;
  margin-left: 72px
}

.custom-apbc-img-wrapper .nomer {
  margin-top: 2px;
  margin-left: 72px
}


/* Media query for mobile devices */
@media (max-width: 767px) {

  .custom-apbc-img-wrapper .image-description {
    margin-top: 5px;
    margin-bottom: 2px;
    margin-left: 10px;
}

  .custom-apbc-img-wrapper .nomer {
    margin-top: 2px;
    margin-left: 10px;
}
  .custom-apbc-img-wrapper .image-description,
  .custom-apbc-img-wrapper .nomer {
    font-size: 12px; /* Slightly smaller font size for mobile */
  }

  .custom-apbc-img-wrapper .nomer {
    hyphens: auto; /* Enable hyphenation for long numbers */
    overflow-wrap: break-word;
    word-break: break-all; /* Allow breaking at any character for very long numbers */
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .custom-apbc-img-wrapper {
    width: 100%; /* Full width for single column on very small screens */
    margin: 10px 0;
  }

}
</style>