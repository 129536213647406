<template>
  <HeaderCom />
  <section class="saasio-breadcurmb-section-sample">
        <div class="container">
            <div class="breadcurmb-title-sample">
            </div>
        </div>
    </section>

    <div class="breadcurmb-title-sample">
              <h2>{{ $t('Sampel.subtitle') }}</h2>
                <p>{{ $t('Sampel.texttitle') }}</p>
            </div>
    <section id="timeline">
  <article>
    <div class="inner">
      <span class="date">
        <span class="month">1</span>
      </span>
      <p>{{ $t('Sampel.timeline1') }}</p>
    </div>
  </article>
  <article>
    <div class="inner">
      <span class="date">
        <span class="month">2</span>
      </span>
      <p>{{ $t('Sampel.timeline2') }}</p>
    </div>
  </article>
  <article>
    <div class="inner">
      <span class="date">
        <span class="month">3</span>
      </span>
      <p>{{ $t('Sampel.timeline3') }}</p>
    </div>
  </article>
  <article>
    <div class="inner">
      <span class="date">
        <span class="month">4</span>
      </span>
      <p>{{ $t('Sampel.timeline4') }}</p>
    </div>
  </article>
  <article>
    <div class="inner">
      <span class="date">
        <span class="month">5</span>
      </span>
      <p>{{ $t('Sampel.timeline5') }}</p>
    </div>
  </article>
</section>

          <section id="sa-contact-inner" class="sa-contact-inner-section inner-page-padding">
                <div class="container">
                    <div class="saas_two_section_title saas2-headline text-left">
                        <h1>{{ $t('Sampel.title') }}</h1>
                    </div>
                    <div class="sa-contact-inner-form">  
                      <form @submit.prevent="submitCtpForm">
                               <div class="row">
                                <div class="col-md-6">
                                  <label for="">Name*</label>
                                  <input type="text" v-model="ctpFormData.nama" placeholder="Enter your name">
                              </div>
                              <div class="col-md-6">
                                <label for="">Email Address*</label>
                                <input type="email" v-model="ctpFormData.email" placeholder="Enter your email address">
                              </div>
                              <div class="col-md-6">
                                  <label for="search">Country*</label>
                                  <input
                                    type="text"
                                    id="search"
                                    v-model="searchTerm"
                                    :placeholder="ctpFormData.negara || 'Select your country'"
                                    @input="searchTerm = $event.target.value"
                                    class="country-input"
                                  />
                                  <ul v-if="searchCountries.length" class="country-list">
                                    <li
                                      v-for="country in searchCountries"
                                      :key="country.name"
                                      :class="{ 'selected-country': country.name === ctpFormData.negara }"
                                      @click="selectCountry(country.name)"
                                    >
                                      {{ country.name }}
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-md-6">
                                  <label for="phone">Phone*</label>
                                  <input type="text" id="phone" v-model="ctpFormData.no_hp" placeholder="Nomer Telepon"
                                        @input="validatePhone" title="Enter your phone number">
                                </div>

                              <div class="col-md-6">
                                <label for="">Company*</label>
                                <input type="text" v-model="ctpFormData.company" placeholder="Enter your company">
                              </div>
                              <div class="col-md-6">
                                <label for="">Job Title*</label>
                                <select v-model="selectedJabatan" class="form-control" @change="handleJabatanChange">
                                  <option disabled selected value="">Select your job title</option>
                                  <option value="Founder/Owner">Founder/Owner</option>
                                  <option value="CEO/Director/Manager">CEO/Director/Manager</option>
                                  <option value="Purchasing/Procurement">Purchasing/Procurement</option>
                                  <option value="Quality Control">Quality Control</option>
                                  <option value="Product Development/R&D">Product Development/R&D</option>
                                  <option value="Sales/Marketing">Sales/Marketing</option>
                                  <option value="Supply Chain/Logistics">Supply Chain/Logistics</option>
                                  <option value="Other">Other</option>
                              </select>
                                  </div>
                                
                                  <div class="col-md-6">
                                    <input v-if="selectedJabatan === 'Other'"  type="text" v-model="customMaterial" placeholder="Enter your custom jon title" 
                                          class="form-control">
                                  </div>
                         
                                <div class="col-md-6">
                                <label for="alamat">Adreess (Sample Recipient)*</label>
                                <textarea id="alamat" v-model="ctpFormData.alamat" placeholder="Enter your adreess"></textarea>
                              </div>
                        </div>
                  
                        <div class="button-group">
                              <button type="button" 
                                      class="form-button" 
                                      :class="{'active': currentForm === 'cp'}" 
                                      @click="showForm('cp')">CIGARETTE PAPER
                              </button>
                              <button type="button" 
                                      class="form-button" 
                                      :class="{'active': currentForm === 'ctp'}" 
                                      @click="showForm('ctp')">TIPPING PAPER
                              </button>
                              <button type="button" 
                                      class="form-button" 
                                      :class="{'active': currentForm === 'etiket'}" 
                                      @click="showForm('etiket')">CIGARETTE PACK
                              </button>
                              <button type="button" 
                                      class="form-button" 
                                      :class="{'active': currentForm === 'fp'}" 
                                      @click="showForm('fp')">FILTER TIPS
                              </button>
                              <button type="button" 
                                      class="form-button" 
                                      :class="{'active': currentForm === 'pr'}" 
                                      @click="showForm('pr')">PRE-ROLLED CONES
                              </button>
                            </div>

                            <div class="saas_two_section_title saas2-headline text-left">
                        <h1>{{ $t('Sampel.specification') }}</h1>
                    </div>
                    <div v-if="currentForm === 'cp'" class="sa-contact-inner-form">
                      <div class="row">
                        <div class="col-md-6">
                              <label for="produk type">Product type*</label>
                              <select v-model="selectedTypecp" class="form-control" @change="handleTypecpChange">
                                <option value="" disabled selected>Select product type</option>
                                <option value="Coloured">Coloured</option>
                                <option value="Double Wrapper">Double Wrapper</option>
                                <option value="Premium">Premium</option>
                                <option value="LIP">LIP</option>
                                <option value="Printed Pattern">Printed Pattern</option>
                                <option value="Printed Full Colour">Printed Full Colour</option>
                                <option value="Metallic Ink">Metallic Ink</option>

                              </select>
                            </div>
                            
                            <div class="col-md-6">
                              <label class="ctp_custom" for="Flavor">Product variant*</label>
                              <select :disabled="selectedTypecp == ''" v-model="selectedVariant" class="form-control">
                                <option value="" disabled selected>Select product variant</option>
                                <option v-for="(variant, index) in variants[selectedTypecp]" :key="index" :value="variant">{{ variant }}</option>
                              </select>
                            </div>


                                  <div class="col-md-6">
                                    <label  for="sweetener">Material*</label>
                                    <select v-model="selectedMaterial" class="form-control" @change="handleMaterialChange">
                                    <option value="" disabled selected>Select materials</option>
                                    <option v-for="material in materials" :key="material.id" :value="material.name">{{ material.name }}</option>
                                    <option value="Lainnya">Other</option>
                                  </select>
                                  </div>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Material">Custom material</label>
                                    <!-- Use the `disabled` attribute to control the enable/disable state -->
                                    <input type="text" v-model="customMaterial" placeholder="Enter your custom material" 
                                          class="form-control" :disabled="selectedMaterial !== 'Lainnya'">
                                  </div>
                                  
                                  <div class="col-md-6">
                                    <label  for="sweetener">Sweetener*</label>
                                    <select v-model="selectedSweetener" class="form-control" @change="handleSweetenerChange">
                                      <option value="" disabled selected>Select sweetener</option>
                                      <option value="Saccharine">Saccharine</option>
                                      <option value="Taline">Taline</option>
                                      <option value="Other">Other</option>
                                      <!-- Add more options as needed -->
                                    </select>
                                  </div>

                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Custom sweetener</label>
                                    <input :disabled="selectedSweetener !== 'Other'" type="text" v-model="customSweetener" placeholder="Enter your custom sweetener" class="form-control">
                                  </div>
                                  
                                  <div class="col-md-6">
                                    <label for="sweetener">Flavor*</label>
                                    <select v-model="selectedFlavor" class="form-control" @change="handleFlavorChange">
                                    <option value="" disabled selected>Select flavor</option>
                                    <option v-for="flavor in flavors" :key="flavor.id" :value="flavor.name">{{ flavor.name }}</option>
                                    <option value="Lainnya">Other</option>
                                  </select>
                                  </div>
                                
                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Custom flavor</label>
                                    <input :disabled="selectedFlavor !== 'Lainnya'" type="text" v-model="customFlavor" placeholder="Enter your custom flavor" class="form-control">
                                  </div>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Size">Size</label>
                                    <input type="text" v-model="ctpFormData.size" placeholder="Enter your custom size" class="form-control">
                                  </div>

                            <div class="col-md-12">
                                <label for="node">Note</label>
                                <textarea id="node" v-model="ctpFormData.node" placeholder="Add your note"></textarea>
                              </div>
                                  <div class="save-inner-btn text-right">
                                    <button type="submit"  class="savesample">{{ $t('Sampel.submit') }} >
                                    </button>
                              </div>
                             </div>
                        </div>
                    <div v-if="currentForm === 'ctp'" class="sa-contact-inner-form">
                      <div class="row">
                        <div class="col-md-6">
                                    <label  for="sweetener">Material*</label>
                                    <select v-model="selectedMaterial" class="form-control" @change="handleMaterialChange">
                                    <option value="" disabled selected>Select material</option>
                                    <option v-for="material in materials" :key="material.id" :value="material.name">{{ material.name }}</option>
                                    <option value="Lainnya">Other</option>
                                  </select>
                                  </div>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Material">Custom material</label>
                                    <!-- Use the `disabled` attribute to control the enable/disable state -->
                                    <input type="text" v-model="customMaterial" placeholder="Enter your custom material" 
                                          class="form-control" :disabled="selectedMaterial !== 'Lainnya'">
                                  </div>

                           <div class="col-md-6" style="position: relative;">
                            <label for="size">Size*</label>
                            <div class="custom-select-wrapper" style="position: relative;">
                              <select v-model="selectedSizes" class="form-control" id="size" style="display: block; width: 100%; height: 38px; padding-right: 50px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 1px solid #ccc; border-radius: 4px;">
                                <option value="" disabled selected>Select size</option>
                                <option value="Reguler: 60 MM">Reguler: 60 MM</option>
                                <option value="Mild: 50 MM">Mild: 50 MM</option>
                                <option value="Bold: 60 MM">Bold: 60 MM</option>
                                <option value="Super Slim: 70 MM">Super Slim: 70 MM</option>
                                <option value="Lainnya">Other</option>
                              </select>
                              
                              <!-- SVG with click event for opening modal -->
                              <svg @click="openModalCTP" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="position: absolute; right: 10px; top: 8px; cursor: pointer; pointer-events: all;">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm1 9h-2V7h2v8z"/>
                              </svg>
                            </div>
                          </div>

                              <transition name="fade">
                                <div v-if="showModalCTP" class="modal-overlay" @click.self="closeModalCTP" style="z-index: 1050;">
                                  <div class="modal-content" @click.stop>
                                    <span class="close-button" @click="closeModalCTP">&times;</span>
                                    <img src="assets/img/business-4/sampel/TIPPINGSIZE.png" alt="Active Image" style="max-width: 100%;">
                                  </div>
                                </div>
                              </transition>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Size">Size custom</label>
                                    <input  :disabled="selectedSizes !== 'Lainnya'" type="text" v-model="customSize" placeholder="Enter your custom size" class="form-control">
                                  </div>
                                  
                                  <div class="col-md-6">
                                    <label  for="sweetener">Sweetener*</label>
                                    <select v-model="selectedSweetener" class="form-control" @change="handleSweetenerChange">
                                      <option value="" disabled selected>Select sweetener</option>
                                      <option value="Saccharine">Saccharine</option>
                                      <option value="Taline">Taline</option>
                                      <option value="Other">Other</option>
                                      <!-- Add more options as needed -->
                                    </select>
                                  </div>

                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Custom sweetener</label>
                                    <input :disabled="selectedSweetener !== 'Other'" type="text" v-model="customSweetener" placeholder="Enter your custom sweetener" class="form-control">
                                  </div>
                                  
                                  <div class="col-md-6">
                                    <label for="sweetener">Flavor*</label>
                                    <select v-model="selectedFlavor" class="form-control" @change="handleFlavorChange">
                                    <option value="" disabled selected>Select flavor</option>
                                    <option v-for="flavor in flavors" :key="flavor.id" :value="flavor.name">{{ flavor.name }}</option>
                                    <option value="Lainnya">Other</option>
                                  </select>
                                  </div>
                                
                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Custom flavor</label>
                                    <input :disabled="selectedFlavor !== 'Lainnya'" type="text" v-model="customFlavor" placeholder="Enter your custom flavor" class="form-control">
                                  </div>

                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Perforation Row (1 row - 4 row)</label>
                                    <input type="text" v-model="ctpFormData.perforationrow" placeholder="Enter your perforation row" class="form-control">
                                  </div>

                                  <div class="col-md-6">
                                    <br>
                                    
                                  </div>

                                  <div class="col-md-6">
                                    <br>
                                    <label class="ctp_custom" for="Flavor">Perforation Coresta Unit (100 CU - 1600 CU)</label>
                                    <input type="text" v-model="ctpFormData.perforationcu" placeholder="Enter your perforation cu" class="form-control">
                                  </div>
                                  
                            <div class="col-md-12">
                                <label for="alamat">Note</label>
                                <textarea id="node" v-model="ctpFormData.node" placeholder="Add your note"></textarea>
                              </div>
                              <div class="save-inner-btn text-right">
                                    <button type="submit">{{ $t('Sampel.submit') }} >
                                    </button>
                              </div>
                             </div>
                        </div>
                        <div v-if="currentForm === 'etiket'" class="sa-contact-inner-form">
                    <div class="row">
                      <div class="col-md-6">
                              <label for="sweetener">Product type*</label>
                              <select v-model="selectedTypeetiket" class="form-control" @change="handleTypeetiketChange">
                                <option value="" disabled selected>Select product type</option>
                                <option value="Hard Pack">Hard Pack</option>
                                <option value="HLP">HLP</option>
                                <option value="Soft Pack">Soft Pack</option>
                                <option value="Display Outer">Display Outer</option>
                                <option value="Individual Pack">Individual Pack</option>
                                <option value="Inner Frame">Inner Frame</option>
                                <option value="Inner Wrapper">Inner Wrapper</option>
                              </select>
                            </div>

                            <div class="col-md-6">
                              <label class="ctp_custom" for="Flavor">Product variant*</label>
                              <select :disabled="selectedTypeetiket == ''" v-model="selectedVariantEtiket" class="form-control">
                                <option value="" disabled selected>Select product variant</option>
                                <option v-for="(variant, index) in variantsEtiket[selectedTypeetiket]" :key="index" :value="variant">{{ variant }}</option>
                              </select>
                            </div>
                            <!-- ...similar structure for other fields... -->
                            <div class="col-md-6">
                                    <label  for="sweetener">Material*</label>
                                    <select v-model="selectedMaterial" class="form-control" @change="handleMaterialChange">
                                    <option value="" disabled selected>Select material</option>
                                    <option v-for="material in materialsEtiket" :key="material.id" :value="material.name">{{ material.name }}</option>
                                    <option value="Lainnya">Other</option>
                                  </select>
                                  </div>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Material">Custom material</label>
                                    <!-- Use the `disabled` attribute to control the enable/disable state -->
                                    <input type="text" v-model="customMaterial" placeholder="Enter your custom material" 
                                          class="form-control" :disabled="selectedMaterial !== 'Lainnya'">
                                  </div>

                          <div class="col-md-6" style="position: relative;">
                            <label for="size">Size*</label>
                            <div class="custom-select-wrapper" style="position: relative;">
                              <select v-model="selectedSizes" class="form-control" id="size" style="display: block; width: 100%; height: 38px; padding-right: 50px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 1px solid #ccc; border-radius: 4px;">
                                <option value="" disabled selected>Select size</option>
                                <option value="Reguler: 60 MM">Reguler: 60 MM</option>
                                <option value="Mild: 50 MM">Mild: 50 MM</option>
                                <option value="Bold: 60 MM">Bold: 60 MM</option>
                                <option value="Super Slim: 70 MM">Super Slim: 70 MM</option>
                                <option value="Lainnya">Other</option>
                              </select>
                              
                              <!-- SVG with click event for opening modal -->
                              <svg @click="openModalCTP" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="position: absolute; right: 10px; top: 8px; cursor: pointer; pointer-events: all;">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm1 9h-2V7h2v8z"/>
                              </svg>
                            </div>
                          </div>
                          

                          <transition name="fade">
                            <div v-if="showModalCTP" class="modal-overlay" @click.self="closeModalCTP" style="z-index: 1050;">
                              <div class="modal-content" @click.stop>
                                <span class="close-button" @click="closeModalCTP">&times;</span>
                                <!-- Use the computed property for the image source -->
                                <img :src="modalImageSrc" alt="Active Image" style="max-width: 100%;">
                              </div>
                            </div>
                          </transition>

                            <div class="col-md-6">
                                    <label class="ctp_custom" for="Size">Size custom</label>
                                    <input  :disabled="selectedSizes !== 'Lainnya'" type="text" v-model="customSize" placeholder="Enter your custom size" class="form-control">
                                  </div>
                           
                                  <div class="col-md-6">
                              <label for="etiket-material">Finishing</label>
                              <select v-model="selectedFinishing" class="form-control" @change="handleFinishingChange">
                                <option value="" disabled selected>Select finishing</option>
                                <option value="UV Tactile">UV Tactile</option>
                                <option value="UV Spot">UV Spot</option>
                                <option value="Emboss / Deboss">Emboss / Deboss</option>
                                <option value="Microemboss">Microemboss</option>
                                <option value="Soft Touch">Soft Touch</option>
                                <option value="Rainbow Effect">Rainbow Effect</option>
                                <option value="HRI">HRI</option>
                                <option value="Hollo Trans Metallized">Hollo Trans Metallized</option>
                                <!-- Add more options as needed -->
                              </select>
                            </div>

                            <div class="col-md-12">
                                <label for="alamat">Node</label>
                                <textarea id="node" v-model="ctpFormData.node" placeholder="Add your note"></textarea>
                              </div>
                            <br>
                              <br>
                              <div class="save-inner-btn text-right">
                                    <button type="submit">{{ $t('Sampel.submit') }} >
                                    </button>
                              </div>
                        </div>
                        </div>
                        <div v-if="currentForm === 'fp'" class="sa-contact-inner-form">
                      <div class="row">
                        <div class="col-md-6">
                              <label for="sweetener">Product type*</label>
                              <select v-model="selectedTypefp" class="form-control" @change="handleTypefpChange">
                                <option value="" disabled selected>Select product type</option>
                                <option value="Tobacco Stopper">Tobacco Stopper</option>
                                <option value="Tobacco Stopper Rod">Tobacco Stopper Rod</option>
                                <option value="Tobacco Stopper Natural">Tobacco Stopper Natural</option>
                                <option value="Tobacco Stopper Natural Substrat">Tobacco Stopper Natural Substrat</option>
                                <option value="Tobacco Stopper Natural Premium">Tobacco Stopper Natural Premium</option>
                                <option value="Extended Cigarettes">Extended Cigarettes</option>
                              </select>
                            </div>

                            <div class="col-md-6">
                              <label class="ctp_custom" for="Flavor">Product variant*</label>
                              <select :disabled="selectedTypefp == ''" v-model="selectedVariantFp" class="form-control">
                                <option value="" disabled selected>Select product variant</option>
                                <option v-for="(variant, index) in variantsFp[selectedTypefp]" :key="index" :value="variant">{{ variant }}</option>
                              </select>
                            </div>   

                            <div class="col-md-6">
                                  <label for="length">Length*</label>
                                  <select v-model="selectedLength" class="form-control" @change="handleLengthChange">
                                    <option value="" disabled selected>Select length</option>
                                    <option value="100 mm">100 mm</option>
                                    <option value="120 mm">120 mm</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label class="ctp_custom" for="customLength">Custom length</label>
                                  <input :disabled="selectedLength !== 'Other'" type="text" v-model="customLength" placeholder="Enter your custom length" class="form-control">
                                </div>

                           <div class="col-md-6" style="position: relative;">
                            <label for="size">Diameter size*</label>
                            <div class="custom-select-wrapper" style="position: relative;">
                              <select v-model="selectedDiameter" class="form-control" style="display: block; width: 100%; height: 38px; padding-right: 50px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 1px solid #ccc; border-radius: 4px;">
                                <option value="" disabled selected>Select diameter size</option>
                                <option value="Regular (7,75 mm)">Regular (7,75 mm)</option>
                                <option value="Bold (8,10 mm)">Bold (8,10 mm)</option>
                                <option value="Mild (7,00 mm)">Mild (7,00 mm)</option>
                                <option value="Other">Other</option>
                              </select>
                              
                              <!-- SVG with click event for opening modal -->
                              <svg @click="openModalDiameter" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="position: absolute; right: 10px; top: 8px; cursor: pointer; pointer-events: all;">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm1 9h-2V7h2v8z"/>
                              </svg>
                            </div>
                          </div>

                              <transition name="fade">
                                <div v-if="showModalDiameter" class="modal-overlay" @click.self="closeModalDiameter" style="z-index: 1050;">
                                  <div class="modal-content" @click.stop>
                                    <span class="close-button" @click="closeModalDiameter">&times;</span>
                                    <img src="assets/img/business-4/sampel/FILTERSIZE.png" alt="Active Image" style="max-width: 100%;">
                                  </div>
                                </div>
                              </transition>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Size">Diameter custom</label>
                                    <input  :disabled="selectedDiameter !== 'Other'" type="text" v-model="customDiameter" placeholder="Enter your custom diameter cize" class="form-control">
                                  </div>
                                  
                                  <div class="col-md-6">
                                      <label for="finishingfp">Special Feature*</label>
                                      <select v-model="selectedFinishingfp" class="form-control" @change="handleFinishingfpChange">
                                        <option value="" disabled selected>Select special feature</option>
                                        <option value="Added-Flavor">Added-Flavor</option>
                                        <option value="Microcooling Agent">Microcooling Agent</option>
                                      </select>
                                    </div>
                                  
                            <div class="col-md-12">
                                <label for="alamat">Node</label>
                                <textarea id="node" v-model="ctpFormData.node" placeholder="Add your note"></textarea>
                              </div>
                              <div class="save-inner-btn text-right">
                                    <button type="submit">{{ $t('Sampel.submit') }} >
                                    </button>
                              </div>
                             </div>
                        </div>

                        <div v-if="currentForm === 'pr'" class="sa-contact-inner-form">
                      <div class="row">
                        <div class="col-md-6">
                              <label for="sweetener">Product type*</label>
                              <select v-model="selectedTypepr" class="form-control" @change="handleTypeprChange">
                                <option value="" disabled selected>Select product type</option>
                                <option value="Paper-based">Paper-based</option>
                                <option value="Natural-based">Natural-based</option>
                              </select>
                            </div>

                            <div class="col-md-6">
                              <label class="ctp_custom" for="Flavor">Product variant*</label>
                              <select :disabled="selectedTypepr == ''" v-model="selectedVariantPr" class="form-control">
                                <option value="" disabled selected>Select product variant</option>
                                <option v-for="(variant, index) in variantsPr[selectedTypepr]" :key="index" :value="variant">{{ variant }}</option>
                              </select>
                            </div>    

                          
                           <div class="col-md-6" style="position: relative;">
                            <label for="size">Diameter size*</label>
                            <div class="custom-select-wrapper" style="position: relative;">
                              <select v-model="selectedDiameterpr" class="form-control" style="display: block; width: 100%; height: 38px; padding-right: 50px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 1px solid #ccc; border-radius: 4px;">
                                <option value="" disabled selected>Select diameter size</option>
                                <option value="King Size">King Size</option>
                                <option value="Classic Size ">Classic Size</option>
                                <option value="Mild Size ">Mild Size</option>
                                <option value="Slim Size">Slim Size</option>
                                <option value="Mini Size">Mini Size</option>
                                <option value="Other">Custom Size</option>
                              </select>
                              
                              <!-- SVG with click event for opening modal -->
                              <svg @click="openModalDiameterpr" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="position: absolute; right: 10px; top: 8px; cursor: pointer; pointer-events: all;">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm1 9h-2V7h2v8z"/>
                              </svg>
                            </div>
                          </div>

                              <transition name="fade">
                                <div v-if="showModalDiameterpr" class="modal-overlay" @click.self="closeModalDiameterpr" style="z-index: 1050;">
                                  <div class="modal-content" @click.stop>
                                    <span class="close-button" @click="closeModalDiameterpr">&times;</span>
                                    <img src="assets/img/business-4/sampel/PREROLLEDSIZE.png" alt="Active Image" style="max-width: 100%;">
                                  </div>
                                </div>
                              </transition>

                                  <div class="col-md-6">
                                    <label class="ctp_custom" for="Size">Diameter custom</label>
                                    <input  :disabled="selectedDiameterpr !== 'Other'" type="text" v-model="customDiameterpr" placeholder="Enter your custom diameter size" class="form-control">
                                  </div>

                                  <div class="col-md-6">
                                  <label for="length">Filter tips*</label>
                                  <select v-model="selectedFilter" class="form-control" @change="handleFilterChange">
                                    <option value="" disabled selected>Select filter tips</option>
                                    <option value="Tobacco Stopper">Tobacco Stopper</option>
                                    <option value="Tobacco Stopper Natural">Tobacco Stopper Natural</option>
                                    <option value="Tobacco Stopper Natural Premium">Tobacco Stopper Natural Premium</option>
                                    <option value="Extended Cigarettes">Extended Cigarettes</option>
                                  </select>
                                </div>
                            
                                  
                                  <div class="col-md-6">
                                      <label for="finishingfp">Special feature*</label>
                                      <select v-model="selectedSpecial" class="form-control" @change="handleSpecialChange">
                                        <option value="" disabled selected>Select special feature</option>
                                        <option value="Tipping Paper">Tipping Paper</option>
                                        <option value="Flavour-Added">Flavour-Added</option>
                                      </select>
                                    </div>
                                  
                            <div class="col-md-12">
                                <label for="alamat">Node</label>
                                <textarea id="node" v-model="ctpFormData.node" placeholder="Add your note"></textarea>
                              </div>
                              <div class="save-inner-btn text-right">
                                    <button type="submit">{{ $t('Sampel.submit') }} >
                                    </button>
                              </div>
                             </div>
                        </div>

                    <div v-if="savedMessage.content" 
                          :class="['data-message', savedMessage.type === 'error' ? 'data-error-message' : 'data-success-message']">
                        {{ savedMessage.content }}
                      </div>
                    </form>
                </div>
            </div>

        </section>
     
  <FooterCom />
</template>

<script>
import { ref, watch } from 'vue';
import axios from 'axios'; 
import HeaderCom from '../components/HeaderCom';
import FooterCom from '../components/FooterCom';
import countries from '@/data/countries.json';

export default {
  components: {
    HeaderCom,
    FooterCom,
  },

  data() {
    return {
      defaultCountry: 'ID',
      searchTerm: '',
      countries: countries,
      formData: {
        selectedCountry: ''
      },
      variants: {
        'Coloured': ['Colour 1', 'Colour 2', 'Colour 3', 'Brown c1', 'Brown C2', 'Black'],
        'Double Wrapper': ['Upper-Side', 'Bottom-Side', 'Overlapping'],
        'Premium': ['HTL', 'Recon Tea', 'Recon Clove', 'Recon Sugar Palm Leaf', 'Recon Corn Husk'],
        'LIP': ['Variant 1', 'Variant 2'],
        'Printed Pattern': ['PRT01', 'PRT02', 'PRT03', 'PRT04', 'PRT04', 'PRT05', 'PRT06', 'PRT07', 'PRT08', 'PRT09', 'PRT10'],
        'Printed Full Colour': ['Print 1', 'Print 2', 'Print 3', 'Print 4', 'Print 5'],
        'Metallic Ink': ['Metallic Ink'],
      },
      variantsEtiket: {
      'Hard Pack': ['Flip-Top', 'Shell & Slide'],
      'HLP': ['Rectangular', 'Hexagonal', 'Round Corner', 'Invisible Ink'],
      'Soft Pack': ['Soft Pack'],
      'Display Outer': ['Display Outer'],
      'Individual Pack': ['Individual Pack'],
      'Inner Frame': ['Inner Frame'],
      'Inner Wrapper': ['Inner Wrapper'],
    },
    variantsFp: {
      'Tobacco Stopper': ['I Type', 'M Type', 'N Type'],
      'Tobacco Stopper Rod': ['M Type', 'I Type', 'O Type'],
      'Tobacco Stopper Natural': ['Corn Husks', 'Sugar Palm Leaf', 'Banana Leaf'],
      'Tobacco Stopper Natural Substrat': ['Corn Husks', 'Sugar Palm Leaf'],
      'Tobacco Stopper Natural Premium': ['Corn Husks', 'Sugar Palm Leaf', 'Banana Leaf'],
    },
    variantsPr: {
        'Paper-based': ['Cigarette Paper', 'HTL', 'Recon Tea', 'Recon Clove', 'Recon Sugar Palm Leaf', 'Recon Corn Hus'],
        'Natural-based': ['Corn Husks', 'Sugar Palm Leaf', 'Banana Leaf'],
      },
    };
  },

  computed: {
    searchCountries() {
      if (this.searchTerm === '') return [];
      return this.countries.filter(country =>
        country.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    modalImageSrc() {
      switch(this.selectedTypeetiket) {
        case 'HLP': return 'assets/img/business-4/sampel/hlp.png';
        case 'Hard Pack': return 'assets/img/business-4/sampel/HARDpack.png';
        case 'Soft Pack': return 'assets/img/business-4/sampel/softpack.png';
        case 'Individual Pack': return 'assets/img/business-4/sampel/INDIVIDUAL.png';
        case 'Display Outer': return 'assets/img/business-4/sampel/DO.png';
        case 'Inner Frame': return 'assets/img/business-4/sampel/innerframe.png';
        default: return ''; // default image if no type is selected
      }
    },
  },

  methods: {
    selectCountry(countryName) {
      this.formData.selectedCountry = countryName;
      this.ctpFormData.negara = countryName;
      this.searchTerm = '';
    },
    validatePhone(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9]/g, '');
      if (value.length > 0 && value[0] !== '0') {
        value = '0' + value.substr(1);
      }
      this.ctpFormData.no_hp = value;
    }
  },

  setup() {
    const currentForm = ref('cp');
    const savedMessage = ref({ content: null, type: null });
    const selectedFlavor = ref('');
    const customFlavor = ref('');
    const selectedSizes = ref('');
    const customSize = ref('');
    const selectedSweetener = ref('');
    const customSweetener = ref('');
    const selectedMaterial = ref('');
    const customMaterial = ref('');
    const selectedSize = ref([]);
    const selectedTypecp = ref('');
    const customTypecp = ref('');
    const selectedTypeetiket = ref('');
    const customTypeetiket = ref('');
    const selectedFinishing = ref('');
    const customFinishing = ref('');
    const selectedTypefp = ref('');
    const customTypefp = ref('');
    const selectedLength = ref('');
    const customLength = ref('');
    const selectedDiameter = ref('');
    const customDiameter = ref('');
    const selectedFinishingfp = ref('');
    const customFinishingfp = ref('');
    const selectedTypepr = ref('');
    const customTypepr = ref('');
    const selectedDiameterpr = ref('');
    const customDiameterpr = ref('');
    const selectedFilter = ref('');
    const customFilter = ref('');
    const selectedSpecial = ref('');
    const customSpecial = ref('');
    const selectedVariant = ref('');
    const selectedVariantEtiket = ref('');
    const selectedVariantFp = ref('');
    const selectedVariantPr = ref('');
    const selectedJabatan = ref(''); 
    const customJabatan = ref(''); 

    const initialCtpFormData = {
      nama: '',
      email: '',
      negara: '',
      no_hp: '',
      company: '',
      jabatan: '',
      customJabatan:'',
      alamat: '',
      node:'',
      typecp: '',
      customTypecp:'',
      material: '',
      size: '',
      length:'',
      diameter:'',
      customSize: '',
      customSweetener: '',
      customMaterial: '',
      sweetener: '',
      customFlavor: '',
      flavor:'',
      perforationrow: '',
      perforationcu:'',
      tanggal: new Date().toISOString().substr(0, 19),
    };

    const ctpFormData = ref({
      nama: '',
      email: '',
      negara: '',
      no_hp: '',
      company: '',
      jabatan: '',
      alamat: '',
      node:'',
      material: '',
      size: '',
      length:'',
      diameter:'',
      sweetener: '',
      flavor: '',
      perforationrow: '',
      perforationcu:'',
      tanggal: new Date().toISOString().substr(0, 19),
    });

 

    const flavors = ref([
      { id: 1, name: "Chocolate" },
      { id: 2, name: "Coffee" },
      { id: 3, name: "Tea" },
      { id: 4, name: "Mango" },
    ]);

    const materials = ref([
      { id: 1, name: "Base Paper 32 / 35 GSM" },
      { id: 2, name: "Cigarette Paper 26 / 28 GSM" },
    ]);

    const materialsEtiket = ref([
      { id: 1, name: "Ivory Board 230 GSM" },
      { id: 2, name: "Metalize Board 237 GSM"},
      { id: 3, name: "Alu Board 210 gsm" },
      { id: 4, name: "Alu Board 230 gsm" },

    ]);

    const handleJabatanChange = () => {
      if (selectedJabatan.value === 'Other') {
        ctpFormData.value.jabatan = customJabatan.value;
      } else {
        ctpFormData.value.jabatan = selectedJabatan.value;
      }
    };
    

    const handleFlavorChange = () => {
      if (selectedFlavor.value === 'Lainnya') {
        ctpFormData.value.flavor = customFlavor.value;
      } else {
        ctpFormData.value.flavor = selectedFlavor.value;
      }
    };

    const handleSizesChange = () => {
      if (selectedSizes.value === 'Lainnya') {
        ctpFormData.value.size = customSize.value;
      } else {
        ctpFormData.value.size = selectedSizes.value;
      }
    };

    const handleSweetenerChange = () => {
      if (selectedSweetener.value === 'Other') {
        ctpFormData.value.sweetener = customSweetener.value;
      } else {
        ctpFormData.value.sweetener = selectedSweetener.value;
      }
    };


    const handleFinishingChange = () => {
      if (selectedFinishing.value === 'Other') {
        ctpFormData.value.finishing = customFinishing.value;
      } else {
        ctpFormData.value.finishing = selectedFinishing.value;
      }
    };

    const handleMaterialChange = () => {
      if (selectedMaterial.value === 'Other') {
        ctpFormData.value.material = customMaterial.value;
      } else {
        ctpFormData.value.material = selectedMaterial.value;
      }
    };

    const handleTypecpChange = () => {
      if (selectedTypecp.value === 'Other') {
        ctpFormData.value.product_type = selectedVariant.value; 
      } else {
        ctpFormData.value.product_type = selectedTypecp.value;
      }
    };

    const handleTypeetiketChange = () => {
      if (selectedTypeetiket.value === 'Other') {
        ctpFormData.value.product_type = selectedVariantEtiket.value;
      } else {
        ctpFormData.value.product_type = selectedTypeetiket.value;
      }
    };

    const handleFinishingfpChange = () => {
      if (selectedFinishing.value === 'Other') {
        ctpFormData.value.special = customFinishingfp.value;
      } else {
        ctpFormData.value.special = selectedFinishingfp.value;
      }
    };

    const handleTypefpChange = () => {
      if (selectedTypefp.value === 'Other') {
        ctpFormData.value.product_type = selectedVariantFp.value;
      } else {
        ctpFormData.value.product_type = selectedTypefp.value;
      }
    };

    const handleLengthChange = () => {
      if (selectedLength.value === 'Other') {
        ctpFormData.value.length = customLength.value;
      } else {
        ctpFormData.value.length = selectedLength.value;
      }
    };

    const handleDiameterChange = () => {
      if (selectedDiameter.value === 'Other') {
        ctpFormData.value.diameter = customDiameter.value;
      } else {
        ctpFormData.value.diameter = selectedDiameter.value;
      }
    };

    const handleTypeprChange = () => {
      if (selectedTypepr.value === 'Other') {
        ctpFormData.value.product_type = selectedVariantPr.value;
      } else {
        ctpFormData.value.product_type = selectedTypepr.value;
      }
    };

    const handleDiameterprChange = () => {
      if (selectedDiameterpr.value === 'Other') {
        ctpFormData.value.diameter = customDiameterpr.value;
      } else {
        ctpFormData.value.diameter = selectedDiameterpr.value;
      }
    };

    const handleFilterChange = () => {
      if (selectedFilter.value === 'Other') {
        ctpFormData.value.filter = customFilter.value;
      } else {
        ctpFormData.value.filter = selectedFilter.value;
      }
    };

    const handleSpecialChange = () => {
      if (selectedSpecial.value === 'Other') {
        ctpFormData.value.special = customSpecial.value;
      } else {
        ctpFormData.value.special = selectedSpecial.value;
      }
    };

    const showModalCTP = ref(false);
    const activeSizeCTP = ref('');

    const openModalCTP = () => {
      showModalCTP.value = true;
      activeSizeCTP.value = 'assets/img/business-4/sampel/TIPPINGSIZE.png';
    };

    const closeModalCTP = () => {
      showModalCTP.value = false;
    };

    const showModalDiameter = ref(false);
    const activeDiameter = ref('');

    const openModalDiameter = () => {
      showModalDiameter.value = true;
      activeDiameter.value = 'assets/img/business-4/sampel/FILTERSIZE.png';
    };

    const closeModalDiameter = () => {
      showModalDiameter.value = false;
    };

    const showModalDiameterpr = ref(false);
    const activeDiameterpr = ref('');

    const openModalDiameterpr = () => {
      showModalDiameterpr.value = true;
      activeDiameterpr.value = 'assets/img/business-4/sampel/PREROLLEDSIZE.png';
    };

    const closeModalDiameterpr = () => {
      showModalDiameterpr.value = false;
    };



    const showForm = (form) => {
      currentForm.value = form;

      switch(form) {
        case 'cp':
          ctpFormData.value.id_kategori = '1';
          break;
        case 'ctp':
          ctpFormData.value.id_kategori = '2';
          break;
        case 'etiket':
          ctpFormData.value.id_kategori = '3';
          break;
        case 'fp':
          ctpFormData.value.id_kategori = '5';
          break;
        case 'pr':
          ctpFormData.value.id_kategori = '6';
          break;
        default:
          ctpFormData.value.id_kategori = '';
          break;
      }
    };

    const resetFormData = () => {
      if (currentForm.value === 'ctp') {
        const currentIdKategori = ctpFormData.value.id_kategori;
        ctpFormData.value = { 
          ...initialCtpFormData,
          id_kategori: currentIdKategori, 
          customFlavor: selectedFlavor.value === 'Lainnya' ? customFlavor.value : '',
          customMaterial: selectedMaterial.value === 'Lainnya' ? customMaterial.value : '',
          customSize: selectedSizes.value === 'Lainnya' ? customSize.value : '', 
          customSweetener: selectedSweetener.value === 'Other' ? customSweetener.value : '',

        };
      } else if (currentForm.value === 'etiket') {
        ctpFormData.value = { ...initialCtpFormData };
      }
    };

    const submitCtpForm = async () => {
      try {

        if (currentForm.value === 'cp') {
          ctpFormData.value.jabatan = selectedJabatan.value === 'Other' ? customJabatan.value : selectedJabatan.value;
          ctpFormData.value.flavor = selectedFlavor.value === 'Lainnya' ? customFlavor.value : selectedFlavor.value;
        ctpFormData.value.sweetener = selectedSweetener.value === 'Other' ? customSweetener.value : selectedSweetener.value;
        ctpFormData.value.material = selectedMaterial.value === 'Lainnya' ? customMaterial.value : selectedMaterial.value;
        ctpFormData.value.diameter = selectedDiameter.value === 'Other' ? customDiameter.value : selectedDiameter.value;
        ctpFormData.value.diameter = selectedDiameterpr.value === 'Other' ? customDiameterpr.value : selectedDiameterpr.value;
      ctpFormData.value.product_type = selectedTypecp.value === 'Other' ? customTypecp.value : selectedTypecp.value;
      ctpFormData.value.variant = selectedVariant.value;
    } else if (currentForm.value==='ctp') 
    {
      ctpFormData.value.size = selectedSizes.value === 'Lainnya' ? customSize.value : selectedSizes.value;
      ctpFormData.value.jabatan = selectedJabatan.value === 'Other' ? customJabatan.value : selectedJabatan.value;
        ctpFormData.value.sweetener = selectedSweetener.value === 'Other' ? customSweetener.value : selectedSweetener.value;
        ctpFormData.value.material = selectedMaterial.value === 'Lainnya' ? customMaterial.value : selectedMaterial.value;       
         ctpFormData.value.diameter = selectedDiameter.value === 'Other' ? customDiameter.value : selectedDiameter.value;
        ctpFormData.value.diameter = selectedDiameterpr.value === 'Other' ? customDiameterpr.value : selectedDiameterpr.value;
    } else if (currentForm.value === 'etiket') {
      ctpFormData.value.flavor = selectedFlavor.value === 'Lainnya' ? customFlavor.value : selectedFlavor.value;
        ctpFormData.value.size = selectedSizes.value === 'Lainnya' ? customSize.value : selectedSizes.value;
        ctpFormData.value.jabatan = selectedJabatan.value === 'Other' ? customJabatan.value : selectedJabatan.value;
        ctpFormData.value.sweetener = selectedSweetener.value === 'Other' ? customSweetener.value : selectedSweetener.value;
        ctpFormData.value.material = selectedMaterial.value === 'Lainnya' ? customMaterial.value : selectedMaterial.value;
        ctpFormData.value.diameter = selectedDiameter.value === 'Other' ? customDiameter.value : selectedDiameter.value;
        ctpFormData.value.diameter = selectedDiameterpr.value === 'Other' ? customDiameterpr.value : selectedDiameterpr.value;
      ctpFormData.value.product_type = selectedTypeetiket.value === 'Other' ? customTypeetiket.value : selectedTypeetiket.value;
      ctpFormData.value.variant = selectedVariantEtiket.value;
    } else if (currentForm.value === 'fp') {
      ctpFormData.value.flavor = selectedFlavor.value === 'Lainnya' ? customFlavor.value : selectedFlavor.value;
      ctpFormData.value.jabatan = selectedJabatan.value === 'Other' ? customJabatan.value : selectedJabatan.value;
        ctpFormData.value.size = selectedSizes.value === 'Lainnya' ? customSize.value : selectedSizes.value;
        ctpFormData.value.sweetener = selectedSweetener.value === 'Other' ? customSweetener.value : selectedSweetener.value;
        ctpFormData.value.material = selectedMaterial.value === 'Lainnya' ? customMaterial.value : selectedMaterial.value;
        ctpFormData.value.diameter = selectedDiameter.value === 'Other' ? customDiameter.value : selectedDiameter.value;
        ctpFormData.value.length = selectedLength.value === 'Other' ? customLength.value : selectedLength.value;
      ctpFormData.value.product_type = selectedTypefp.value === 'Other' ? customTypefp.value : selectedTypefp.value;
      ctpFormData.value.variant = selectedVariantFp.value;
    } else if (currentForm.value === 'pr') {
      ctpFormData.value.jabatan = selectedJabatan.value === 'Other' ? customJabatan.value : selectedJabatan.value;
      ctpFormData.value.flavor = selectedFlavor.value === 'Lainnya' ? customFlavor.value : selectedFlavor.value;
        ctpFormData.value.size = selectedSizes.value === 'Lainnya' ? customSize.value : selectedSizes.value;
        ctpFormData.value.sweetener = selectedSweetener.value === 'Other' ? customSweetener.value : selectedSweetener.value;
        ctpFormData.value.material = selectedMaterial.value === 'Lainnya' ? customMaterial.value : selectedMaterial.value;
        ctpFormData.value.diameter = selectedDiameterpr.value === 'Other' ? customDiameterpr.value : selectedDiameterpr.value;
      ctpFormData.value.product_type = selectedTypepr.value === 'Other' ? customTypepr.value : selectedTypepr.value;
      ctpFormData.value.variant = selectedVariantPr.value;
    }
       
        if (!ctpFormData.value.id_kategori) {
          switch(currentForm.value) {
            case 'cp':
              ctpFormData.value.id_kategori = '1';
              break;
            case 'ctp':
              ctpFormData.value.id_kategori = '2';
              break;
            case 'etiket':
              ctpFormData.value.id_kategori = '3';
              break;
            case 'fp':
              ctpFormData.value.id_kategori = '5';
              break;
            case 'pr':
              ctpFormData.value.id_kategori = '6';
              break;
          }
        }

        const response = await axios.post('https://puracigarettesolutions.com:3000/api/ctp/CreateCtp', ctpFormData.value);

        if (response.status === 201) {
          savedMessage.value = { content: 'Sample saved successfully', type: 'success' };
          resetFormData();
          customFlavor.value = '';
          customJabatan.value = '';
          customSize.value = '';
          customSweetener.value = '';
        } else {
          console.error('Error saving Sample');
          savedMessage.value = { content: 'Error saving Sample', type: 'error' };
        }
      } catch (error) {
        console.error('Error during Sample form submission:', error);
        savedMessage.value = { content: 'Error saving Sample', type: 'error' };
      }
    };


    watch(selectedMaterial, (newMaterial) => {
      if (newMaterial !== 'Other') {
        customMaterial.value = '';
      }
    });

    watch(selectedJabatan, (newJabatan) => {
      if (newJabatan !== 'Other') {
        customJabatan.value = '';
      }
    });

    watch(selectedFlavor, (newFlavor) => {
      if (newFlavor !== 'Lainnya') {
        customFlavor.value = '';
      }
    });

    watch(selectedSizes, (newSize) => {
      if (newSize !== 'Lainnya') {
        customSize.value = '';
      }
    });

    watch(selectedSweetener, (newSweetener) => {
      if (newSweetener !== 'Other') {
        customSweetener.value = '';
      }
    });

    watch(selectedTypecp, (newTypecp) => {
      if (newTypecp !== 'Other') {
        customTypecp.value = '';
      }
    });

    watch(selectedTypeetiket, (newTypeetiket) => {
      if (newTypeetiket !== 'Other') {
        customTypeetiket.value = '';
      }
    });

    watch(selectedFinishing, (newFinishing) => {
      if (newFinishing !== 'Other') {
        customFinishing.value = '';
      }
    });

    watch(selectedFinishingfp, (newFinishingfp) => {
      if (newFinishingfp !== 'Other') {
        customFinishingfp.value = '';
      }
    });

    watch(selectedTypefp, (newTypefp) => {
      if (newTypefp !== 'Other') {
        customTypefp.value = '';
      }
    });

    watch(selectedLength, (newLength) => {
      if (newLength !== 'Other') {
        customLength.value = '';
      }
    });

    watch(selectedDiameter, (newDiameter) => {
      if (newDiameter !== 'Other') {
        customDiameter.value = '';
      }
    });

    watch(selectedDiameterpr, (newDiameterpr) => {
      if (newDiameterpr !== 'Other') {
        customDiameterpr.value = '';
      }
    });

    watch(selectedTypepr, (newTypepr) => {
      if (newTypepr !== 'Other') {
        customTypepr.value = '';
      }
    });

    watch(selectedFilter, (newFilter) => {
      if (newFilter !== 'Other') {
        customFilter.value = '';
      }
    });

    watch(selectedSpecial, (newSpecial) => {
      if (newSpecial !== 'Other') {
        customSpecial.value = '';
      }
    });

    return {
      currentForm,
      ctpFormData,
      showForm,
      submitCtpForm,
      savedMessage,
      flavors,
      materials,
      materialsEtiket,
      customFlavor,
      selectedFlavor,
      handleFlavorChange,
      customSize,
      selectedSizes,
      selectedSweetener,
      customSweetener,
      handleSweetenerChange,
      handleMaterialChange,
      customMaterial,
      selectedMaterial,
      handleSizesChange,
      selectedSize,
      openModalCTP,
      closeModalCTP,
      showModalCTP,
      handleTypecpChange,
      customTypecp,
      handleTypeetiketChange,
      selectedTypeetiket,
      customTypeetiket,
      handleFinishingChange,
      selectedFinishing,
      customFinishing,
      handleTypefpChange,
      selectedTypefp,
      customTypefp,
      handleLengthChange,
      selectedLength,
      customLength,
      handleDiameterChange,
      selectedDiameter,
      customDiameter,
      openModalDiameter,
      closeModalDiameter,
      showModalDiameter,
      handleFinishingfpChange,
      selectedFinishingfp,
      customFinishingfp,
      handleTypeprChange,
      selectedTypepr,
      customTypepr,
      handleDiameterprChange,
      selectedDiameterpr,
      customDiameterpr,
      openModalDiameterpr,
      closeModalDiameterpr,
      showModalDiameterpr,
      handleFilterChange,
      selectedFilter,
      customFilter,
      handleSpecialChange,
      selectedSpecial,
      customSpecial,
      selectedTypecp,
      selectedVariant,
      selectedVariantEtiket,
      selectedVariantFp,
      selectedVariantPr,
      handleJabatanChange,
      selectedJabatan,
      customJabatan,
    };
  },
};
</script>



<style>

@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');

.saas_two_section_title h1 {
  font-size: 20px; /* Tingkatkan ukuran font untuk visibilitas */
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    color: #000000; /* Memastikan teks terlihat pada latar belakang gelap */
    margin-bottom: 5px; /* Ruang antara teks dan tombol */
}

.ctp_custom {
  color: #6c757d;
}

.button-group {
  margin-bottom: 20px;
  margin-top: 5px;
}


.form-button {
    background-color: #ffc107;
    color: rgb(255, 255, 255);
    border: 2px solid #ffc107; 
    margin: 0 10px;
    border-radius: 20px; /* Membuat tombol lebih oval */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
    font-weight: bold;
}

.form-button.active {
    background-color: #dcb83d;
    color: rgb(255, 255, 255);
}

.form-button:not(.active):hover {
    background-color: #ffc107;
}
.button-group {
  margin-bottom: 5px;
}

.form-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.data-message {
    margin-top: 10px; /* Space above the message */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2); /* Default background */
    color: white; /* Default text color */
}

.data-success-message {
    background-color: rgba(0, 128, 0, 0.85);
    color: white;
}

.data-error-message {
    background-color: rgba(255, 0, 0, 0.85); /* Red background for error */
    color: white;
}

.align-center {
    text-align: center;
    margin-top: 50px;
    margin-left: 150px;
    font-family: 'Montserrat', sans-serif;
}

.apbc-team-single .img-wrapper img {
    width: auto; /* Adjust width as necessary, could be in percentage or pixels */
    height: auto; /* Adjust height as necessary */
    max-width: 500px; /* Example maximum width */
    max-height: 500px; /* Example maximum height */
    display: block; /* Ensures the image is block level */
    margin: 0 auto; /* Centers the image if it's smaller than the container */
    
}

.apbc-team-single .img-wrapper img:hover {
  transform: scale(1.1); /* Membesarkan gambar saat hover */
    transition: transform 0.3s ease-in-out; /* Membuat transisi menjadi halus */
    box-shadow: 0 8px 12px rgba(0,0,0,0.2); /* Menambahkan bayangan untuk efek yang lebih menonjol */
}

.checkbox {
  transform: scale(0.5); /* Adjusting size */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
}

/* Updated CSS targeting checkboxes directly */
input[type="checkbox"] {
  transform: scale(1.5); /* Increase the scale value as needed */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
  /* Additional styles (if needed) */
}

input[type="checkbox"] {
  width: 15px; /* Set a specific width */
  height: 15px; /* Set a specific height */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
  /* Additional styles (if needed) */
}


.checkbox-label-group {
  display: flex;
  margin-top: 15px;
  align-items: center;
  color: black;
}

.checkbox-label-group label {
  margin-left: 5px; /* Adjust as needed */
  /* Additional label styling if needed */
  color: black;
}

.pillbox-container {
  display: flex;
  flex-wrap: wrap;
}

.pill {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 15px; /* Rounded corners for pill shape */
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

.pill.selected {
  background-color: #dcb83d;
  color: white;
  border-color: #dcb83d;
}

.form-container label {
    font-family: 'Montserrat', sans-serif;
}

.image-gallery {
  display: flex;
  position: relative;
  justify-content: space-around; /* Menyusun gambar dalam satu baris */
  flex-wrap: wrap; /* Izinkan wrap jika lebar layar terlalu sempit */
  margin: 10px 0; /* Tambahkan margin atas dan bawah */
  margin-bottom: 50px;
}

.image-ctp {
  display: flex;
  position: relative;
  justify-content: space-around; /* Menyusun gambar dalam satu baris */
  flex-wrap: wrap; /* Izinkan wrap jika lebar layar terlalu sempit */
  margin: 5px 0; /* Tambahkan margin atas dan bawah */
  margin-bottom: 20px;
}


.image-ctpi {
  flex-basis: 35%; /* Setiap gambar mengambil ~22% dari lebar container */
}


.image-item {
  flex-basis: 40%; /* Setiap gambar mengambil ~22% dari lebar container */
  border: 2px solid #dcb83d; /* Border biru */
  margin-bottom: 25px;
  padding: 10px; /* Padding di dalam border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Bayangan ringan */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transisi halus untuk transform dan shadow */
  overflow: hidden; /* Mencegah konten keluar dari batas saat membesar */
}

.image-item img {
  width: 100%; /* Lebar gambar menyesuaikan dengan container */
  height: auto; /* Tinggi gambar otomatis sesuai rasio aspek */
  transition: transform 0.3s ease; /* Transisi halus untuk transform */
}


.image-item:hover {
  transform: scale(1.05); /* Membesarkan gambar saat hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Membuat shadow lebih besar saat hover */
}

.image-item:hover img {
  transform: scale(1.1); /* Membesarkan gambar saat hover */
}

.image-label {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333; /* Adjust the color as needed */
  font-weight: bold;
}

.show-modal-label {
  cursor: pointer;
  margin-left: 10px;
  color: #007bff; /* Warna biru, sesuaikan dengan tema Anda */
  font-weight: bold;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px; /* Menyesuaikan berdasarkan kebutuhan */
}

.checkbox-group {
  flex-basis: 48%; /* Atau sesuaikan persentase untuk kontrol lebih pada lebar */
  /* Menambahkan margin/padding sesuai kebutuhan */
}

.checkbox-label-group label {
  margin-left: 8px; /* Atur sesuai kebutuhan */
}

input[type="checkbox"] {
  margin-right: 8px; /* Atur sesuai kebutuhan */
}


/* Styles for mobile screens */
@media screen and (max-width: 767px) {
    .image-item {
      flex-basis: 100%; /* Satu item per baris pada layar kecil */
      margin-top: 20px;
    }

    .button-group {
      flex-direction: column; /* Tombol dalam satu kolom pada layar kecil */
    }

    .form-button {
      margin: 10px 0; /* Berikan margin pada tombol */
    }

    .apbc-team-single .img-wrapper img {
      max-width: 100%; /* Gambar mengisi lebar container pada layar kecil */
    }

    .align-center {
      text-align: center; /* Tengahkan teks pada layar kecil */
    }

    .checkbox-label-group {
      margin-left: 0; /* Setel margin menjadi 0 pada layar kecil */
    }

    input[type="checkbox"] {
      margin: 0; /* Setel margin menjadi 0 pada layar kecil */
    }

    .image-gallery {
      margin: 10px 0; /* Tambahkan margin pada galeri gambar pada layar kecil */
    }

    .cta-text {
      margin-top: 20px; /* Berikan ruang di atas teks panggilan aksi */
    }

    .image-ctpi img {
      width: 100%; /* Gambar akan mengisi lebar 100% dari parent container */
    }
  }

  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px; /* Adjust based on your content size */
}

.close-button {
  position: absolute;
  top: 50px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

section#timeline {
  margin-top: 250px;
  display: flex;
  overflow-x: auto;
  padding: 5px 20px;
  position: relative;
  
  /* Add these properties to center the section */
  max-width: 1200px; /* or any suitable max-width */
  margin-left: auto;
  margin-right: auto;
}

section#timeline:before {
  content: '';
    display: block;
    position: absolute;
    top: 32%;
    left: 120px;
    width: 73%;
    height: 3px;
    background: #FFCA2E;
    z-index: 0;
}

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 200px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -65px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}

@media (min-width: 1921px) {

  section#timeline:before {
    content: "";
    display: block;
    position: absolute;
    top: 34%;
    left: 120px;
    width: 67%;
    height: 3px;
    background: #ffca2e;
    z-index: 0;
}

  
}


@media (max-width: 1224px) and (min-width: 992px) {
  .breadcurmb-title-sample h2{
    position: absolute;
        width: 80%;
        top: 150px;
        left: 50px;
        color: #000000;
        bottom: 70%;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        font-size: 35px;
}

.breadcurmb-title-sample p {
    position: relative;
    width: 50%;
    top: 220px;
    left: 210px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}


  section#timeline {
  margin-top: 250px;
  display: flex; /* Align articles horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 5px 20px;
  position: relative;
}

section#timeline:before {
  content: '';
  display: block;
  position: absolute;
  top: 30%;
    left: 80px;
    width: 78%;
  height: 3px;
  background: #FFCA2E; /* Light grey background */
  z-index: 0;
}

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 160px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -55px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}
  
}

@media (max-width: 991px) and (min-width: 840px) {
  .breadcurmb-title-sample h2{
    position: absolute;
        width: 80%;
        top: 150px;
        left: 50px;
        color: #000000;
        bottom: 70%;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        font-size: 35px;
}

.breadcurmb-title-sample p {
    position: relative;
    width: 50%;
    top: 220px;
    left: 210px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}


  section#timeline {
  margin-top: 250px;
  display: flex; /* Align articles horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 5px 20px;
  position: relative;
}

section#timeline:before {
  content: '';
  display: block;
  position: absolute;
  top: 30%;
    left: 80px;
    width: 78%;
  height: 3px;
  background: #FFCA2E; /* Light grey background */
  z-index: 0;
}

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 160px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -55px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}
  
}

@media (max-width: 839px) and (min-width: 769px) {
  .breadcurmb-title-sample h2{
    position: absolute;
    width: 60%;
    top: 129px;
    left: 150px;
    color: #000000;
    bottom: 70%;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

  .breadcurmb-title-sample p {
    position: relative;
    width: 65%;
    top: 210px;
    left: 150px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}
  section#timeline {
      margin-top: 190px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 190px;
    }

    section#timeline:before {
      top: 96px;
        left: 19%;
        width: 2px;
        height: 80%;
        background: #FFCA2E;
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 42px;
      left: calc(50% - 255px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}

}

.country-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #000000;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  transition: border-color 0.3s ease-in-out;
}

.country-input:focus {
  border-color: #007bff;
  color: #000000;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1), 0 0 8px rgba(0, 123, 255, 0.6);
}

.country-list {
  list-style: none;
  padding-left: 0;
  margin-top: 2px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.country-list li {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.country-list li:hover, .country-list li.selected-country {
  background-color: #f8f9fa;
}

.selected-country {
  font-weight: bold;
  color: black;
}


/* Tablet and mobile phone adjustments */
@media (max-width: 768px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 75px;
    }

    section#timeline:before {
        top: 96px;
        left: 14%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
        top: 48px;
        left: calc(50% - 155px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
        padding: 15px; /* Memberikan padding yang lebih untuk memudahkan baca */
        text-align: center; /* Teks disetel agar berada di tengah */
    }
}

@media (max-width: 765px) and (min-width: 660px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 130px;
    }

    section#timeline:before {
      top: 100px;
      left: 17%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 48px;
      left: calc(50% - 230px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

@media (max-width: 659px) and (min-width: 541px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 130px;
    }

    section#timeline:before {
      top: 100px;
      left: 17%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 48px;
      left: calc(50% - 210px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

@media (max-width: 540px) and (min-width: 481px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 130px;
    }

    section#timeline:before {
      top: 100px;
      left: 17%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 48px;
      left: calc(50% - 210px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

/* Adjustments for very small mobile phones */
@media (max-width: 480px) {
    section#timeline article div.inner p {
        font-size: 12px; /* Mengatur ulang ukuran font untuk visibilitas yang lebih baik */
    }
}


@media (max-width: 768px) {
    .container {
        width: 95%;
    }

    .form-button, .country-input, input[type="text"], input[type="email"], select, textarea {
        width: 100%; /* Make input elements full width */
    }

    .button-group {
        flex-direction: column;
    }

    .form-button {
        margin-bottom: 10px; /* Space between buttons */
    }

    .image-item, .image-ctpi {
        flex-basis: 100%; /* Full width images */
    }

    .modal-content {
        width: 90%; /* Smaller modal on tablet */
    }
}

/* Phones */
@media (max-width: 480px) {
    .container {
        width: 100%;
    }

    .form-button {
        font-size: 14px; /* Smaller text in buttons */
    }

    .country-list {
        display: none; /* Optionally hide country list dropdown in very small screens */
    }

    .image-gallery, .image-ctpi {
        flex-direction: column;
    }
}




</style>
